import React, { FunctionComponent } from "react"

import IndexSearch from "./IndexSearch"
import px from "../px"

let View: FunctionComponent = () => {
  return (
    <div className="indexPage" style={{ display: "flex", height: px(6) }}>
      <IndexSearch placeholder="Search Items" />
    </div>
  )
}

export default View
