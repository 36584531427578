import React, { useMemo, FunctionComponent } from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom"
import { Tabs, Row, Col } from "antd"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"
import { jsPDF } from "jspdf"
import domToImage from "dom-to-image"

import slugify from "../utilities/slugify"
import { formatMatchRate } from "./ListDetail"
import DiscreetLink from "../components/DiscreetLink"
import formatDate, { formatDateWithFormats } from "core_ui/lib/formatDate"
import hlLogo from "../assets/healiolytics-logo.png"
import {
  blackBase,
  grayBase8,
  greenBase,
  silver3,
  whiteBase,
  grayBase2,
} from "../colors"
import { chartHeight, indexSearchHeight, indexTitleHeight } from "../constants"
import formatNumber from "core_ui/lib/formatNumber"
import ExportButtons from "../components/ExportButtons"
import DetailAdditionalMetadataTable from "../components/DetailAdditionalMetadataTable"
import IndexTitle from "../components/IndexTitle"
import DetailTable from "../components/DetailTable"
import DetailTabs from "../components/DetailTabs"
import px from "../px"
import sharedStyles from "../sharedStyles"
import {
  getStartDateFormatted,
  getStartDate,
  getEndDateFormatted,
  getDspEta,
  Segment,
  CSVExportableColumnType,
} from "./SegmentDetail"
import SegmentsIcon from "../components/SegmentsIcon"
import Table from "../components/Table"
import PieChart from "../components/PieChart"
import { WidthScrollbarContainer } from "../components/WidthContainer"
import createAndDownloadCsv from "../utilities/createAndDownloadCsv"
import gatherCsvData from "../utilities/gatherCsvData"
import copyToClipboard from "../utilities/copyToClipboard"

const { TabPane } = Tabs

export const PLD_FILE_FRAGMENT = gql`
  fragment pld_file on pld_files {
    id
    filename
    created_at
    counts_by_day
    hidden
    impression_count
    total_impression_count
    sent_date
    impressions_end_date
    impressions_start_date
  }
`

export const CAMPAIGN_FRAGMENT = gql`
  fragment campaign on campaigns {
    id
    name
    created_at
    updated_at
    brand_id
    brand {
      id
      name
    }
    advertiser_id
    advertiser {
      id
      name
    }
    status
  }
`

export interface Campaign {
  id: number
  name: string
  created_at: string
  updated_at: string
  brand_id: null | number
  brand?: {
    id: number
    name: string
  }
  advertiser_id: null | number
  advertiser?: {
    id: number
    name: string
  }
  status: null | string
}

const segment_detail_columns: CSVExportableColumnType<Segment>[] = [
  {
    title: "Segment Name",
    dataIndex: "filename",
    sorter: (a: Segment, b: Segment) => a.filename.localeCompare(b.filename),
    render: (text, record) => {
      return (
        <div style={sharedStyles.tableElement}>
          {<DiscreetLink to={`/segments/${record.id}`}>{text}</DiscreetLink>}
        </div>
      )
    },
    csvRender: (_record, _index) => {
      return _record.filename
    },
  },
  {
    title: "Creation Date",
    dataIndex: "created_at",
    render: (text) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatDateWithFormats(text, "MM/DD/YYYY")}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) =>
      +new Date(a.created_at) - +new Date(b.created_at),
    defaultSortOrder: "descend",
    csvRender: (_record, _index) => {
      return formatDate(_record.created_at)
    },
  },
  {
    title: "Start Date",
    render: (_text, record) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {getStartDateFormatted(record)}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) =>
      +new Date(getStartDate(a) ?? "") - +new Date(getStartDate(b) ?? ""),
    csvRender: (_record, _index) => {
      return _record.target_list.end_date
        ? formatDate(_record.target_list.end_date)
        : "Not Set"
    },
  },
  {
    title: "End Date",
    render: (_text, record) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {getEndDateFormatted(record)}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) =>
      +new Date(a.end_date) - +new Date(b.end_date),
    csvRender: (_record, _index) => {
      return _record.target_list.end_date
        ? formatDate(_record.target_list.end_date)
        : "Not Set"
    },
  },
  {
    title: "Match Rate",
    render: (_text, record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatMatchRate(record)}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) => {
      let aRate = a.matched_npi_count / a.total_npi_count || 0
      let bRate = b.matched_npi_count / b.total_npi_count || 0
      return aRate - bRate
    },
    csvRender: (_record, _index) => {
      return formatMatchRate(_record.target_list)
    },
  },
  {
    title: "Total Npis",
    render: (_text, record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {record.total_npi_count
            ? formatNumber(record.total_npi_count)
            : "N/A"}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) => a.total_npi_count - b.total_npi_count,
    csvRender: (_record, _index) => {
      return formatNumber(_record.target_list.total_npi_count)
    },
  },
  {
    title: "Npis Matched",
    render: (_text, record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {record.matched_npi_count
            ? formatNumber(record.matched_npi_count)
            : "N/A"}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) =>
      a.matched_npi_count - b.matched_npi_count,
    csvRender: (_record, _index) => {
      return formatNumber(_record.target_list.matched_npi_count)
    },
  },
  {
    title: "DSP ETA",
    render: (_text, _record, _index) => (
      <div style={sharedStyles.tableElementNoWrap}>
        {getDspEta(_record?.created_at)}
      </div>
    ),
    sorter: (a: Segment, b: Segment) =>
      getDspEta(a.created_at).localeCompare(getDspEta(b.created_at)),
    csvRender: (_record, _index) => {
      return getDspEta(_record?.created_at)
    },
  },
]

export const CAMPAIGN_DETAIL_FRAGMENT = gql`
  fragment campaign on campaigns {
    id
    name
    created_at
    updated_at
    start_date
    end_date
    destination
    new_npis_matched
    new_npis_targeted
    npis_reached
    npis_targeted
    impressions_matched
    impressions_reached
    advertiser {
      id
      name
    }
    brand {
      id
      name
    }
    segments {
      id
      filename
      matched_npi_count
      total_npi_count
      created_at
      target_list {
        id
        matched_npi_count
        total_npi_count
        start_date
        end_date
      }
    }
    pld_files(
      where: { hidden: { _eq: false }, sent_date: { _is_null: false } }, order_by: {sent_date: desc}
    ) {
      ...pld_file
    }
  }
  ${PLD_FILE_FRAGMENT}
`

const CAMPAIGN_DETAIL_QUERY = gql`
  query Campaign($id: Int!) {
    campaigns_by_pk(id: $id) {
      ...campaign
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`

const CAMPAIGN_DETAIL_SUBSCRIPTION = gql`
  subscription Campaign($id: Int!) {
    campaigns_by_pk(id: $id) {
      ...campaign
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`

export interface PldFile {
  id: number
  filename: string
  created_at: string
  impression_count: null | number
  total_impression_count: null | number
  sent_date: string
  impressions_start_date: string
  impressions_end_date: string
  hidden: boolean
}

export interface CampaignDetail {
  id: number
  name: string
  created_at: string
  updated_at: string
  start_date: string
  end_date: string
  destination: string
  npis_reached: null | number
  npis_targeted: null | number
  new_npis_matched: null | number
  new_npis_targeted: null | number
  impressions_matched: null | number
  impressions_reached: null | number
  brand_id: null | number
  brand?: {
    id: number
    name: string
  }
  advertiser_id: null | number
  advertiser?: {
    id: number
    name: string
  }
  segments: Segment[]
  pld_files: PldFile[]
}

const pld_columns: CSVExportableColumnType<PldFile>[] = [
  {
    title: "Date Range",
    render: (_text, record, _index) => {
      if (record.impressions_start_date)
        return (
          <div style={sharedStyles.tableElement}>
            {formatDateWithFormats(
              record.impressions_start_date.substring(0, 10),
              "MM/DD/YYYY",
              false
            ) +
              " - " +
              formatDateWithFormats(
                record.impressions_end_date.substring(0, 10),
                "MM/DD/YYYY",
                false
              )}
          </div>
        )
    },
    csvRender: (record, _index) => {
      return (
        formatDateWithFormats(
          record.impressions_start_date.substring(0, 10),
          "MM/DD/YYYY",
          false
        ) +
        " - " +
        formatDateWithFormats(
          record.impressions_end_date.substring(0, 10),
          "MM/DD/YYYY",
          false
        )
      )
    },
  },
  {
    title: "Total Impressions",
    dataIndex: "total_impression_count",
    render: (text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {text ? formatNumber(text) : "N/A"}
        </div>
      )
    },
    csvRender: (record, _index) => {
      return record.total_impression_count
        ? formatNumber(record.total_impression_count)
        : "N/A"
    },
  },
  {
    title: "Impressions Matched",
    dataIndex: "impression_count",
    render: (text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {text ? formatNumber(text) : "N/A"}
        </div>
      )
    },
    csvRender: (record, _index) => {
      return record.impression_count
        ? formatNumber(record.impression_count)
        : "N/A"
    },
  },
  {
    title: "Delivered Date",
    dataIndex: "sent_date",
    render: (text, _record, _index) => {
      if (!text) {
        return null
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatDateWithFormats(text, "MM/DD/YYYY")}
        </div>
      )
    },
    csvRender: (record, _index) => {
      return formatDateWithFormats(record.sent_date, "MM/DD/YYYY", false)
    },
  },
]

const graphColumnStyles = {
  flex: "1 0 0",
  display: "flex",
  flexDirection: "column" as const,
}
const graphColumnHeaderStyles = {
  height: "36px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  backgroundColor: grayBase8,
  color: whiteBase,
  fontFamily: "Roboto Condensed",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  textTransform: "uppercase" as const,
}
const borderStyles = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: silver3,
  backgroundColor: whiteBase,
}

const tabHeaderStyles = { margin: `0px 0px ${px(2)}` }
const tabHeaderTitleContainerStyles = {
  display: "flex",
  alignItems: "center",
  height: px(6),
  position: "relative" as const,
}

const legendLabelStyles = {
  lineHeight: px(2.25),
  color: grayBase8,
  whiteSpace: "nowrap" as const,
}

const nullDataLegendLabelStyles = {
  ...legendLabelStyles,
  opacity: 0.5,
}

const legendFigureStyles = {
  fontWeight: 700,
  fontSize: px(6),
  lineHeight: px(6),
  color: greenBase,
  marginBottom: px(0.5),
}
const nullDataFigureStyles = {
  ...legendFigureStyles,
  opacity: 0,
}

const AggregateGraphColumn: FunctionComponent<{
  columnHeader: string
  className: string
  keyMetricLabel: string
  keyMetricValue: null | undefined | number
  aggregateMetricLabel: string
  aggregateMetricValue: null | undefined | number
}> = (props) => {
  let graphColors = [greenBase, grayBase2]
  let {
    keyMetricValue,
    keyMetricLabel,
    aggregateMetricValue,
    aggregateMetricLabel,
  } = props
  let validData =
    keyMetricValue != null &&
    aggregateMetricValue != null &&
    aggregateMetricValue > 0 &&
    keyMetricValue > 0 &&
    keyMetricValue <= aggregateMetricValue

  return (
    <div className={props.className} style={graphColumnStyles}>
      <div style={{ ...graphColumnHeaderStyles }}>
        <div
          style={{
            marginLeft: "15px",
            whiteSpace: "nowrap",
          }}
        >
          {props.columnHeader}
        </div>
      </div>
      <div style={{ ...borderStyles }}>
        <div
          style={{ display: "flex", justifyContent: "center", padding: px(4) }}
        >
          {validData ? (
            <PieChart
              height={chartHeight}
              width={(4 / 3) * chartHeight}
              colors={graphColors}
              data={[
                {
                  name: keyMetricLabel,
                  value: keyMetricValue as number,
                },
                {
                  name: "",
                  value:
                    (aggregateMetricValue as number) -
                    (keyMetricValue as number),
                },
              ]}
              startAngle={90}
              endAngle={-270}
              labels={true}
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height={`${chartHeight}px`}
              viewBox="0 0 462.5 462.5"
            >
              <g>
                <circle
                  fill={grayBase2}
                  cx="231.25"
                  cy="231.25"
                  r={`${50 * (2 / 2.5)}%`}
                ></circle>
                <text
                  fontFamily="Roboto Condensed"
                  fontSize="16px"
                  x="50%"
                  y="50%"
                  fill="#ffffff"
                  textAnchor="middle"
                  dy=".3em"
                >
                  Data Unavailable
                </text>
              </g>
            </svg>
          )}
        </div>
        <div
          style={{
            ...borderStyles,
            borderWidth: "0px",
            borderTopWidth: "1px",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              alignItems: "center",
              padding: px(3),
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={validData ? legendFigureStyles : nullDataFigureStyles}>
              {validData
                ? (aggregateMetricValue as number).toLocaleString()
                : "placeholder"}
            </div>
            <div
              style={validData ? legendLabelStyles : nullDataLegendLabelStyles}
            >
              {aggregateMetricLabel}
            </div>
          </div>
          <div
            style={{
              ...borderStyles,
              borderWidth: "0px",
              borderLeftWidth: "1px",
              alignItems: "center",
              padding: px(3),
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={validData ? legendFigureStyles : nullDataFigureStyles}>
              {validData
                ? (keyMetricValue as number).toLocaleString()
                : "placeholder"}
            </div>
            <div
              style={validData ? legendLabelStyles : nullDataLegendLabelStyles}
            >
              {keyMetricLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface ICampaignAggregateResults {
  matchedImpressions: number
  totalImpressions: number
}

const GetCampaignAggregateCounts = (
  pld_files: PldFile[] | undefined
): ICampaignAggregateResults | null => {
  if (!pld_files || pld_files.length === 0) return null

  let totalImpressions = 0
  let matchedImpressions = 0
  pld_files.forEach((pld_file) => {
    totalImpressions += pld_file.total_impression_count ?? 0
    matchedImpressions += pld_file.impression_count ?? 0
  })
  return { matchedImpressions, totalImpressions }
}

const pdfTableExport = async (options: {
  className: string
  saveName: string
}) => {
  let { className, saveName } = options

  // Units are mm, an A4 pdf is 210 mm
  let pageWidth = 210

  let image = document.createElement("img")
  const doc = new jsPDF()
  let pixelsInMM = 3.779
  let hlLogoWidth = 196
  let hlLogoHeight = 49
  image.width = hlLogoWidth
  image.height = hlLogoHeight
  image.src = hlLogo
  let contentBottom = 0
  doc.addImage(
    image,
    "PNG",
    16 / pixelsInMM,
    12 / pixelsInMM,
    pageWidth / 5,
    (pageWidth / 5) * (hlLogoHeight / hlLogoWidth)
  )

  contentBottom += (hlLogoHeight + 24) / pixelsInMM

  let elem = document.querySelector(className)
  if (elem == null) {
    throw new Error(`Expected element ${className} not found`)
  }

  let { width, height } = elem.getBoundingClientRect()
  let dataURI = await domToImage.toPng(elem, {
    bgcolor: whiteBase,
  })
  let elemWidth = pageWidth - 24 / pixelsInMM
  let elemHeight = elemWidth * (height / width)
  doc.addImage(
    dataURI,
    "PNG",
    (pageWidth - elemWidth) / 2,
    contentBottom,
    elemWidth,
    elemHeight
  )
  return doc.save(saveName)
}

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  let { id, path, url, data, onTabChange, activeKey } = props

  let pldFiles = data?.pld_files || []
  let campaignAggregateCounts: ICampaignAggregateResults | null = useMemo(
    () => GetCampaignAggregateCounts(pldFiles) || null,
    [pldFiles]
  )

  return (
    <div
      style={{
        marginTop: px(6),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <WidthScrollbarContainer>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Row style={{ height: px(6), marginBottom: px(4) }}>
            <Col>
              <IndexTitle title="Physician Level Reporting Detail" />
            </Col>
            <Col
              style={{
                marginLeft: "auto",
                marginTop: `${(indexTitleHeight - indexSearchHeight) / 2}px`,
              }}
            >
              <DetailAdditionalMetadataTable>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      color: blackBase,
                      fontSize: px(1.5),
                      lineHeight: px(1.5),
                      marginBottom: px(0.5),
                      textTransform: "uppercase",
                    }}
                  >
                    Start Date
                  </div>
                  <div
                    style={{
                      fontSize: px(2),
                      lineHeight: px(2),
                      textTransform: "uppercase",
                      color: grayBase8,
                    }}
                  >
                    {data?.start_date
                      ? formatDateWithFormats(
                          data?.start_date,
                          "MM/DD/YYYY",
                          false
                        )
                      : "Not Set"}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: blackBase,
                      fontSize: px(1.5),
                      lineHeight: px(1.5),
                      marginBottom: px(0.5),
                      textTransform: "uppercase",
                    }}
                  >
                    End Date
                  </div>
                  <div
                    style={{
                      fontSize: px(2),
                      lineHeight: px(2),
                      textTransform: "uppercase",
                      color: grayBase8,
                    }}
                  >
                    {data?.end_date
                      ? formatDateWithFormats(
                          data?.end_date,
                          "MM/DD/YYYY",
                          false
                        )
                      : "Not Set"}
                  </div>
                </div>
              </DetailAdditionalMetadataTable>
            </Col>
          </Row>
          <Row style={{ marginBottom: px(6) }}>
            <Col flex={1}>
              <DetailTable title={data?.name || ""}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      Advertiser
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {data?.advertiser?.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      Brand
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {data?.brand?.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      Destination
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {data?.destination ?? "Not Available"}
                    </div>
                  </div>
                </div>
              </DetailTable>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <DetailTabs onChange={onTabChange} activeKey={activeKey}>
                <TabPane
                  tab="PLD Delivery"
                  key={`/campaigns/${id}/pld_delivery`}
                />
                <TabPane
                  tab="Aggregate Data"
                  key={`/campaigns/${id}/aggregate_data`}
                />
                <TabPane
                  tab="Segment Details"
                  key={`/campaigns/${id}/details`}
                />
              </DetailTabs>
            </Col>
          </Row>
        </div>
      </WidthScrollbarContainer>
      <div style={sharedStyles.detailTabContainer}>
        <WidthScrollbarContainer
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <Switch>
            <Route exact path={`${path}/details`}>
              <div style={tabHeaderStyles}>
                <div style={tabHeaderTitleContainerStyles}>
                  <div
                    style={{
                      marginLeft: px(2),
                      width: px(4),
                      height: px(4),
                      fill: greenBase,
                    }}
                  >
                    <SegmentsIcon />
                  </div>
                  <div
                    style={{
                      marginLeft: "12px",
                      color: greenBase,
                      fontSize: px(3.25),
                      fontWeight: "bold",
                      fontFamily: "Roboto Condensed",
                    }}
                  >
                    Segments
                  </div>
                  <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                    <ExportButtons
                      onPDFClick={async () =>
                        pdfTableExport({
                          className: ".campaign-segments",
                          saveName: "campaign-detail-segments.pdf",
                        })
                      }
                      onCSVClick={async () => {
                        // get data
                        var segments = props.data?.segments
                        let rawCsv = segments
                          ? gatherCsvData(segments, segment_detail_columns)
                          : ""
                        let csvContent = "data:text/csv;charset=utf-8," + rawCsv
                        createAndDownloadCsv(
                          "campaign-detail-segments.csv",
                          csvContent
                        )
                      }}
                      onClipboardClick={async () => {
                        // get data
                        var segments = props.data?.segments
                        let rawCsv = segments
                          ? gatherCsvData(segments, segment_detail_columns)
                          : ""

                        copyToClipboard(rawCsv)
                      }}
                    />
                  </div>
                </div>
              </div>
              <Row>
                <Col flex={1}>
                  <Table<Segment>
                    className="campaign-segments"
                    columns={segment_detail_columns}
                    dataSource={props.data?.segments}
                  />
                </Col>
              </Row>
            </Route>
            <Route exact path={`${path}/pld_delivery`}>
              <div style={tabHeaderStyles}>
                <div style={tabHeaderTitleContainerStyles}>
                  <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                    <ExportButtons
                      onPDFClick={async () =>
                        pdfTableExport({
                          className: ".pld-delivery",
                          saveName: "pld-delivery.pdf",
                        })
                      }
                      onCSVClick={async () => {
                        // get data
                        var pld_files = props.data?.pld_files
                        let rawCsv = pld_files
                          ? gatherCsvData(pld_files, pld_columns)
                          : ""
                        let csvContent = "data:text/csv;charset=utf-8," + rawCsv

                        createAndDownloadCsv("pld-delivery.csv", csvContent)
                      }}
                      onClipboardClick={async () => {
                        // get data
                        var pld_files = props.data?.pld_files
                        let rawCsv = pld_files
                          ? gatherCsvData(pld_files, pld_columns)
                          : ""

                        copyToClipboard(rawCsv)
                      }}
                    />
                  </div>
                </div>
              </div>
              <Row>
                <Col flex={1}>
                  <Table<PldFile>
                    className="pld-delivery"
                    columns={pld_columns}
                    dataSource={props.data?.pld_files}
                  />
                </Col>
              </Row>
            </Route>
            <Route exact path={`${path}/aggregate_data`}>
              <div style={tabHeaderStyles}>
                <div style={tabHeaderTitleContainerStyles}>
                  <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                    <ExportButtons
                      onPDFClick={() =>
                        exportChartsAsPDF(
                          slugify(data?.name as string) + ".pdf"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div style={{ flex: 1 }}>
                  <AggregateGraphColumn
                    columnHeader={"NPIS REACHED (CAMPAIGN LIFETIME)"}
                    className={"piechart-npi"}
                    keyMetricLabel="NPIS REACHED"
                    keyMetricValue={data?.npis_reached}
                    aggregateMetricLabel="NPIS TARGETED"
                    aggregateMetricValue={data?.npis_targeted}
                  />
                </div>
                <div style={{ marginLeft: px(3), flex: 1 }}>
                  <AggregateGraphColumn
                    columnHeader={
                      "IMPRESSIONS MATCHED TO PLD (CAMPAIGN LIFETIME)"
                    }
                    className={"piechart-impressions"}
                    keyMetricLabel="MATCHED TO PLD"
                    keyMetricValue={campaignAggregateCounts?.matchedImpressions}
                    aggregateMetricLabel="TOTAL IMPRESSIONS"
                    aggregateMetricValue={
                      campaignAggregateCounts?.totalImpressions
                    }
                  />
                </div>
              </div>
            </Route>
            <Route>
              <Redirect to={`${url}/details`} />
            </Route>
          </Switch>
        </WidthScrollbarContainer>
      </div>
    </div>
  )
}

let useController = () => {
  let { path, url } = useRouteMatch()
  let location = useLocation()
  let { id } = useParams()
  let history = useHistory()
  let campaignQuery = useQuery(CAMPAIGN_DETAIL_QUERY, { variables: { id } })
  let campaignSubscription = useSubscription(CAMPAIGN_DETAIL_SUBSCRIPTION, {
    variables: { id },
  })
  let data: CampaignDetail | null = useMemo(
    () =>
      campaignSubscription.data?.campaigns_by_pk ||
      campaignQuery.data?.campaigns_by_pk ||
      null,
    [campaignQuery, campaignSubscription]
  )

  return {
    data,
    onTabChange: history.push,
    activeKey: location.pathname,
    id,
    url,
    path,
  }
}

let exportChartsAsPDF = async (documentName: string) => {
  let npiChart = document.querySelector(".piechart-npi")
  if (npiChart == null) {
    throw new Error("Expected element .piechart-npi not found")
  }

  let impressionsChart = document.querySelector(".piechart-impressions")
  if (impressionsChart == null) {
    throw new Error("Expected element .piechart-impressions not found")
  }

  // Units are mm, an A4 pdf is 210mm by 297mm
  let pageWidth = 210
  let pageHeight = 297

  let image = document.createElement("img")
  const doc = new jsPDF()
  let pixelsInMM = 3.779
  let hlLogoWidth = 196
  let hlLogoHeight = 49
  image.width = hlLogoWidth
  image.height = hlLogoHeight
  image.src = hlLogo
  let contentBottom = 0
  doc.addImage(
    image,
    "PNG",
    16 / pixelsInMM,
    12 / pixelsInMM,
    pageWidth / 5,
    (pageWidth / 5) * (hlLogoHeight / hlLogoWidth)
  )
  contentBottom += (hlLogoHeight + 24) / pixelsInMM

  let { width: npiWidth, height: npiHeight } = npiChart.getBoundingClientRect()
  let {
    width: impWidth,
    height: impHeight,
  } = impressionsChart.getBoundingClientRect()

  // Calculate the maximum width for the graphs, with the following constraints in
  // mind. The graphs can have different heights but the widths should be the
  // same. Both graphs should fit on one page beneath the header.

  let availHeight = pageHeight - contentBottom - 2 * (24 / pixelsInMM)
  let npiAspectRatio = npiWidth / npiHeight
  let impAspectRatio = impWidth / impHeight
  let npiScaledHeight = availHeight * (npiHeight / (impHeight + npiHeight))
  let impScaledHeight = availHeight * (impHeight / (impHeight + npiHeight))

  // Compute the widths of these elements if we made them as tall as possible,
  // while preserving their aspect ratio
  let npiResultingWidth = npiScaledHeight * npiAspectRatio
  let impResultingWidth = impScaledHeight * impAspectRatio

  // We take the smaller of the two as the width, if we opted for the larger,
  // then the heights would overflow
  let chartWidth = Math.min(npiResultingWidth, impResultingWidth)

  let dataURI = await domToImage.toPng(npiChart, { bgcolor: whiteBase })
  let chartHeight = chartWidth / npiAspectRatio
  doc.addImage(
    dataURI,
    "PNG",
    (pageWidth - chartWidth) / 2,
    contentBottom,
    chartWidth,
    chartHeight
  )
  contentBottom += 24 / pixelsInMM + chartHeight

  {
    let dataURI = await domToImage.toPng(impressionsChart, {
      bgcolor: whiteBase,
    })
    let chartHeight = chartWidth / impAspectRatio
    doc.addImage(
      dataURI,
      "PNG",
      (pageWidth - chartWidth) / 2,
      contentBottom,
      chartWidth,
      chartHeight
    )
  }

  contentBottom += 24 / pixelsInMM + chartHeight
  await doc.save(documentName)
}
let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
