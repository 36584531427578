import React, { ComponentProps, useState, useMemo } from "react"
import escapeStringRegexp from "escape-string-regexp"

import Select, { Option } from "./Select"

export const CREATE_NEW_OPTION_VALUE = -1

function FieldSelect<T extends { id: number }>(
  props: ComponentProps<typeof Select> & {
    collection: T[]
    field: string
    createNew?: boolean
  }
) {
  let { collection, field, createNew = false, ...restProps } = props
  let [searchValue, setSearchValue] = useState("")
  let renderOptions = useMemo(() => {
    let filtered = collection.slice(0)
    if (searchValue) {
      let pattern = new RegExp(escapeStringRegexp(searchValue), "i")
      filtered = collection.filter((elem) => {
        let e = elem as T & { [index: string]: string }
        if (field in e && typeof e[field] === "string") {
          return pattern.test(e[field])
        }
        return false
      })
    }

    let rendered = filtered.map((elem) => {
      let e = elem as T & { [index: string]: string }
      return (
        <Option key={elem.id} value={e.id} title={e[field]}>
          {e[field]}
        </Option>
      )
    })

    if (createNew) {
      rendered = [
        <Option
          className="select-create-new"
          key={CREATE_NEW_OPTION_VALUE}
          value={CREATE_NEW_OPTION_VALUE}
          title="Create New"
        >
          Create New
        </Option>,
      ].concat(rendered)
    }
    return rendered
  }, [collection, searchValue, createNew, field])

  // Coerce null to undefined, so placeholder is rendered
  let value = props.value === null ? undefined : props.value

  let selectProps = {
    allowClear: true,
    ...restProps,
    value: value,
    showSearch: true,
    filterOption: false,
    onSearch: setSearchValue,
    onChange: (
      ...args: Parameters<
        NonNullable<ComponentProps<typeof Select>["onChange"]>
      >
    ) => {
      if (props.onChange) {
        props.onChange.apply(null, args)
      }
      setSearchValue("")
    },
  }
  return <Select {...selectProps}>{renderOptions}</Select>
}

export default FieldSelect
