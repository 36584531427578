import React, {
  RefObject,
  createRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
  Prompt,
} from "react-router-dom"
import { Typography, Radio, Tooltip, Input, Tabs, Row, Col } from "antd"
import { RadioChangeEvent } from "antd/es/radio"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"
import * as Yup from "yup"
import moment from "moment"
import { SelectValue } from "antd/es/select"
import debounce from "lodash.debounce"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"

import slugify from "../utilities/slugify"
import { formatDateWithFormats } from "core_ui/lib/formatDate"
import formatNumber from "core_ui/lib/formatNumber"
import FieldSelect, { CREATE_NEW_OPTION_VALUE } from "../components/FieldSelect"
import {
  matchTargetList,
  createSegments,
  catchCriticalError,
  catchTokenExpired,
} from "../api"
import { useMounted, useDependentState } from "core_ui/lib/hooks"
import useInputField from "../components/useInputField"
import Select, { Option } from "../components/Select"
import Table from "../components/Table"
import IndexTitle from "../components/IndexTitle"
import DetailTable from "../components/DetailTable"
import DetailTabs from "../components/DetailTabs"
import PrimaryButton from "../components/PrimaryButton"
import NumberedPageSection from "../components/NumberedPageSection"
import px from "../px"
import sharedStyles from "../sharedStyles"
import { blueBase, grayBase2, greenBase, redBase, silverBase5 } from "../colors"
import { CAMPAIGN_QUERY, CAMPAIGN_SUBSCRIPTION } from "./CampaignIndex"
import { Campaign } from "./CampaignDetail"
import PieChart from "../components/PieChart"
import createAndDownloadCsv from "../utilities/createAndDownloadCsv"
import gatherCsvData from "../utilities/gatherCsvData"
import copyToClipboard from "../utilities/copyToClipboard"
import ExportButtons from "../components/ExportButtons"
import exportToPdf from "../utilities/exportToPdf"
import { WidthScrollbarContainer } from "../components/WidthContainer"
import { CSVExportableColumnType } from "./SegmentDetail"
import ListGlossaryModal from "../components/ListGlossaryModal"
import SegmentCreationModal from "../components/SegmentCreationModal"
import SegmentCreationRequestModal from "../components/SegmentCreationRequestModal"
import SegmentCreationErrorModal from "../components/SegmentCreationErrorModal"
import SegmentCreationConfirmLeaveModal from "../components/SegmentCreationConfirmLeaveModal"
import MaskedInput, { MaskedInputRef } from "../components/MaskedInput"

const { TabPane } = Tabs
const { Link: TypographyLink } = Typography

const ENTIRE_LIST_COLUMN = "*"

let minLength = 5
export const customNameSchema = Yup.string()
  // The error message here is not totally accurate, which is fine
  .matches(/^[a-zA-Z0-9_ -]*$/, "Please enter alphanumeric characters only.")
  .min(minLength, `Please enter more than ${minLength} characters.`)
  .required("This field is required")

let segmentNameSchema = Yup.string()
  // The error message here is not totally accurate, which is fine
  .matches(/^[a-zA-Z0-9_.]*$/, "Please enter alphanumeric characters only.")
  .required("Please provide a name")

const radioStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: px(2),
  padding: "5px 0px",
  marginBottom: px(1),
  marginRight: px(5.25),
}

export const LIST_FRAGMENT = gql`
  fragment list on target_lists {
    id
    filename
    created_at
    matched_npi_count
    total_npi_count
    matchable_columns
    healio_target_list
  }
`

export const LIST_QUERY = gql`
  query TargetList($id: Int!) {
    target_lists_by_pk(id: $id) {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`

enum Channel {
  NonEndemicWeb = "NonEndemicWeb",
  FacebookInstagram = "FacebookInstagram",
  Twitter = "Twitter",
  LinkedIn = "LinkedIn",
  Bing = "Bing",
}

export const LIST_SUBSCRIPTION = gql`
  subscription TargetList($id: Int!) {
    target_lists_by_pk(id: $id) {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`

interface Advertiser {
  id: number
  name: string
}

const ADVERTISER_FRAGMENT = gql`
  fragment advertiser on advertisers {
    id
    name
  }
`

const ADVERTISER_QUERY = gql`
  query Advertisers {
    advertisers(order_by: { name: asc }) {
      ...advertiser
    }
  }
  ${ADVERTISER_FRAGMENT}
`

const ADVERTISER_SUBSCRIPTION = gql`
  subscription Advertisers {
    advertisers(order_by: { name: asc }) {
      ...advertiser
    }
  }
  ${ADVERTISER_FRAGMENT}
`

interface Brand {
  id: number
  name: string
  advertiser_id: number
}

const BRAND_FRAGMENT = gql`
  fragment brand on brands {
    id
    name
    advertiser_id
  }
`

const BRAND_QUERY = gql`
  query Brands {
    brands(order_by: { name: asc }) {
      ...brand
    }
  }
  ${BRAND_FRAGMENT}
`

const BRAND_SUBSCRIPTION = gql`
  subscription Brands {
    brands(order_by: { name: asc }) {
      ...brand
    }
  }
  ${BRAND_FRAGMENT}
`
const programmaticMultiplier = 15

export interface List {
  id: number
  filename: string
  created_at: string
  matched_npi_count: number
  total_npi_count: number
  matchable_columns: string
  healio_target_list : boolean
}

type ListMatchCompareFunction = (
  a: ListMatch,
  b: ListMatch,
  sortOrder?: string | null
) => number

const entireListLastSorter: (
  defaultSorter: ListMatchCompareFunction
) => ListMatchCompareFunction = (defaultSorter) => (a, b, sortOrder) => {
  // Whether we are sorting in ascending or descending, the "Entire List" segment is always last
  let flip = sortOrder === "descend"
  if (a.segment === ENTIRE_LIST_COLUMN && b.segment !== ENTIRE_LIST_COLUMN) {
    return flip ? -1 : 1
  } else if (
    a.segment !== ENTIRE_LIST_COLUMN &&
    b.segment === ENTIRE_LIST_COLUMN
  ) {
    return flip ? 1 : -1
  } else {
    return defaultSorter(a, b)
  }
}

const listMatchSegmentSort = entireListLastSorter(
  (a: ListMatch, b: ListMatch) => a.segment.localeCompare(b.segment)
)

export function formatMatchRate(
  list: Pick<List, "matched_npi_count" | "total_npi_count"> | null
) {
  let matched = list?.matched_npi_count
  let total = list?.total_npi_count
  if (matched == null || total == null || total === 0) {
    return "-"
  }
  let rate = (100 * matched) / total
  return `${rate.toFixed(1)}%`
}

export function formatMonthlyEstimatedImpressions(
  matched: number | null,
  multiplier: number
) {
  if (matched == null) {
    return "N/A"
  }
  return formatNumber(multiplier * matched)
}

const matchRateColumns: CSVExportableColumnType<ListMatch>[] = [
  {
    title: "Segment",
    dataIndex: "segment",
    render: (text: string, record: ListMatch) => {
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElement}>
            <b>Entire List</b>
          </div>
        )
      }
      return <div style={sharedStyles.tableElement}>{text}</div>
    },
    sorter: listMatchSegmentSort,
    csvRender: (_record, _index) => {
      if (_record.segment === ENTIRE_LIST_COLUMN) {
        return "Entire List"
      }
      return _record.segment
    },
  },
  {
    title: "Total Count",
    dataIndex: "total",
    render: (text: string, record: ListMatch) => {
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>{formatNumber(parseInt(text) || 0)}</b>
          </div>
        )
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatNumber(parseInt(text) || 0)}
        </div>
      )
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) => a.total - b.total
    ),
    csvRender: (_record: ListMatch, _index: number) => {
      return _record.total.toString()
    },
  },
  {
    title: "Healio",
    dataIndex: "healio_match",
    render: (text: string, record: ListMatch) => {
      let healio_matched_count = parseInt(text)
        ? formatNumber(parseInt(text))
        : "N/A"
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>{healio_matched_count}</b>
          </div>
        )
      }

      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {healio_matched_count}
        </div>
      )
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) => a.healio_match - b.healio_match
    ),
    csvRender: (_record, _index) => {
      return _record.healio_match.toString()
    },
  },
  {
    title: "Healio MR",
    dataIndex: "healio_percentage",
    render: (text: string, record: ListMatch) => {
      let healio_matched_percentage =
        text !== "0" && text !== "0.0" ? `${text}%` : "N/A"
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>{healio_matched_percentage}</b>
          </div>
        )
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {healio_matched_percentage}
        </div>
      )
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) =>
        +a.healio_percentage - +b.healio_percentage
    ),
    csvRender: (_record, _index) => {
      return `${_record.healio_percentage}%`
    },
  },
  {
    title: "Active Prog",
    dataIndex: "prog_match",
    render: (text: string, record: ListMatch) => {
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>{formatNumber(parseInt(text) || 0)}</b>
          </div>
        )
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatNumber(parseInt(text) || 0)}
        </div>
      )
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) => a.prog_match - b.prog_match
    ),
    csvRender: (_record, _index) => {
      return _record.prog_match.toString()
    },
  },
  {
    title: "Active Prog MR",
    dataIndex: "prog_percentage",
    render: (text: string, record: ListMatch) => {
      text = `${text}%`
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>{text}</b>
          </div>
        )
      }
      return <div style={sharedStyles.tableElementNoWrap}>{text}</div>
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) => +a.prog_percentage - +b.prog_percentage
    ),
    csvRender: (_record, _index) => {
      return `${_record.prog_percentage}%`
    },
  },
  {
    title: "EST. PROG IMP/MO",
    dataIndex: "prog_match",
    render: (text: string, record: ListMatch) => {
      if (record.segment === ENTIRE_LIST_COLUMN) {
        return (
          <div style={sharedStyles.tableElementNoWrap}>
            <b>
              {formatMonthlyEstimatedImpressions(
                parseInt(text) || 0,
                programmaticMultiplier
              )}
            </b>
          </div>
        )
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatMonthlyEstimatedImpressions(
            parseInt(text) || 0,
            programmaticMultiplier
          )}
        </div>
      )
    },
    sorter: entireListLastSorter(
      (a: ListMatch, b: ListMatch) => a.prog_match - b.prog_match
    ),
    csvRender: (_record, _index) => {
      return formatMonthlyEstimatedImpressions(
        _record.prog_match || 0,
        programmaticMultiplier
      ).replace(",","")
    },
  },
]

const SectionWrapper: FunctionComponent = (props) => {
  return (
    <WidthScrollbarContainer>
      <Row justify="center">
        <Col span={24}>{props.children}</Col>
      </Row>
    </WidthScrollbarContainer>
  )
}

interface LeaveProps {
  when?: boolean | undefined
  navigate: (path: string) => void
  shouldBlockNavigation: (location: Location) => boolean
}
const LeavingCreateSegments = ({
  when,
  navigate,
  shouldBlockNavigation,
}: LeaveProps) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const handleBlockedNavigation = (
    nextLocation: any,
    _action: any
  ): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname)
      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <SegmentCreationConfirmLeaveModal
        onLeave={handleConfirmNavigationClick}
        onContinue={() => setModalVisible(false)}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  )
}

export const EstimatedMonthlyImpressions: FunctionComponent<{
  matchCount: number | undefined | null
}> = (props) => {
  let facebookMultiplier = 10

  let infoIcon = (
    <div
      style={{
        lineHeight: px(1.75),
        fontSize: px(1.75),
        marginRight: px(0.75),
      }}
    >
      <FontAwesomeIcon icon={faInfoCircle} color={greenBase} />
    </div>
  )

  let impressionsExplainer = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span>{`Programmatic Match Count * ${programmaticMultiplier} Est. Monthly Frequency = Display Est. Imps/mo`}</span>
      <span>{`Programmatic Match Count * ${facebookMultiplier} Est. Monthly Frequency = Facebook/Insta Est. Imps/mo`}</span>
    </div>
  )
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Tooltip
            overlayStyle={{
              maxWidth: "initial" /* Allow the width to come from contentkk*/,
            }}
            title={impressionsExplainer}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: px(1),
              }}
            >
              {infoIcon}
              <div
                style={{
                  fontWeight: 700,
                  fontFamily: "Roboto Condensed",
                  color: greenBase,
                  fontSize: px(1.88),
                  lineHeight: px(1.88),
                  textTransform: "uppercase" as const,
                }}
              >
                Estimated Monthly Impressions
              </div>
            </div>
          </Tooltip>
          <div style={{ display: "flex" }}>
            <div style={{ opacity: 0 }}>{infoIcon}</div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={sharedStyles.detailTableColumnContent}>
                {formatMonthlyEstimatedImpressions(
                  props?.matchCount || 0,
                  programmaticMultiplier
                )}
              </div>
              <div
                style={{
                  fontSize: px(1.5),
                  lineHeight: px(1.5),
                  marginBottom: "1px",
                }}
              >
                PROGRAMMATIC DISPLAY
              </div>
            </div>
            <div
              style={{
                marginLeft: px(1.5),
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={sharedStyles.detailTableColumnContent}>
                {formatMonthlyEstimatedImpressions(
                  props?.matchCount || 0,
                  facebookMultiplier
                )}
              </div>
              <div style={sharedStyles.matchColumnSmallHeader}>
                FACEBOOK/INSTAGRAM
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  let {
    id,
    isMatchRates,
    path,
    url,
    list,
    onTabChange,
    activeKey,
    matchRateTab,
    createSegmentsTab,
    renderPieChart,
  } = props

  return (
    <div
      style={{
        marginTop: px(6),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <WidthScrollbarContainer>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Row style={{ height: px(6), marginBottom: px(4) }}>
            <Col>
              <IndexTitle
                title={isMatchRates ? "Match Target List" : "Send Target List"}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: px(6) }}>
            <Col flex={1}>
              <DetailTable title={list?.filename || ""}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      Uploaded
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {formatDateWithFormats(
                        list?.created_at ?? "",
                        "MM/DD/YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {renderPieChart ? (
                      <div style={{ width: "65px" }}>
                        <PieChart
                          size={65}
                          colors={[greenBase, grayBase2]}
                          data={[
                            {
                              name: "Matched",
                              value: list?.matched_npi_count ?? 0,
                            },
                            {
                              name: "Total",
                              value:
                                (list?.total_npi_count ?? 0) -
                                (list?.matched_npi_count ?? 0),
                            },
                          ]}
                          startAngle={90}
                          endAngle={-270}
                          labels={false}
                        />
                      </div>
                    ) : null}
                    <div
                      style={{
                        marginLeft: renderPieChart ? px(1.5) : px(0),
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <div style={sharedStyles.detailTableColumnHeader}>
                        NPIs Matched
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {formatMatchRate(list)}
                          </div>
                          <div
                            style={{
                              fontSize: px(1.5),
                              lineHeight: px(1.5),
                              marginBottom: "1px",
                            }}
                          >
                            RATE
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: px(1.5),
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {list?.total_npi_count == null
                              ? "-"
                              : formatNumber(list?.total_npi_count)}
                          </div>
                          <div style={sharedStyles.matchColumnSmallHeader}>
                            TOTAL
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: px(1.5),
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {list?.matched_npi_count == null
                              ? "-"
                              : formatNumber(list?.matched_npi_count)}
                          </div>
                          <div style={sharedStyles.matchColumnSmallHeader}>
                            MATCHED
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <EstimatedMonthlyImpressions
                  matchCount={list?.matched_npi_count}
                />
              </DetailTable>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <DetailTabs onChange={onTabChange} activeKey={activeKey}>
                <TabPane tab="Match Rates" key={`/lists/${id}/match_rates`} />
                <TabPane
                  tab="Send Segments"
                  key={`/lists/${id}/create_segments`}
                />
              </DetailTabs>
            </Col>
          </Row>
        </div>
      </WidthScrollbarContainer>
      <Switch>
        <Route exact path={`${path}/create_segments`}>
          <div
            style={{
              ...sharedStyles.detailTabContainer,
              padding: `${px(5)} 0px ${px(21)}`,
            }}
          >
            <SectionWrapper>
              <NumberedPageSection number={1} disabled={false}>
                <h2
                  style={{
                    ...sharedStyles.headerLarge,
                    marginBottom: px(1),
                  }}
                >
                  Segment This Target List
                </h2>
                <Row style={{ marginBottom: px(1.5) }} gutter={16}>
                  <Col style={{ padding: `0px ${px(1)}` }} span={24}>
                    <Radio.Group
                      style={{
                        marginLeft: px(1),
                        marginTop: px(2.5),
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                      onChange={createSegmentsTab.onMatchRateColumnSelect}
                      value={createSegmentsTab.matchRateColumn}
                    >
                      {list?.matchable_columns && (
                        <Radio style={radioStyle} value={ENTIRE_LIST_COLUMN}>
                          Entire List
                        </Radio>
                      )}
                      {list?.matchable_columns &&
                        list?.matchable_columns.split("|").map((c: string) => (
                          <Radio style={radioStyle} key={c} value={c}>
                            {c}
                          </Radio>
                        ))}
                    </Radio.Group>
                  </Col>
                </Row>
                <div style={{ marginBottom: px(0.75) }}>
                  <Table
                    rowKey="segment"
                    loading={createSegmentsTab.matchesLoading}
                    dataSource={createSegmentsTab.matches}
                    columns={matchRateColumns}
                    pagination={false}
                    rowSelection={{
                      selectedRowKeys: createSegmentsTab.selectedRowKeys,
                      onChange: createSegmentsTab.onRowSelection,
                      hideSelectAll: true,
                      renderCell: (checked, d, index, originNode) => {
                        if (
                          d.segment === ENTIRE_LIST_COLUMN &&
                          createSegmentsTab.matchRateColumn !==
                            ENTIRE_LIST_COLUMN
                        ) {
                          return null
                        }
                        return originNode
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Segments with less than 30 NPIs are suppressed.</div>
                  <TypographyLink
                    style={{ color: blueBase }}
                    onClick={() =>
                      createSegmentsTab.setListGlossaryModalVisible(true)
                    }
                  >
                    Learn more about these values.
                  </TypographyLink>
                </div>
              </NumberedPageSection>
            </SectionWrapper>
            <div
              style={{
                margin: `${px(5)} 0`,
                height: "1px",
                backgroundColor: silverBase5,
              }}
            />
            <SectionWrapper>
              <NumberedPageSection
                number={2}
                disabled={createSegmentsTab.assignToCampaignSectionDisabled}
              >
                <h2
                  style={{
                    ...sharedStyles.headerLarge,
                    marginBottom: px(3),
                  }}
                >
                  Send to DSP or Ad Account
                </h2>
                <Row gutter={24}>
                  <Col span={12}>
                    <div style={{ marginBottom: px(4) }}>
                      <div style={sharedStyles.commonInputLabel}>
                        Advertiser<span style={{ color: redBase }}>*</span>
                      </div>
                      <FieldSelect
                        createNew={true}
                        placeholder="Choose Advertiser"
                        disabled={createSegmentsTab.advertiserSelectDisabled}
                        value={createSegmentsTab.selectedAdvertiserId}
                        onChange={createSegmentsTab.onAdvertiserSelect}
                        collection={createSegmentsTab.advertisers}
                        field="name"
                      />
                    </div>
                    <div style={{ marginBottom: px(4) }}>
                      <div
                        style={{
                          ...sharedStyles.commonInputLabel,
                          opacity:
                            createSegmentsTab.brandSelectDisabled &&
                            !createSegmentsTab.assignToCampaignSectionDisabled
                              ? 0.5
                              : 1,
                        }}
                      >
                        Brand<span style={{ color: redBase }}>*</span>
                      </div>
                      <FieldSelect
                        createNew={true}
                        disabled={createSegmentsTab.brandSelectDisabled}
                        placeholder="Choose Brand"
                        value={createSegmentsTab.selectedBrandId}
                        onChange={createSegmentsTab.onBrandSelect}
                        collection={createSegmentsTab.brands}
                        field="name"
                      />
                    </div>
                    <div
                      style={{
                        /* We render a bottom marging if we are displaying the element */
                        display: createSegmentsTab.createNewBrand
                          ? "none"
                          : "block",
                        marginBottom: createSegmentsTab.createNewBrand
                          ? 0
                          : px(4),
                      }}
                    >
                      <div
                        style={{
                          ...sharedStyles.commonInputLabel,
                          opacity:
                            createSegmentsTab.campaignSelectDisabled &&
                            !createSegmentsTab.assignToCampaignSectionDisabled
                              ? 0.5
                              : 1,
                        }}
                      >
                        Campaign<span style={{ color: redBase }}>*</span>
                      </div>
                      <FieldSelect
                        createNew={true}
                        disabled={createSegmentsTab.campaignSelectDisabled}
                        placeholder="Choose Campaign"
                        value={createSegmentsTab.selectedCampaignId}
                        onChange={createSegmentsTab.onCampaignSelect}
                        collection={createSegmentsTab.campaigns}
                        field="name"
                      />
                    </div>
                    <div
                      style={{
                        display: createSegmentsTab.createNewCampaign
                          ? "block"
                          : "none",
                      }}
                    >
                      <div style={sharedStyles.commonInputLabel}>
                        Channel<span style={{ color: redBase }}>*</span>
                      </div>
                      <Select
                        mode="multiple"
                        placeholder="Choose Channel"
                        value={createSegmentsTab.selectedChannels}
                        onChange={createSegmentsTab.onChannelSelect}
                      >
                        <Option value={Channel.NonEndemicWeb}>
                          Non-Endemic Web
                        </Option>
                        <Option value={Channel.FacebookInstagram}>
                          Facebook/Instagram
                        </Option>
                        <Option value={Channel.Twitter}>Twitter</Option>
                        <Option value={Channel.LinkedIn}>LinkedIn</Option>
                        <Option value={Channel.Bing}>Bing</Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        marginBottom: px(4),
                        opacity: createSegmentsTab.createNewAdvertiser ? 1 : 0,
                      }}
                    >
                      <div style={sharedStyles.commonInputLabel}>
                        Advertiser Name
                        <span style={{ color: redBase }}>*</span>
                      </div>
                      <Input
                        ref={createSegmentsTab.customAdvertiser.ref}
                        className={
                          createSegmentsTab.customAdvertiser.touched &&
                          createSegmentsTab.customAdvertiser.error
                            ? "error"
                            : ""
                        }
                        onChange={
                          createSegmentsTab.customAdvertiser.debouncedOnChange
                        }
                        onBlur={createSegmentsTab.customAdvertiser.onBlur}
                      />
                      <div style={sharedStyles.fieldError}>
                        {createSegmentsTab.customAdvertiser.touched &&
                          createSegmentsTab.customAdvertiser.error}
                      </div>
                    </div>
                    <div
                      style={{
                        marginBottom: px(4),
                        opacity: createSegmentsTab.createNewBrand ? 1 : 0,
                      }}
                    >
                      <div style={sharedStyles.commonInputLabel}>
                        Brand Name<span style={{ color: redBase }}>*</span>
                      </div>
                      <Input
                        ref={createSegmentsTab.customBrand.ref}
                        className={
                          createSegmentsTab.customBrand.touched &&
                          createSegmentsTab.customBrand.error
                            ? "error"
                            : ""
                        }
                        onChange={
                          createSegmentsTab.customBrand.debouncedOnChange
                        }
                        onBlur={createSegmentsTab.customBrand.onBlur}
                      />
                      <div style={sharedStyles.fieldError}>
                        {createSegmentsTab.customBrand.touched &&
                          createSegmentsTab.customBrand.error}
                      </div>
                    </div>
                  </Col>
                </Row>
              </NumberedPageSection>
            </SectionWrapper>
            <div
              style={{
                margin: `${px(5)} 0`,
                height: "1px",
                backgroundColor: silverBase5,
              }}
            />
            <SectionWrapper>
              <NumberedPageSection
                number={3}
                disabled={createSegmentsTab.nameSegmentsSectionDisabled}
              >
                <h2
                  style={{
                    ...sharedStyles.headerLarge,
                    marginBottom: px(3),
                  }}
                >
                  Name Segments
                </h2>
                <div style={{ marginBottom: px(2), fontSize: px(2) }}>
                  If necessary, you can rename your segments. Advertiser, brand,
                  and date are required.
                </div>
                {createSegmentsTab.selectedRowKeys.length === 0 ? (
                  <Input />
                ) : null}
                {createSegmentsTab.selectedRowKeys.map((d: string) => {
                  return (
                    <div style={{ marginBottom: px(4) }} key={d}>
                      <MaskedInput
                        prefix={createSegmentsTab.segmentNamePrefix}
                        ref={createSegmentsTab.segmentRefs.get(d)}
                        suffix={createSegmentsTab.segmentNameSuffix}
                        onValueChange={createSegmentsTab.segmentNameOnChange(d)}
                        hasError={
                          !!createSegmentsTab.segmentNameErrorMap.get(d)
                        }
                      />
                      <div style={sharedStyles.fieldError}>
                        {createSegmentsTab.segmentNameErrorMap.get(d)}
                      </div>
                    </div>
                  )
                })}
                <div
                  style={{
                    marginTop: px(5),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PrimaryButton
                    disabled={createSegmentsTab.submitSegmentsDisabled}
                    loading={createSegmentsTab.submitSegmentsLoading}
                    onClick={() => {
                      createSegmentsTab.submitSegmentsForCreation()
                    }}
                  >
                    Send Segments
                  </PrimaryButton>
                </div>
              </NumberedPageSection>
            </SectionWrapper>
          </div>
        </Route>
        <Route exact path={`${path}/match_rates`}>
          <div
            style={{
              ...sharedStyles.detailTabContainer,
              padding: `${px(4)} 0px ${px(21)}`,
            }}
          >
            <WidthScrollbarContainer>
              <Row justify="center">
                <Col span={24}>
                  <h2
                    style={{
                      ...sharedStyles.headerLarge,
                      marginBottom: px(0),
                    }}
                  >
                    Match Rates
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      marginBottom: px(2.5),
                    }}
                  >
                    <Col span={(8 / 10) * 24}>
                      <Radio.Group
                        style={{
                          marginLeft: px(1),
                          marginTop: px(2.5),
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                        onChange={matchRateTab.onMatchRateColumnSelect}
                        value={matchRateTab.matchRateColumn}
                      >
                        {list?.matchable_columns && (
                          <Radio style={radioStyle} value={ENTIRE_LIST_COLUMN}>
                            Entire List
                          </Radio>
                        )}
                        {list?.matchable_columns &&
                          list?.matchable_columns
                            .split("|")
                            .map((c: string) => (
                              <Radio style={radioStyle} key={c} value={c}>
                                {c}
                              </Radio>
                            ))}
                      </Radio.Group>
                    </Col>
                    <Col span={(2 / 10) * 24} style={{ position: "relative" }}>
                      <div style={{ visibility: "hidden" }}>
                        <ExportButtons />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "0px",
                          bottom: "0px",
                        }}
                      >
                        <ExportButtons
                          onPDFClick={async () => {
                            exportToPdf(
                              [".listmatch-matchrates"],
                              slugify(list?.filename as string) + ".pdf"
                            )
                          }}
                          onCSVClick={async () => {
                            // get data
                            let rawCsv = matchRateTab.matches
                              ? gatherCsvData(
                                  matchRateTab.matches,
                                  matchRateColumns
                                )
                              : ""
                            let csvContent =
                              "data:text/csv;charset=utf-8," + rawCsv
                            createAndDownloadCsv(
                              slugify(list?.filename as string) + ".csv",
                              csvContent
                            )
                          }}
                          onClipboardClick={async () => {
                            // get data
                            let rawCsv = matchRateTab.matches
                              ? gatherCsvData(
                                  matchRateTab.matches,
                                  matchRateColumns
                                )
                              : ""

                            copyToClipboard(rawCsv)
                          }}
                        />
                      </div>
                    </Col>
                  </div>
                  <div style={{ marginBottom: px(2) }}>
                    <div style={{ marginBottom: px(0.75) }}>
                      <Table
                        className="listmatch-matchrates"
                        rowKey="segment"
                        loading={matchRateTab.matchesLoading}
                        dataSource={matchRateTab.matches}
                        columns={matchRateColumns}
                        pagination={false}
                      />
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <TypographyLink
                        style={{ color: blueBase }}
                        onClick={() =>
                          createSegmentsTab.setListGlossaryModalVisible(true)
                        }
                      >
                        Learn more about these values.
                      </TypographyLink>
                    </div>
                  </div>
                  <Row justify="center" gutter={16}>
                    <PrimaryButton onClick={matchRateTab.onCreateSegments}>
                      Create Segments
                    </PrimaryButton>
                  </Row>
                </Col>
              </Row>
            </WidthScrollbarContainer>
          </div>
        </Route>
        <Route>
          <Redirect to={`${url}/match_rates`} />
        </Route>
      </Switch>
      <SegmentCreationModal
        visible={createSegmentsTab.segmentCreationModalVisible}
        onCreateAnotherSegment={
          createSegmentsTab.segmentCreationModalCreateAnotherSegment
        }
        setVisible={createSegmentsTab.setSegmentCreationModalVisible}
        onViewCampaign={createSegmentsTab.segmentCreationModalViewCampaign}
        onCancel={createSegmentsTab.resetSegmentCreationOptions}
      />
      <ListGlossaryModal
        visible={createSegmentsTab.listGlossaryModalVisible}
        setVisible={createSegmentsTab.setListGlossaryModalVisible}
      />
      <SegmentCreationRequestModal
        visible={createSegmentsTab.segmentCreationRequestModalVisible}
        setVisible={createSegmentsTab.setSegmentCreationRequestModalVisible}
        onCreateAnotherSegment={
          createSegmentsTab.segmentCreationRequestModalCreateAnotherSegment
        }
        onViewPendingSegments={
          createSegmentsTab.segmentCreationRequestModalViewPendingSegments
        }
        onCancel={createSegmentsTab.resetSegmentCreationOptions}
      />
      <SegmentCreationErrorModal
        visible={createSegmentsTab.segmentErrorModalVisible}
        setVisible={createSegmentsTab.setSegmentErrorModalVisible}
      />
      <LeavingCreateSegments
        when={createSegmentsTab.selectedRowKeys.length > 0}
        navigate={(path) => {
          createSegmentsTab.resetSegmentCreationOptions()
          createSegmentsTab.history.push(path)
        }}
        shouldBlockNavigation={(location) => {
          if (
            location &&
            location.pathname.indexOf("create_segments") === -1 &&
            createSegmentsTab.selectedRowKeys.length > 0
          ) {
            return true
          }
          return false
        }}
      />
    </div>
  )
}

type ListMatch = {
  segment: string
  healio_match: number
  prog_match: number
  total: number
  prog_percentage: string
  healio_percentage: string
}

let useMatchRateTab = (list: List | null) => {
  let { id } = useParams()
  let isMounted = useMounted()
  let history = useHistory()
  let [userHasSelected, setUserHasSelected] = useState(false)
  let [matchRateColumn, setMatchRateSelection] = useState(list?.healio_target_list ? "Specialty" : ENTIRE_LIST_COLUMN)

  let [matchesLoading, setMatchesLoading] = useState(true)
  let [matches, setMatches] = useState<ListMatch[]>([])
  let listId = list?.id
  let listHealioTargetList = list?.healio_target_list
  useEffect(() => {
    if (listId == null) {
      return
    }

    if (matchRateColumn === ENTIRE_LIST_COLUMN && listHealioTargetList && !userHasSelected){
      setMatchRateSelection("Specialty")
      return;
    }

    setMatchesLoading(true)
    matchTargetList(matchRateColumn, listId)
      .then((resp) => {
        if (!isMounted) {
          return
        }

        let matches = Object.keys(resp.data).map((k) => ({
          segment: k,
          healio_match: resp.data[k].healio_match,
          prog_match: resp.data[k].prog_match,
          total: resp.data[k].total,
          healio_percentage: resp.data[k].healio_percentage,
          prog_percentage: resp.data[k].prog_percentage,
        }))
        matches.sort(listMatchSegmentSort)
        setMatches(matches)
      })
      .catch(catchCriticalError)
      .catch(catchTokenExpired)
      .finally(() => setMatchesLoading(false))
  }, [isMounted, setMatches, matchRateColumn, listId, listHealioTargetList, setMatchRateSelection, userHasSelected])

  return {
    onMatchRateColumnSelect: (e: RadioChangeEvent) => {
      setMatchRateSelection(e.target.value)
      setUserHasSelected(true)
    },

    matchRateColumn,
    matches,
    matchesLoading,
    onCreateSegments: () => {
      history.push({
        pathname: `/lists/${id}/create_segments`,
        state: { matchRateColumn },
      })
    },
  }
}
let useCreateSegmentsTab = (list: List | null) => {
  let { id } = useParams()
  let isMounted = useMounted()
  let history = useHistory()
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  let location = useLocation<{ matchRateColumn?: string }>()

  let customAdvertiser = useInputField({
    initial: "",
    schema: customNameSchema,
  })
  let customBrand = useInputField({ initial: "", schema: customNameSchema })

  let [selectedAdvertiserId, setSelectedAdvertiserId] = useState<
    number | undefined
  >(undefined)
  let [selectedBrandId, setSelectedBrandId] = useState<number | undefined>(
    undefined
  )
  let [selectedCampaignId, setSelectedCampaignId] = useState<
    number | undefined
  >(undefined)
  let [selectedChannels, setSelectedChannels] = useState<Channel[] | undefined>(
    undefined
  )

  let [segmentNameMap, setSegmentNameMap] = useState<Map<string, string>>(
    new Map()
  )

  let [segmentRefs, setSegmentRefs] = useState<
    Map<string, RefObject<MaskedInputRef>>
  >(new Map())

  let [segmentNameErrorMap, setSegmentNameErrorMap] = useState<
    Map<string, string>
  >(new Map())

  let advertiserQuery = useQuery(ADVERTISER_QUERY)
  let advertiserSubscription = useSubscription(ADVERTISER_SUBSCRIPTION)
  let advertisers = useMemo(
    () =>
      advertiserSubscription.data?.advertisers ||
      advertiserQuery.data?.advertisers ||
      [],
    [advertiserQuery, advertiserSubscription]
  )

  let advertiserLookup = useMemo(() => {
    let lookup = new Map<number, Advertiser>()
    let d: Advertiser
    for (d of advertisers) {
      lookup.set(d.id, d)
    }
    return lookup
  }, [advertisers])

  let brandQuery = useQuery(BRAND_QUERY)
  let brandSubscription = useSubscription(BRAND_SUBSCRIPTION)
  let brands = useMemo(
    () =>
      (brandSubscription.data?.brands || brandQuery.data?.brands || []).filter(
        (d: Brand) =>
          selectedAdvertiserId != null &&
          d.advertiser_id === selectedAdvertiserId
      ),
    [brandQuery.data, brandSubscription.data, selectedAdvertiserId]
  )

  let brandLookup = useMemo(() => {
    let lookup = new Map<number, Brand>()
    let d: Brand
    for (d of brands) {
      lookup.set(d.id, d)
    }
    return lookup
  }, [brands])

  let campaignQuery = useQuery(CAMPAIGN_QUERY)
  let campaignSubscription = useSubscription(CAMPAIGN_SUBSCRIPTION)
  let campaigns = useMemo(
    () =>
      (
        campaignSubscription.data?.campaigns ||
        campaignQuery.data?.campaigns ||
        []
      ).filter(
        (d: Campaign) =>
          (selectedAdvertiserId == null ||
            d.advertiser_id === selectedAdvertiserId) &&
          (selectedBrandId == null || d.brand_id === selectedBrandId)
      ),
    [
      campaignQuery.data,
      campaignSubscription.data,
      selectedAdvertiserId,
      selectedBrandId,
    ]
  )
  let campaignsLoading = campaignQuery.loading && campaignSubscription.loading

  let [matchRateColumn, setMatchRateSelection] = useDependentState(
    location?.state?.matchRateColumn ? location?.state?.matchRateColumn : list?.healio_target_list ? "Specialty" : ENTIRE_LIST_COLUMN,
    [location?.state?.matchRateColumn]
  )

  let [userHasSelected, setUserHasSelected] = useState(false)
  let initialSelectedRowKeys = useMemo(() => [], [])
  let [selectedRowKeys, setSelectedRowKeys] = useDependentState<string[]>(
    initialSelectedRowKeys,
    [matchRateColumn]
  )

  let [submitSegmentsLoading, setSubmitSegmentsLoading] = useState(false)
  let [segmentCreationModalVisible, setSegmentCreationModalVisible] = useState(
    false
  )
  let [
    segmentCreationModalCampaignId,
    setSegmentCreationModalCampaignId,
  ] = useState<number | null>(null)
  let [
    segmentCreationRequestModalVisible,
    setSegmentCreationRequestModalVisible,
  ] = useState(false)
  let [listGlossaryModalVisible, setListGlossaryModalVisible] = useState(false)
  let [segmentErrorModalVisible, setSegmentErrorModalVisible] = useState(false)
  let [matchesLoading, setMatchesLoading] = useState(true)
  let [matches, setMatches] = useState<ListMatch[]>([])
  let listId = list?.id
  let listHealioTargetList = list?.healio_target_list
  useEffect(() => {
    if (listId == null) {
      return
    }

    if (matchRateColumn === ENTIRE_LIST_COLUMN && listHealioTargetList && !userHasSelected){
      setMatchRateSelection("Specialty")
      return;
    }

    setMatchesLoading(true)
    matchTargetList(matchRateColumn, listId)
      .then((resp: any) => {
        if (!isMounted) {
          return
        }

        let matches = Object.keys(resp.data)
          .map((k) => ({
            segment: k,
            healio_match: resp.data[k].healio_match,
            prog_match: resp.data[k].prog_match,
            total: resp.data[k].total,
            healio_percentage: resp.data[k].healio_percentage,
            prog_percentage: resp.data[k].prog_percentage,
          }))
          // Filter out segments with < 30 npis
          .filter(
            (d: ListMatch) => d.total >= 30 || d.segment === ENTIRE_LIST_COLUMN
          )
        matches.sort(listMatchSegmentSort)
        setMatches(matches)
      })
      .catch(catchCriticalError)
      .catch(catchTokenExpired)
      .finally(() => setMatchesLoading(false))
  }, [isMounted, setMatches, matchRateColumn, listId, listHealioTargetList, setMatchRateSelection, userHasSelected])

  let listColumnStr = list?.matchable_columns
  let matchableColumns = useMemo(() => {
    if (!listColumnStr) {
      return []
    }

    return listColumnStr.split("|")
  }, [listColumnStr])

  // These booleans are used to simplify logic elsewhere, Ex.
  // createNewCampaign is true if anything would require a new campaign to be
  // created
  let createNewAdvertiser = selectedAdvertiserId === CREATE_NEW_OPTION_VALUE
  let createNewBrand =
    createNewAdvertiser || selectedBrandId === CREATE_NEW_OPTION_VALUE
  let createNewCampaign =
    createNewBrand ||
    (!campaignsLoading && (campaigns?.length ?? 0) === 0) ||
    selectedCampaignId === CREATE_NEW_OPTION_VALUE

  let customNameSanitize = useCallback(
    (name: string) =>
      // Derive the segment names from the our advertiser and brand
      name
        .replace(/-/g, "_")
        .replace(/ +/g, "_")
        // Keep only alpha-numeric, dashes, periods, and underscores in name
        .replace(/[^a-zA-Z0-9_.]/g, ""),
    []
  )

  let advertiserName = useMemo(() => {
    let advertiserName = ""
    if (createNewAdvertiser) {
      advertiserName = customAdvertiser.value || advertiserName
    } else if (selectedAdvertiserId != null) {
      let lookup = advertiserLookup.get(selectedAdvertiserId)
      advertiserName = lookup?.name || advertiserName
    }

    return customNameSanitize(advertiserName)
  }, [
    createNewAdvertiser,
    selectedAdvertiserId,
    customNameSanitize,
    customAdvertiser,
    advertiserLookup,
  ])

  let brandName = useMemo(() => {
    let brandName = ""
    if (createNewBrand) {
      brandName = customBrand.value || brandName
    } else if (selectedBrandId != null) {
      let lookup = brandLookup.get(selectedBrandId)
      brandName = lookup?.name || brandName
    }

    return customNameSanitize(brandName)
  }, [
    createNewBrand,
    selectedBrandId,
    customNameSanitize,
    customBrand,
    brandLookup,
  ])

  let today = useMemo(() => moment().format("M_D_YYYY"), [])
  let segmentNamePrefix = useMemo(() => `${advertiserName}__${brandName}__`, [
    advertiserName,
    brandName,
  ])
  let segmentNameSuffix = useMemo(() => `__${today}.csv`, [today])

  let advertiserUnchosen = selectedAdvertiserId == null
  let brandUnchosen = selectedBrandId == null
  let campaignUnchosen = selectedCampaignId == null
  let channelUnchosen = selectedChannels == null

  // Each are invalid if we depend on the custom field, but it has an error
  let advertiserInvalid =
    (advertiserUnchosen || createNewAdvertiser) &&
    customAdvertiser.error != null
  let brandInvalid =
    (brandUnchosen || createNewBrand) && customBrand.error != null

  let campaignInvalid = !createNewBrand && campaignUnchosen
  let channelInvalid = createNewCampaign && channelUnchosen

  let nameSegmentsSectionDisabled =
    // Brand, advertiser, campaign  must be selected or created without errors
    advertiserInvalid ||
    brandInvalid ||
    campaignInvalid ||
    // If we're creating a campaign, then the channel must be selected or created without errors
    (createNewCampaign && channelInvalid)

  let segmentNameErrors: boolean = useMemo(() => {
    let segment: string
    for (segment of selectedRowKeys) {
      let error: undefined | string = segmentNameErrorMap.get(segment)
      if (error?.length) {
        return true
      }
    }
    return false
  }, [segmentNameErrorMap, selectedRowKeys])

  useEffect(() => {
    let segmentNameMap = new Map()
    let segmentRefs = new Map()
    let segment: string
    for (segment of selectedRowKeys) {
      segmentRefs.set(segment, createRef<MaskedInputRef>())
      if (segment === ENTIRE_LIST_COLUMN) {
        segmentNameMap.set(segment, "Entire_List")
      } else {
        segmentNameMap.set(segment, segment)
      }
    }
    setSegmentNameMap(segmentNameMap)
    setSegmentRefs(segmentRefs)
  }, [selectedRowKeys])

  useEffect(() => {
    for (let segment of selectedRowKeys) {
      let ref = segmentRefs.get(segment)
      if (ref?.current == null) {
        continue
      }

      if (segment === ENTIRE_LIST_COLUMN) {
        segment = "Entire_List"
      }
      ref.current.setValue(customNameSanitize(segment))
    }
  }, [selectedRowKeys, segmentRefs, customNameSanitize])

  let resetSegmentCreationOptions = () => {
    setSelectedRowKeys([])
    setSelectedAdvertiserId(undefined)
    setSelectedBrandId(undefined)
    setSelectedCampaignId(undefined)
    setSelectedAdvertiserId(undefined)
    setSelectedChannels(undefined)
    setMatchRateSelection(list?.healio_target_list ? "Specialty" : ENTIRE_LIST_COLUMN)
    customAdvertiser.reset()
    customBrand.reset()
  }

  // This is a convenient way to get a debounced onChange function per
  // segment. we debounce, so that every input change does not register a
  // rerender, where even the fastest of rendering cycles will show jitter.
  // The primary thing going on is that after 200ms of no activity we validate
  // the input for errors and update state then
  let segmentNameOnChange = useCallback(
    (segment: string) =>
      debounce((value: string) => {
        let clone = new Map(segmentNameMap)
        clone.set(segment, value)
        setSegmentNameMap(clone)

        clone = new Map(segmentNameErrorMap)
        segmentNameSchema
          .validate(value, { abortEarly: true })
          .then(() => {
            if (isMounted) {
              clone.set(segment, "")
              setSegmentNameErrorMap(clone)
            }
          })
          .catch((error) => {
            if (isMounted) {
              clone.set(segment, error.errors[0])
              setSegmentNameErrorMap(clone)
            }
          })
      }, 200),
    [isMounted, segmentNameErrorMap, segmentNameMap]
  )

  return {
    listId,
    history,
    lastLocation,
    setLastLocation,
    onMatchRateColumnSelect: (e: RadioChangeEvent) => {
      setMatchRateSelection(e.target.value)
      setUserHasSelected(true)
    },
    matchRateColumn,
    matchableColumns,
    matches,
    matchesLoading,
    onCreateSegments: () => {
      history.push({
        pathname: `/lists/${id}/create_segments`,
        state: { matchRateColumn },
      })
    },
    selectedRowKeys,
    onRowSelection: (_keys: any, selectedRows: ListMatch[]) => {
      setSelectedRowKeys(selectedRows.map((d) => d.segment))
    },
    selectedAdvertiserId,
    onAdvertiserSelect: (value: SelectValue) => {
      setSelectedAdvertiserId(value as number)

      // Reset selected
      setSelectedBrandId(undefined)
      setSelectedCampaignId(undefined)
      setSelectedChannels(undefined)
    },
    advertisers,

    selectedBrandId,
    onBrandSelect: (value: SelectValue) => {
      setSelectedBrandId(value as number)

      // Reset selected
      setSelectedCampaignId(undefined)
      setSelectedChannels(undefined)
    },
    brands,

    selectedCampaignId,
    onCampaignSelect: (value: SelectValue) => {
      setSelectedCampaignId(value as number)
    },
    campaigns,

    selectedChannels,
    onChannelSelect: (channels: unknown) => {
      setSelectedChannels(channels as Channel[])
    },
    customAdvertiser,
    customBrand,

    advertiserSelectDisabled: selectedRowKeys.length === 0,
    brandSelectDisabled: createNewAdvertiser || advertiserUnchosen,
    campaignSelectDisabled: createNewBrand || brandUnchosen,

    createNewCampaign,
    createNewBrand,
    createNewAdvertiser,

    assignToCampaignSectionDisabled: selectedRowKeys.length === 0,
    nameSegmentsSectionDisabled,

    segmentNamePrefix,
    segmentNameSuffix,
    segmentNameMap,
    segmentNameErrorMap,
    segmentRefs,

    segmentNameOnChange,
    submitSegmentsDisabled: nameSegmentsSectionDisabled || segmentNameErrors,
    submitSegmentsLoading,
    listGlossaryModalVisible,
    setListGlossaryModalVisible,
    segmentCreationModalVisible,
    setSegmentCreationModalVisible,
    segmentCreationRequestModalVisible,
    setSegmentCreationRequestModalVisible,
    segmentErrorModalVisible,
    setSegmentErrorModalVisible,
    resetSegmentCreationOptions,
    segmentCreationModalViewCampaign: () => {
      history.push({
        pathname: `/campaigns/${segmentCreationModalCampaignId}/details`,
      })
    },
    segmentCreationModalCreateAnotherSegment: () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      resetSegmentCreationOptions()
      setSegmentCreationModalVisible(false)
    },
    segmentCreationRequestModalCreateAnotherSegment: () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      resetSegmentCreationOptions()
      setSegmentCreationRequestModalVisible(false)
    },
    segmentCreationRequestModalViewPendingSegments: () => {
      resetSegmentCreationOptions()
      setSegmentCreationRequestModalVisible(false)
      history.push({
        pathname: "/segments",
      })
    },
    submitSegmentsForCreation: () => {
      setSubmitSegmentsLoading(true)
      let createSegmentRequest = {
        column: matchRateColumn,
        // We check if we're creating a new advertiser, because in that case
        // the selectedAdvertiserId will be -1, for the "Create New" option,
        // ditto for the brand/campaign
        advertiser: createNewAdvertiser
          ? {
              name: customAdvertiser.value,
            }
          : {
              id: selectedAdvertiserId,
            },
        brand: createNewBrand
          ? {
              name: customBrand.value,
            }
          : {
              id: selectedBrandId,
            },
        campaign: createNewCampaign
          ? null
          : {
              id: selectedCampaignId,
            },
        channel: (selectedChannels || []).join(", "),
        segments: Array.from(segmentNameMap).map(([segment, name]) => [
          segment,
          `${segmentNamePrefix}${name}${segmentNameSuffix}`,
        ]),
      }
      if (!list?.id) {
        throw new Error("Missing TargetList")
      }
      createSegments(createSegmentRequest, list?.id)
        .then(() => {
          if (!isMounted) {
            return
          }
          if (createNewCampaign) {
            setSegmentCreationRequestModalVisible(true)
          } else {
            // We duplicate the selected campaign id here, for the purpose of segmentCreationModal, that would otherwise be lost in resetSegmentCreationOptions
            setSegmentCreationModalCampaignId(selectedCampaignId as number)
            setSegmentCreationModalVisible(true)
          }

          resetSegmentCreationOptions()
        })
        .catch(catchCriticalError)
        .catch(catchTokenExpired)
        .catch(() => {
          if (!isMounted) {
            return
          }
          setSegmentErrorModalVisible(true)
        })
        .finally(() => {
          if (!isMounted) {
            return
          }
          setSubmitSegmentsLoading(false)
        })
    },
  }
}
let useController = () => {
  let { path, url } = useRouteMatch()
  let location = useLocation()
  let { id } = useParams()
  let history = useHistory()
  let listQuery = useQuery(LIST_QUERY, { variables: { id } })
  let listSubscription = useSubscription(LIST_SUBSCRIPTION, {
    variables: { id },
  })
  let list: List | null = useMemo(
    () =>
      listSubscription.data?.target_lists_by_pk ||
      listQuery.data?.target_lists_by_pk ||
      null,
    [listQuery, listSubscription]
  )
  let matchRateTab = useMatchRateTab(list)
  let createSegmentsTab = useCreateSegmentsTab(list)

  // We are using a negative check here, because if the url is lists/:id/ or
  // lists/;id/match_rates should be isMatchRates = True
  let isMatchRates = location.pathname !== url + "/create_segments"

  return {
    renderPieChart: list?.total_npi_count != null,
    activeKey: location.pathname,
    isMatchRates,
    id,
    url,
    path,
    list,
    onTabChange: history.push,
    matchRateTab,
    createSegmentsTab,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
