import React, { ComponentProps, FunctionComponent } from "react"
import { Curve, PieChart, Pie, Cell } from "recharts"
import { greenBase, blueBase } from "../colors"

function renderFirstLabelLine(props: any) {
  if (props.index !== 0) {
    return null
  }

  return <Curve {...props} type="linear" className="recharts-pie-label-line" />
}

const renderFirstLabel: ComponentProps<typeof Pie>["label"] = ({
  x,
  y,
  fill,
  cx,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  // http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
  // https://stackoverflow.com/questions/56104004/rechart-adding-labels-to-charts

  if (typeof innerRadius != "number") {
    throw new Error("Expected innerRadius to be a number")
  }
  if (typeof outerRadius != "number") {
    throw new Error("Expected outerRadius to be a number")
  }
  if (typeof cx != "number") {
    throw new Error("Expected cx to be a number")
  }

  if (percent == null) {
    return null
  }

  if (index !== 0) {
    return null
  }

  return (
    <g>
      <text
        x={x > cx ? x + 3 : x - 3}
        y={y}
        fill={fill}
        dominantBaseline="central"
        fontSize="24px"
        fontWeight="bold"
        fontFamily="Roboto Condensed"
        textAnchor={x > cx ? "start" : "end"}
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    </g>
  )
}

type ViewProps = {
  data: { value: number | undefined; name: string | undefined }[]
  width?: number
  height?: number
  size?: number
  startAngle?: number
  endAngle?: number
  labels?: boolean
  colors?: string[]
}
let View: FunctionComponent<ViewProps> = (props) => {
  let {
    data,
    labels = true,
    startAngle = 0,
    endAngle = 360,
    colors = [greenBase, blueBase],
    width,
    height,
    size = 250,
  } = props

  if (width == null || height == null) {
    height = size
    width = labels ? (4 / 3) * height : height
  }

  return (
    <PieChart width={width} height={height}>
      <Pie
        dataKey="value"
        data={data}
        outerRadius={height / (labels ? 2.5 : 2)}
        startAngle={startAngle}
        endAngle={endAngle}
        // disable animations: a bug with animation and showing chart labels
        isAnimationActive={false}
        labelLine={labels ? renderFirstLabelLine : true}
        label={labels ? renderFirstLabel : false}
      >
        {data.map((_entry, index) => (
          <Cell fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  )
}

export default View
