import React, { FunctionComponent } from "react"

import { Link } from "react-router-dom"
import DetailTable from "./DetailTable"
import { silver3 } from "../colors"
import px from "../px"

const View: FunctionComponent = () => {
  return (
    <>
      <div style={{ marginBottom: px(3) }}>
        <DetailTable title="Detail table title">
          <div style={{ height: "40px", backgroundColor: silver3 }} />
          <div style={{ height: "40px", backgroundColor: silver3 }} />
          <div style={{ height: "40px", backgroundColor: silver3 }} />
        </DetailTable>
      </div>
      <DetailTable
        title="Detail table with subtitle"
        subTitle={<Link to="/sandbox">From sandbox: lorem ipsum bluah</Link>}
      >
        <div style={{ height: "40px", backgroundColor: silver3 }} />
        <div style={{ height: "40px", backgroundColor: silver3 }} />
        <div style={{ height: "40px", backgroundColor: silver3 }} />
      </DetailTable>
    </>
  )
}
export default View
