import React, { FunctionComponent } from "react"

let View: FunctionComponent = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-15.5 -15.5 82 82"
      enableBackground="new -15.5 -15.5 82 82"
    >
      <path d="M25.5-11.246c20.264,0,36.745,16.483,36.745,36.746c0,20.264-16.481,36.745-36.745,36.745 S-11.246,45.764-11.246,25.5C-11.246,5.238,5.236-11.246,25.5-11.246 M25.5-14.5c-22.091,0-40,17.909-40,40 c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40C65.5,3.409,47.591-14.5,25.5-14.5L25.5-14.5z M-6.418,47.185 c1.09,1.087,2.857,1.087,3.946,0l13.88-13.243l7.773,7.771c1.09,1.094,2.857,1.094,3.947,0l14.362-15.002l5.143,5.146 c2.638,2.638,7.147,0.769,7.147-2.959V11.297c0-1.543-1.249-2.791-2.791-2.791H29.387c-3.728,0-5.594,4.508-2.959,7.145l5.146,5.142 L21.159,31.85l-7.774-7.772c-1.09-1.09-2.857-1.09-3.947,0L-8.392,41.267c-1.09,1.087-1.09,2.854,0,3.944L-6.418,47.185 L-6.418,47.185z" />
    </svg>
  )
}

export default View
