import React, { FunctionComponent } from "react"

import { grayBase8 } from "../colors"
import px from "../px"
import { WidthScrollbarContainer } from "../components/WidthContainer"

const View: FunctionComponent = (props) => {
  return (
    <div style={{ height: "100%", backgroundColor: grayBase8 }}>
      <WidthScrollbarContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: px(10),
          }}
        >
          {props.children}
        </div>
      </WidthScrollbarContainer>
    </div>
  )
}

export default View
