import React, { FunctionComponent } from "react"
import { Row, Col } from "antd"
import intersperse from "intersperse"

import { silver3 } from "../colors"
import px from "../px"
import { indexSearchHeight } from "../constants"

type ViewProps = {}
const View: FunctionComponent<ViewProps> = (props) => {
  return (
    <div
      style={{
        border: `1px solid ${silver3}`,
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: `${indexSearchHeight}px`,
        justifyContent: "center",
      }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{ padding: `0px ${px(2.5)}` }}
      >
        {intersperse(
          React.Children.toArray(props.children).map((child) => (
            <Col flex={1}>{child}</Col>
          )),
          <Col>
            <div style={{ width: px(2.5) }} />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default View
