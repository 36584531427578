import React, {
  FunctionComponent,
  FormEvent,
  useState,
  useCallback,
} from "react"
import { Link } from "react-router-dom"
import { message, Input } from "antd"
import * as Yup from "yup"

import { requestPasswordReset, catchCriticalError } from "core_ui/lib/api"
import formatError from "core_ui/lib/formatError"
import { useField } from "core_ui/lib/hooks"

import px from "../px"
import { redBase } from "../colors"
import PrimaryButton from "./PrimaryButton"
//import { silverBase5 } from "../colors"
import sharedStyles from "../sharedStyles"

let emailSchema = Yup.string()
  .email("Please enter a valid Email Address")
  .trim()
  .required("Enter your Email Address")

type ViewProps = ReturnType<typeof useController>
export const View: FunctionComponent<ViewProps> = (props) => {
  let { email, loading, onSubmit, emailSent } = props

  const buttonProps = {
    label: "Submit",
    loading,
    disabled: loading,
    htmlType: "submit" as const,
  }

  return (
    <>
      {emailSent ? (
        <div style={{ fontSize: px(2), marginBottom: px(4) }}>
          Please check your email. You should receive a password reset link
          soon.
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div style={{ marginBottom: px(4) }}>
            <label
              htmlFor="email"
              style={{
                display: "block",
                fontSize: px(2),
                marginBottom: px(0.25),
              }}
            >
              Email<span style={{ color: redBase }}>*</span>
            </label>
            <Input
              className={email.touched && email.error ? "error" : ""}
              id="email"
              value={email.value}
              onBlur={email.onBlur}
              onChange={email.onChange}
              placeholder="Email"
              name="email"
              style={{
                borderRadius: px(0.5),
              }}
            />
            <div style={sharedStyles.fieldError}>
              {email.touched && email.error}
            </div>
          </div>
          <div
            style={{
              marginBottom: px(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PrimaryButton {...buttonProps}>{buttonProps.label}</PrimaryButton>
          </div>
          <div
            style={{
              marginBottom: px(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: px(2) }}>Or go back to</span>&nbsp;
            <Link
              style={{
                fontSize: px(2),
                textDecoration: "underline",
                textUnderlinePosition: "under",
              }}
              to="/signin"
            >
              Sign In
            </Link>
          </div>
        </form>
      )}
    </>
  )
}

const useController = () => {
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  let email = useField({
    initial: "",
    schema: emailSchema,
  })
  const onSubmit = useCallback(
    (e: FormEvent) => {
      // Prevent default action of a form submit
      e.preventDefault()

      // Mark all fields as touched
      email.setTouched(true)

      if (email.error) {
        return
      }

      setLoading(true)
      requestPasswordReset({ email: email.value.trim() })
        .then(() => {
          setEmailSent(true)
        })
        .catch(catchCriticalError)
        .catch((error) => {
          if (error?.response?.data?.error === "User account does not exist") {
            email.setError(error.response.data.error)
          } else if (error?.response?.data?.email) {
            email.setError(error.response.data.email)
          } else {
            message.error(formatError(error))
          }
        })
        .finally(() => setLoading(false))
    },
    [email]
  )

  return {
    email,
    loading,
    onSubmit,
    emailSent,
  }
}

const Component: FunctionComponent = () => {
  let viewProps = useController()
  return <View {...viewProps} />
}

export default Component
