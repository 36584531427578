import React, { CSSProperties, FunctionComponent } from "react"

import { useSelector } from "react-redux"

import { getWindowWidth } from "../selectors"

const estimatedScrollbarWidth = 16.666

// This component centers its children in a column via auto margins. If the
// column grows in size such that a vertical scrollbar would appear, the
// component is smart enough to tweak the available width so that the
// appearance and disappearance of the scrollbar causes no shift in alignment
//
// This component also does this calculation for two desired content widths
// 1440px and 1200px. It chooses the largest of the two, s.t. it can still
// perform the scrollbar adjustment.
const WidthScrollbarContainer: FunctionComponent<{ style?: CSSProperties }> = (
  props
) => {
  let windowWidth = useSelector(getWindowWidth)
  let contentWidth =
    windowWidth > 1440 + estimatedScrollbarWidth * 2 ? 1440 : 1200

  // Perhaps this is more complex than necessary, there is a neat trick to
  // computing the actual width of the right hand scrollbar which is an
  // otherwise browser specific value. It's 100vh - 100%, i.e. the viewport
  // width minus the content width.
  //
  // The issue with the scrollbar appearance/disappearance is that it
  // subtracts from the available width, just like if someone were resizing
  // the browser window, on resize you would expect content that is centered
  // to shift to adjust to the moving center.
  //
  // However, this is jarring when a user is not explicitly resizing the page
  // and happens to switch to a view that triggers the scrollbar and a
  // corresponding horizontal shift occurs.
  //
  // We counter this by introducing an equal and opposite shift via padding,
  // in order to do this shift via padding which is part of the width, we
  // simultaneously increase the width by the shift. We could do this shift
  // via margin, but then we could not use auto margins.
  let addScrollbarWidth =
    windowWidth > contentWidth + estimatedScrollbarWidth * 2

  let contentContainerWidth = addScrollbarWidth
    ? `calc(${contentWidth}px + (100vw - 100%))`
    : `${contentWidth}px`
  let contentContainerPadding = addScrollbarWidth
    ? "0px 0px 0px calc(100vw - 100%)"
    : `0px ${estimatedScrollbarWidth}px`
  return (
    <div
      style={{
        width: contentContainerWidth,
        padding: contentContainerPadding,
        boxSizing: addScrollbarWidth ? "border-box" : "content-box",
        margin: "0px auto",
        ...props?.style,
      }}
    >
      {props.children}
    </div>
  )
}

// This will always have the same width (padding included) as the
// WidthScrollbarContainer, but it exposes the full width to its children so
// that there are no margins or padding around them.
const WidthContainer: FunctionComponent<{ style?: CSSProperties }> = (
  props
) => {
  let windowWidth = useSelector(getWindowWidth)
  let contentWidth =
    windowWidth > 1440 + estimatedScrollbarWidth * 2 ? 1440 : 1200
  let addScrollbarWidth =
    windowWidth > contentWidth + estimatedScrollbarWidth * 2

  return (
    <div
      style={{
        minWidth: addScrollbarWidth
          ? `calc(${contentWidth}px + (100vw - 100%))`
          : `${contentWidth + 2 * estimatedScrollbarWidth}px`,
        ...props?.style,
      }}
    >
      {props.children}
    </div>
  )
}

export { WidthScrollbarContainer, WidthContainer }
