import { CSVExportableColumnType } from "../pages/SegmentDetail"

export default function gatherCsvData<T>(
  data: T[],
  columnDefs: CSVExportableColumnType<T>[]
): string {
  let rows = []

  rows.push(
    columnDefs
      .map((cd) => {
        let cell = cd.title.toString()
        return cleanCsvCell(cell)
      })
      .join(",")
  )

  for (let i = 0; i < data.length; i++) {
    rows.push(
      columnDefs
        .map((cd) => {
          if (cd.csvRender) {
            let cell = cd.csvRender(data[i], i)
            return cleanCsvCell(cell)
          } else {
            return ""
          }
        })
        .join(",")
    )
  }

  return rows.join("\r\n")
}

function cleanCsvCell(cell: string): string {
  if (cell.replace(/ /g, "").match(/[\s,"]/)) {
    return '"' + cell.replace(/"/g, '""') + '"'
  }
  return cell
}
