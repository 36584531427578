import React, { useMemo, FunctionComponent } from "react"
import {
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom"
import { Row, Col } from "antd"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"

import { formatDateWithFormats } from "core_ui/lib/formatDate"
import formatNumber from "core_ui/lib/formatNumber"
import { formatMatchRate, EstimatedMonthlyImpressions } from "./ListDetail"
import sharedStyles from "../sharedStyles"
import IndexTitle from "../components/IndexTitle"
import DetailTable from "../components/DetailTable"

import px from "../px"

import {
  WidthContainer,
  WidthScrollbarContainer,
} from "../components/WidthContainer"
import { blackBase, grayBase8, grayBase2, greenBase } from "../colors"
import { indexSearchHeight, indexTitleHeight } from "../constants"
import DetailAdditionalMetadataTable from "../components/DetailAdditionalMetadataTable"
import moment from "moment"
import PieChart from "../components/PieChart"
import { ColumnType } from "antd/es/table"

export const SEGMENT_FRAGMENT = gql`
  fragment segment on segments {
    id
    filename
    created_at
    total_npi_count
    matched_npi_count
    start_date
    end_date
    campaign {
      id
      name
    }
    target_list {
      id
      filename
      total_npi_count
      matched_npi_count
      start_date
      end_date
    }
  }
`

export const SEGMENT_QUERY = gql`
  query Segment($id: Int!) {
    segments_by_pk(id: $id) {
      ...segment
    }
  }
  ${SEGMENT_FRAGMENT}
`

const SEGMENT_SUBSCRIPTION = gql`
  subscription Segment($id: Int!) {
    segments_by_pk(id: $id) {
      ...segment
    }
  }
  ${SEGMENT_FRAGMENT}
`

export interface Segment {
  id: number
  filename: string
  created_at: string
  status: string
  total_npi_count: number
  matched_npi_count: number
  start_date: string
  end_date: string
  target_list: {
    id: number
    filename: string
    matched_npi_count: number
    total_npi_count: number
    start_date: string
    end_date: string
  }
  campaign?: {
    id: number
    name: string
  }
}

export function getDspEta(createdDate: string | null) {
  if (createdDate == null) {
    return "N/A"
  }
  let dspEta = moment(createdDate).add(10, "days")

  // We'll likely want to check the liveramp api and update segments tbh
  if (moment() > dspEta) {
    return "Arrived"
  }
  return dspEta.format("MM/DD/YYYY")
}

export function getStartDate(segment: Segment | null) {
  return segment?.start_date ?? segment?.target_list.start_date
}

export function getStartDateFormatted(segment: Segment | null) {
  if (segment && segment.campaign == null) {
    return "Pending"
  }
  let startDate = getStartDate(segment)
  if (!startDate) {
    return "Not Set"
  }
  return formatDateWithFormats(startDate, "MM/DD/YYYY", false)
}

export function getEndDate(segment: Segment | null) {
  return segment?.end_date ?? segment?.target_list.end_date
}

export function getEndDateFormatted(segment: Segment | null) {
  if (segment && segment.campaign == null) {
    return "Pending"
  }
  let endDate = getEndDate(segment)
  if (!endDate) {
    return "Not Set"
  }
  return formatDateWithFormats(endDate, "MM/DD/YYYY", false)
}

export interface CSVExportableColumnType<T> extends ColumnType<T> {
  csvRender(record: T, index: number): string
  title: string
}

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  let { data, renderPieChart } = props
  return (
    <div
      style={{
        marginTop: px(6),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <WidthScrollbarContainer>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Row style={{ height: px(6), marginBottom: px(4) }}>
            <Col>
              <IndexTitle title="Segment Detail" />
            </Col>
            <Col
              style={{
                marginLeft: "auto",
                marginTop: `${(indexTitleHeight - indexSearchHeight) / 2}px`,
              }}
            >
              <DetailAdditionalMetadataTable>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      color: blackBase,
                      fontSize: px(1.5),
                      lineHeight: px(1.5),
                      marginBottom: px(0.5),
                      textTransform: "uppercase",
                    }}
                  >
                    Creation Date
                  </div>
                  <div
                    style={{
                      fontSize: px(2),
                      lineHeight: px(2),
                      textTransform: "uppercase",
                      color: grayBase8,
                    }}
                  >
                    {data?.created_at
                      ? formatDateWithFormats(
                          data?.created_at,
                          "MM/DD/YYYY",
                          false
                        )
                      : "Not Set"}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      color: blackBase,
                      fontSize: px(1.5),
                      lineHeight: px(1.5),
                      marginBottom: px(0.5),
                      textTransform: "uppercase",
                    }}
                  >
                    Start Date
                  </div>
                  <div
                    style={{
                      fontSize: px(2),
                      lineHeight: px(2),
                      textTransform: "uppercase",
                      color: grayBase8,
                    }}
                  >
                    {getStartDateFormatted(data)}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: blackBase,
                      fontSize: px(1.5),
                      lineHeight: px(1.5),
                      marginBottom: px(0.5),
                      textTransform: "uppercase",
                    }}
                  >
                    End Date
                  </div>
                  <div
                    style={{
                      fontSize: px(2),
                      lineHeight: px(2),
                      textTransform: "uppercase",
                      color: grayBase8,
                    }}
                  >
                    {getEndDateFormatted(data)}
                  </div>
                </div>
              </DetailAdditionalMetadataTable>
            </Col>
          </Row>
          <Row style={{ marginBottom: px(6) }}>
            <Col flex={1}>
              <DetailTable
                title={data?.filename || ""}
                subTitle={
                  <Link
                    to={`/lists/${data?.target_list?.id}`}
                  >{`From list: ${data?.target_list?.filename}`}</Link>
                }
                gridTemplateColumns={`minmax(auto, 40%) ${px(4)} auto ${px(
                  4
                )} auto ${px(4)} auto`}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      Campaign
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {data && data.campaign == null
                        ? "Pending"
                        : data?.campaign?.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {renderPieChart ? (
                      <div style={{ width: "65px" }}>
                        <PieChart
                          size={65}
                          colors={[greenBase, grayBase2]}
                          data={[
                            {
                              name: "Matched",
                              value: data?.matched_npi_count ?? 0,
                            },
                            {
                              name: "Total",
                              value:
                                (data?.total_npi_count ?? 0) -
                                (data?.matched_npi_count ?? 0),
                            },
                          ]}
                          startAngle={90}
                          endAngle={-270}
                          labels={false}
                        />
                      </div>
                    ) : null}
                    <div
                      style={{
                        marginLeft: renderPieChart ? px(1.5) : px(0),
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <div style={sharedStyles.detailTableColumnHeader}>
                        NPIs Matched
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {formatMatchRate(data)}
                          </div>
                          <div style={sharedStyles.matchColumnSmallHeader}>
                            RATE
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: px(1.5),
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {data?.total_npi_count == null
                              ? "-"
                              : formatNumber(data?.total_npi_count)}
                          </div>
                          <div style={sharedStyles.matchColumnSmallHeader}>
                            TOTAL
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: px(1.5),
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={sharedStyles.detailTableColumnContent}>
                            {data?.matched_npi_count == null
                              ? "-"
                              : formatNumber(data?.matched_npi_count)}
                          </div>
                          <div style={sharedStyles.matchColumnSmallHeader}>
                            MATCHED
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <EstimatedMonthlyImpressions
                  matchCount={data?.matched_npi_count}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={sharedStyles.detailTableColumnHeader}>
                      DSP ETA
                    </div>
                    <div style={sharedStyles.detailTableColumnContent}>
                      {getDspEta(data?.created_at ?? null)}
                    </div>
                  </div>
                </div>
              </DetailTable>
            </Col>
          </Row>
        </div>
      </WidthScrollbarContainer>
      {/* Make the width container also a flex column, so we can occupy the
            remaining vertical space */}
      <WidthContainer
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <div style={sharedStyles.detailTabContainer} />
      </WidthContainer>
    </div>
  )
}

let useController = () => {
  let { path, url } = useRouteMatch()
  let location = useLocation()
  let { id } = useParams()
  let history = useHistory()
  let segmentQuery = useQuery(SEGMENT_QUERY, { variables: { id } })
  let segmentSubscription = useSubscription(SEGMENT_SUBSCRIPTION, {
    variables: { id },
  })
  let data: Segment | null = useMemo(
    () =>
      segmentSubscription.data?.segments_by_pk ||
      segmentQuery.data?.segments_by_pk ||
      null,
    [segmentQuery, segmentSubscription]
  )

  return {
    renderPieChart: data?.total_npi_count != null,
    loading: segmentSubscription.loading && segmentQuery.loading,
    data,
    onTabChange: history.push,
    activeKey: location.pathname,
    id,
    url,
    path,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
