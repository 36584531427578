import { Action as ReduxAction } from "redux"

export type State = {
  windowWidth: number
  token: null | string
}

export let initialState: State = {
  windowWidth: window.innerWidth,
  token: localStorage.getItem("token"),
}

export enum ActionType {
  RESIZE = "RESIZE",
  USER_AUTHENTICATED = "USER_AUTHENTICATED",
}
type Action = ReduxAction<ActionType> & { data?: any }

export const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.RESIZE:
      return { ...state, windowWidth: action.data }
    case ActionType.USER_AUTHENTICATED:
      return { ...state, token: action.data }
    default:
      return state
  }
}
