import { useEffect, useState, useCallback } from "react";
import useMounted from "./useMounted";
var useField = function (_a) {
    var initial = _a.initial, schema = _a.schema, ref = _a.ref;
    var isMounted = useMounted();
    var _b = useState(initial), value = _b[0], setValue_ = _b[1];
    var _c = useState(false), touched = _c[0], setTouched = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    // Wraps setValue_ to update the value in the ref
    var setValue = useCallback(function (value) {
        if ((ref === null || ref === void 0 ? void 0 : ref.current) != null) {
            ref.current.value = value;
        }
        setValue_(value);
    }, [setValue_, ref]);
    var validateAndSetError = useCallback(function (value) {
        schema
            .validate(value)
            .then(function () {
            if (isMounted) {
                setError(null);
            }
        })
            .catch(function (error) {
            if (isMounted) {
                setError(error.errors[0]);
            }
        });
    }, [isMounted, setError, schema]);
    useEffect(function () {
        validateAndSetError(value);
    }, [value, validateAndSetError]);
    var onChange = useCallback(function (e) {
        setValue(e.currentTarget.value);
    }, [setValue]);
    var onBlur = useCallback(function () {
        setTouched(true);
    }, [setTouched]);
    // This is an alternative to the default onBlur, it is useful for a
    // performance optimization. Rather than controlling the input by passing a
    // value to the input, we leave it uncontrolled, but we hook up the onBlur
    // to set the value when it loses focus, this avoids the state update per
    // keystroke, which is jittery/slow and is preferrable to using a debounce
    // which causes spans of time where user has inputted text but the state
    // update is pending, the caveat is that onBlur must fire, this could be
    // improved by setting a timer to fire even if the onBlur does not, in my
    // experience onBlur is quite reliable
    var onBlurWithOnChange = useCallback(function (e) {
        onBlur();
        onChange(e);
    }, [onBlur, onChange]);
    var onChangeValidateOnly = useCallback(function (e) {
        validateAndSetError(e.currentTarget.value);
    }, [validateAndSetError]);
    return {
        ref: ref,
        value: value,
        setValue: setValue,
        error: error,
        setError: setError,
        touched: touched,
        setTouched: setTouched,
        onChange: onChange,
        onChangeValidateOnly: onChangeValidateOnly,
        onBlur: onBlur,
        onBlurWithOnChange: onBlurWithOnChange,
    };
};
export default useField;
