import { useMemo, useRef, useEffect, useState, useCallback } from "react"
import { Input } from "antd"
import { useMounted } from "core_ui/lib/hooks"
import debounce from "lodash.debounce"

let useInputField = ({ initial, schema }: { initial?: any; schema: any }) => {
  let ref = useRef<Input>(null)
  let isMounted = useMounted()
  let [value, setValue_] = useState(initial)
  let [touched, setTouched] = useState(false)
  let [error, setError] = useState<string | null>(null)

  // Wraps setValue_ to update the value in the ref
  let setValue = useCallback(
    (value?: string) => {
      if (ref?.current != null) {
        ref?.current.setState({ value })
      }
      setValue_(value)
    },
    [setValue_, ref]
  )

  let validateAndSetError = useCallback(
    (value: any) => {
      schema
        .validate(value)
        .then(() => {
          if (isMounted) {
            setError(null)
          }
        })
        .catch((error: { errors: any[] }) => {
          if (isMounted) {
            setError(error.errors[0])
          }
        })
    },
    [isMounted, setError, schema]
  )

  useEffect(() => {
    validateAndSetError(value)
  }, [value, validateAndSetError])

  let onChange = useCallback(
    (e: React.FormEvent<{ value: any }>) => {
      setValue(e.currentTarget.value)
    },
    [setValue]
  )
  let onBlur = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  let debouncedSetValue = useMemo(() => debounce(setValue, 200), [setValue])
  let debouncedOnChange = useCallback(
    (e: React.FormEvent<{ value: any }>) => {
      debouncedSetValue(e.currentTarget.value)
    },
    [debouncedSetValue]
  )

  // This is an alternative to the default onBlur, it is useful for a
  // performance optimization. Rather than controlling the input by passing a
  // value to the input, we leave it uncontrolled, but we hook up the onBlur
  // to set the value when it loses focus, this avoids the state update per
  // keystroke, which is jittery/slow and is preferrable to using a debounce
  // which causes spans of time where user has inputted text but the state
  // update is pending, the caveat is that onBlur must fire, this could be
  // improved by setting a timer to fire even if the onBlur does not, in my
  // experience onBlur is quite reliable
  let onBlurWithOnChange = useCallback(
    (e: React.FormEvent<{ value: any }>) => {
      onBlur()
      onChange(e)
    },
    [onBlur, onChange]
  )
  let onChangeValidateOnly = useCallback(
    (e: React.FormEvent<{ value: any }>) => {
      validateAndSetError(e.currentTarget.value)
    },
    [validateAndSetError]
  )

  let reset = useCallback(() => {
    setTouched(false)
    setValue(initial)
  }, [initial, setValue])

  return {
    ref,
    value,
    setValue,
    error,
    setError,
    touched,
    setTouched,
    onChange,
    onChangeValidateOnly,
    debouncedOnChange,
    onBlur,
    onBlurWithOnChange,
    reset,
  }
}

export default useInputField
