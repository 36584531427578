import React, { ComponentProps, FunctionComponent } from "react"

import px from "../px"
import PrimaryButton from "./PrimaryButton"
import SecondaryButton from "./SecondaryButton"
import SegmentModal from "./SegmentModal"

type ViewProps = {
  onLeave: () => void
  onContinue: () => void
} & Omit<ComponentProps<typeof SegmentModal>, "title">
let View: FunctionComponent<ViewProps> = (props) => {
  let { onLeave, onContinue, ...restProps } = props
  return (
    <SegmentModal title="Are you sure you want to leave?" {...restProps}>
      <div style={{ textAlign: "center", fontSize: px(2) }}>
        You haven't created your segments yet.
      </div>
      <PrimaryButton onClick={onLeave}>Leave</PrimaryButton>
      <SecondaryButton onClick={onContinue}>
        Continue with segments
      </SecondaryButton>
    </SegmentModal>
  )
}

export default View
