import { fork } from "redux-saga/effects"

import resizeSaga from "./resizeSaga"
import authSaga from "./authSaga"

function* saga() {
  yield fork(resizeSaga)
  yield fork(authSaga)
}

export default saga
