import React, { FunctionComponent } from "react"

let View: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      width="82px"
      height="82px"
      viewBox="0 0 82 82"
      enableBackground="new 0 0 82 82"
    >
      <g>
        <path d="M41,4.256c20.263,0,36.747,16.483,36.747,36.744c0,20.262-16.484,36.746-36.747,36.746   C20.739,77.746,4.256,61.262,4.256,41C4.254,20.739,20.737,4.256,41,4.256 M41,1C18.909,1,1,18.91,1,41c0,22.093,17.909,40,40,40   c22.094,0,40.002-17.907,40.002-40C81.002,18.91,63.093,1,41,1L41,1z" />
        <g>
          <path d="M33.098,58.73L16.976,42.609c-0.97-0.969-0.97-2.543,0-3.507l3.507-3.509c0.969-0.969,2.539-0.969,3.507,0    l10.861,10.861l23.26-23.261c0.969-0.969,2.54-0.969,3.506,0l3.509,3.509c0.967,0.968,0.967,2.54,0,3.507L36.601,58.73    C35.638,59.699,34.065,59.699,33.098,58.73L33.098,58.73z" />
        </g>
      </g>
    </svg>
  )
  /* <svg */
  /*   version="1.1" */
  /*   id="Layer_1" */
  /*   xmlns="http://www.w3.org/2000/svg" */
  /*   x="0px" */
  /*   y="0px" */
  /*   viewBox="-15.5 -15.5 82 82" */
  /*   enableBackground="new -15.5 -15.5 82 82" */
  /* > */
  /*   <path */
  /*     d="M25.5-11.246c20.262,0,36.745,16.483,36.745,36.746c0,20.262-16.483,36.745-36.745,36.745 */
  /*     c-20.262,0-36.746-16.483-36.746-36.745C-11.246,5.238,5.238-11.246,25.5-11.246 M25.5-14.5c-22.091,0-40,17.909-40,40 */
  /*     c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40C65.5,3.409,47.591-14.5,25.5-14.5L25.5-14.5z M11.568,29.05l9.667-10.083 */
  /*     v24.382c0,1.776,1.429,3.203,3.206,3.203h2.136c1.776,0,3.205-1.427,3.205-3.203V18.966L39.45,29.05 */
  /*     c1.241,1.294,3.311,1.32,4.578,0.051l1.456-1.468c1.255-1.254,1.255-3.285,0-4.527L27.778,5.386c-1.253-1.254-3.284-1.254-4.523,0 */
  /*     L5.518,23.106c-1.256,1.255-1.256,3.286,0,4.527l1.454,1.468C8.255,30.37,10.324,30.344,11.568,29.05z" */
  /*   /> */
  /* </svg> */
}

export default View
