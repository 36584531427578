import React, { FunctionComponent } from "react"

import NumberedPageSection from "./NumberedPageSection"
import px from "../px"
import { silver3 } from "../colors"

let View: FunctionComponent = () => {
  return (
    <div>
      <p>A default page</p>
      <div style={{ marginBottom: px(4) }}>
        <NumberedPageSection number={1} disabled={false}>
          <div
            style={{
              width: "100%",
              marginBottom: px(3),
              backgroundColor: silver3,
              height: "32px",
            }}
          />
          <div
            style={{
              width: "100%",
              marginBottom: px(4),
              backgroundColor: silver3,
              height: "40px",
            }}
          />
          <div
            style={{ width: "100%", backgroundColor: silver3, height: "40px" }}
          />
        </NumberedPageSection>
      </div>
      <p>A disabled page</p>
      <div style={{ marginBottom: px(4) }}>
        <NumberedPageSection number={1} disabled={true}>
          <div
            style={{
              width: "100%",
              marginBottom: px(3),
              backgroundColor: silver3,
              height: "32px",
            }}
          />
          <div
            style={{
              width: "100%",
              marginBottom: px(4),
              backgroundColor: silver3,
              height: "40px",
            }}
          />
          <div
            style={{ width: "100%", backgroundColor: silver3, height: "40px" }}
          />
        </NumberedPageSection>
      </div>
    </div>
  )
}

export default View
