import React, { FunctionComponent, ComponentProps } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { Input } from "antd"

import px from "../px"

import { indexSearchHeight } from "../constants"
const noop = () => {}

type ViewProps = {} & ComponentProps<typeof Input>
const View: FunctionComponent<ViewProps> = (props) => {
  return (
    <Input
      style={{
        borderRadius: px(0.5),
        height: `${indexSearchHeight}`,
      }}
      placeholder={props.placeholder}
      suffix={<FontAwesomeIcon icon={faSearch} />}
      onChange={props.onChange || noop}
    />
  )
}

export default View
