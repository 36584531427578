import React from "react"
import { Table, Empty } from "antd"
import { TableProps } from "antd/es/table"

import { defaultConfig } from "./Pagination"

function View<T extends object = any>(props: TableProps<T>): JSX.Element {
  // By setting sortDirections in this way, we prevent the user from cancelling a sort
  return (
    <Table
      sortDirections={["ascend", "descend", "ascend"]}
      pagination={defaultConfig}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Data Unavailable"
          />
        ),
      }}
      {...props}
    />
  )
}

export default View
