import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
  FunctionComponent,
} from "react"
import Observable from "zen-observable"
import intersperse from "intersperse"
import { AxiosResponse } from "axios"
import { FetchResult } from "apollo-link"
import { Button } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { eventChannel } from "redux-saga"
import { takeEvery, take, put, call } from "redux-saga/effects"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faInfoCircle,
  faExternalLink,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { faRedo } from "@fortawesome/pro-regular-svg-icons"
import { Link } from "react-router-dom"
import gql from "graphql-tag"

import { Action as ReduxAction } from "redux"
import px from "../px"
import {
  blueBase,
  grayBase8,
  greenBase,
  redBase,
  silver3,
  whiteBase,
} from "../colors"
import PrimaryButton from "../components/PrimaryButton"
import { useSaga } from "core_ui/lib/hooks"
import { List, LIST_FRAGMENT } from "../pages/ListDetail"
import client from "../client"
import { uploadFile } from "../api"
import formatError from "core_ui/lib/formatError"

type UserListUpload = {
  id: number
  created_at: string
  source_file: {
    id: number
    filename: string
  }
  target_list_id: null | number
  target_list: null | List
  error: string
}

export const USER_LIST_UPLOAD_FRAGMENT = gql`
  fragment user_list_upload on user_list_uploads {
    id
    created_at
    target_list_id
    target_list {
      ...list
    }
    source_file {
      id
      filename
    }
    error
  }
  ${LIST_FRAGMENT}
`

export const UPLOAD_TRANSACTION_SUBSCRIPTION = gql`
  subscription UploadTransaction($transaction_id: Int!) {
    upload_transactions_by_pk(id: $transaction_id) {
      id
      error
      is_finished
      user_list_uploads {
        ...user_list_upload
      }
    }
  }
  ${USER_LIST_UPLOAD_FRAGMENT}
`

const uploadCardContainerStyles = {
  borderRadius: px(0.5),
  backgroundColor: whiteBase,
  display: "flex",
  flex: 1,
  flexDirection: "column" as const,
  minHeight: "415px",
}

const UpArrowSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 122.00001301233256 122.99999699835304"
    width="122.00001301233256"
    height="122.99999699835304"
  >
    <g transform="translate(0, 0)">
      <g transform="translate(0.000015666117915034828, -8.166664745782036e-7) rotate(0)">
        <path
          d="M88.75434,59.88564c0.38124,-0.92248 0.22874,-2.07557 -0.53374,-2.76743l-25.39063,-25.59875c-0.99123,-0.99935 -2.66868,-0.99935 -3.65991,0l-25.31438,25.52188c-0.45749,0.53811 -0.76248,1.22997 -0.76248,1.92183c-0.07625,1.38372 0.99123,2.45994 2.36369,2.53681c0.07625,0 0.07625,0 0.1525,0h15.24963v28.13556c-0.07625,1.38372 0.91498,2.53681 2.28744,2.61369c0.07625,0 0.1525,0 0.22874,0h15.24963c1.37247,0.07687 2.43994,-0.99935 2.51619,-2.38307c0,-0.07687 0,-0.07687 0,-0.15375v-28.21244h15.24963c1.06747,0.07687 2.0587,-0.61499 2.36369,-1.61434zM82.65449,23.75527c6.55734,3.84366 11.97096,9.30165 15.70712,15.91274c7.70106,13.52967 7.70106,30.13427 0,43.66394c-3.73616,6.53422 -9.22603,12.06908 -15.70712,15.83586c-13.41967,7.76419 -29.88927,7.76419 -43.30894,0c-6.48109,-3.76678 -11.97096,-9.30165 -15.70712,-15.83586c-7.70106,-13.52967 -7.70106,-30.13427 0,-43.66394c3.73616,-6.53422 9.22603,-12.06908 15.70712,-15.83586c13.41967,-7.76419 29.88927,-7.76419 43.30894,-0.07687zM113.76373,30.75073c-5.33737,-9.30165 -13.03843,-17.06583 -22.26446,-22.44695c-9.22603,-5.45799 -19.74827,-8.37917 -30.49926,-8.3023c-10.75099,-0.07687 -21.27323,2.84431 -30.49926,8.3023c-9.22603,5.38112 -16.92709,13.1453 -22.26446,22.44695c-5.41362,9.30165 -8.31105,19.91014 -8.2348,30.74925c-0.07625,10.83911 2.74493,21.4476 8.15855,30.74925c5.33737,9.30165 13.03843,17.06583 22.34071,22.44695c9.22603,5.45799 19.74827,8.37917 30.49926,8.3023c10.75099,0.07687 21.27323,-2.76743 30.49926,-8.22542c9.22603,-5.38112 16.92709,-13.1453 22.26446,-22.52383c5.41362,-9.30165 8.31105,-19.91014 8.2348,-30.74925c0.07625,-10.83911 -2.82118,-21.4476 -8.2348,-30.74925z"
          style={{
            strokeWidth: 0,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            fill: greenBase,
          }}
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <defs>
        <path
          id="path-160029823843413"
          d="M88.75434,59.88564c0.38124,-0.92248 0.22874,-2.07557 -0.53374,-2.76743l-25.39063,-25.59875c-0.99123,-0.99935 -2.66868,-0.99935 -3.65991,0l-25.31438,25.52188c-0.45749,0.53811 -0.76248,1.22997 -0.76248,1.92183c-0.07625,1.38372 0.99123,2.45994 2.36369,2.53681c0.07625,0 0.07625,0 0.1525,0h15.24963v28.13556c-0.07625,1.38372 0.91498,2.53681 2.28744,2.61369c0.07625,0 0.1525,0 0.22874,0h15.24963c1.37247,0.07687 2.43994,-0.99935 2.51619,-2.38307c0,-0.07687 0,-0.07687 0,-0.15375v-28.21244h15.24963c1.06747,0.07687 2.0587,-0.61499 2.36369,-1.61434zM82.65449,23.75527c6.55734,3.84366 11.97096,9.30165 15.70712,15.91274c7.70106,13.52967 7.70106,30.13427 0,43.66394c-3.73616,6.53422 -9.22603,12.06908 -15.70712,15.83586c-13.41967,7.76419 -29.88927,7.76419 -43.30894,0c-6.48109,-3.76678 -11.97096,-9.30165 -15.70712,-15.83586c-7.70106,-13.52967 -7.70106,-30.13427 0,-43.66394c3.73616,-6.53422 9.22603,-12.06908 15.70712,-15.83586c13.41967,-7.76419 29.88927,-7.76419 43.30894,-0.07687zM113.76373,30.75073c-5.33737,-9.30165 -13.03843,-17.06583 -22.26446,-22.44695c-9.22603,-5.45799 -19.74827,-8.37917 -30.49926,-8.3023c-10.75099,-0.07687 -21.27323,2.84431 -30.49926,8.3023c-9.22603,5.38112 -16.92709,13.1453 -22.26446,22.44695c-5.41362,9.30165 -8.31105,19.91014 -8.2348,30.74925c-0.07625,10.83911 2.74493,21.4476 8.15855,30.74925c5.33737,9.30165 13.03843,17.06583 22.34071,22.44695c9.22603,5.45799 19.74827,8.37917 30.49926,8.3023c10.75099,0.07687 21.27323,-2.76743 30.49926,-8.22542c9.22603,-5.38112 16.92709,-13.1453 22.26446,-22.52383c5.41362,-9.30165 8.31105,-19.91014 8.2348,-30.74925c0.07625,-10.83911 -2.82118,-21.4476 -8.2348,-30.74925z"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
    </g>
  </svg>
)

enum Action {
  FILE_UPLOAD = "FILE_UPLOAD",
  FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS",
  FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR",
  FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS",
  TRANSACTION_FINISHED = "TRANSACTION_FINISHED",
  SUBSCRIPTION_ENDED_PRIOR_TO_TRANSACTION_FINISH = "SUBSCRIPTION_ENDED_PRIOR_TO_TRANSACTION_FINISH",
}

interface ActionT extends ReduxAction {
  type: Action
  data?: any
}

type DragAndDropViewProps = ReturnType<typeof useController>
let DragAndDropView: FunctionComponent<DragAndDropViewProps> = (props) => {
  let { inputRef, handleFileUpload } = props
  return (
    <DragAndDropCard {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", marginBottom: px(2.5) }}>
          <div
            className="file-upload-svg-container"
            style={{ position: "absolute" }}
          >
            <UpArrowSvg />
          </div>
          <div style={{ opacity: 0 }}>
            <UpArrowSvg />
          </div>
        </div>
        <div
          style={{
            marginBottom: px(2),
            fontSize: "31px",
            lineHeight: "31px",
            fontWeight: 700,
          }}
        >
          Drag and drop target list(s) here
        </div>
        <div
          style={{ marginBottom: px(2.5), fontSize: px(2), color: grayBase8 }}
        >
          OR
        </div>
        <input
          multiple
          ref={inputRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let newFiles = Array.from(e.currentTarget.files || [])

            handleFileUpload(newFiles)

            // This hack allows you to select a file, remove the file, and
            // reselect it. Otherwise reselect has no effect
            // https://stackoverflow.com/a/57717189/1213041
            // @ts-ignore
            e.currentTarget.value = null
          }}
          type="file"
          style={{ display: "none" }}
        />
        <div style={{ marginBottom: px(2.5) }}>
          <PrimaryButton>BROWSE FILES</PrimaryButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: px(2),
            color: grayBase8,
          }}
        >
          <div>
            &bull; CSV files preferred. XLS and XLSX files also accepted.
          </div>
          <div>
            &bull; Files must contain an NPI column with "NPI" in the column
            header.
          </div>
          <div>&bull; Files must have at least 30 rows.</div>
        </div>
      </div>
    </DragAndDropCard>
  )
}

type DragAndDropCardProps = Pick<
  ReturnType<typeof useController>,
  "onFileDrop" | "onFileDrag" | "dragState" | "inputRef"
>
let DragAndDropCard: FunctionComponent<DragAndDropCardProps> = (props) => {
  let { inputRef, onFileDrop, onFileDrag, dragState } = props
  return (
    <div
      className={`upload-card drag-and-drop ${
        dragState === "dragover" ? "drag-over" : ""
      }`}
      style={{ ...uploadCardContainerStyles, cursor: "pointer" }}
      onDrop={onFileDrop}
      onDragOver={onFileDrag}
      onDragLeave={onFileDrag}
      onClick={() => inputRef.current?.click()}
    >
      {props.children}
    </div>
  )
}

const errorIcon = (
  <FontAwesomeIcon style={{ color: redBase }} icon={faExclamationCircle} />
)
const successIcon = (
  <FontAwesomeIcon style={{ color: greenBase }} icon={faCheckCircle} />
)

function renderRow({
  fileName,
  error,
  list,
  individualUploadComplete,
  uploadComplete,
}: {
  fileName: string
  error: null | string
  list: null | List
  individualUploadComplete: boolean
  uploadComplete: boolean
}) {
  let icon = <LoadingOutlined />
  let completeContent: null | JSX.Element = null
  if (list != null) {
    icon = successIcon
    completeContent = (
      <div style={{ display: "flex", width: "100%" }}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          style={{ paddingRight: px(3) }}
          to={`/lists/${list.id}/match_rates`}
        >
          VIEW MATCH RESULTS <FontAwesomeIcon icon={faExternalLink} />
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/lists/${list.id}/create_segments`}
        >
          CREATE SEGMENTS <FontAwesomeIcon icon={faExternalLink} />
        </Link>
      </div>
    )
  } else if (error != null || individualUploadComplete) {
    // Note: We are handling a corner case, where our individual upload is
    // finished, but no list was found and no error included
    icon = errorIcon
    let isExcelFile = /xlsx?$/i.test(fileName)
    error = error || "An internal error occurred, the list does not exist"
    if (isExcelFile) {
      completeContent = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              color: redBase,
              fontSize: px(1.75),
              lineHeight: px(2),
              marginBottom: px(0.5),
            }}
          >
            {error}
          </div>
          <div
            style={{
              fontSize: px(2),
              display: "flex",
              flexDirection: "column",
              margin: `${px(1)} 0px`,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FontAwesomeIcon
                style={{ color: grayBase8 }}
                icon={faInfoCircle}
              />
              <span
                style={{
                  fontSize: px(1.75),
                  marginLeft: px(1),
                  color: grayBase8,
                  fontWeight: 700,
                }}
              >
                Troubleshooting Tips:
              </span>
            </div>
            <ul
              style={{
                paddingLeft: px(3.5),
                marginBottom: "0px",
                fontSize: px(1.75),
              }}
            >
              <li>Check your file for empty cells</li>
              <li>Convert your file to a .csv</li>
            </ul>
          </div>
          <div style={{ fontSize: px(1.75), lineHeight: px(2) }}>
            If the problem persists{" "}
            <a href="mailto:support@healiolytics.com">contact Healiolytics</a>{" "}
            for assistance.
          </div>
        </div>
      )
    } else {
      completeContent = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              color: redBase,
              fontSize: px(1.75),
              lineHeight: px(2),
              marginBottom: px(0.5),
            }}
          >
            {error}
          </div>
          <div style={{ fontSize: px(1.75), lineHeight: px(2) }}>
            Please try again or if the problem persists{" "}
            <a href="mailto:support@healiolytics.com">contact Healiolytics</a>{" "}
            for assistance.
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div
        style={{
          lineHeight: px(2.5),
          fontSize: px(2),
          marginBottom: uploadComplete ? px(1) : "0px",
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div style={{ wordBreak: "break-all" }}>{fileName}</div>
        <div
          style={{
            paddingLeft: px(2),
            fontSize: px(2.5),
            marginLeft: "auto",
          }}
        >
          {icon}
        </div>
      </div>
      {uploadComplete ? completeContent : null}
    </>
  )
}

type UploadProgressViewProps = ReturnType<typeof useController>
let UploadProgressView: FunctionComponent<UploadProgressViewProps> = (
  props
) => {
  let {
    uploadComplete,
    fileTransactionMap,
    fileErrorMap,
    fileUploadSet,
    onUploadMoreLists,
  } = props
  return (
    <div>
      <div style={{ ...uploadCardContainerStyles, padding: "49px 60px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              marginBottom: px(2),
              fontSize: "31px",
              lineHeight: "31px",
              fontWeight: 700,
            }}
          >
            {uploadComplete ? "Your upload is complete" : "Upload in progress"}
          </div>
          {uploadComplete ? null : (
            <div style={{ textAlign: "center" }}>
              <div>Your upload will take a minute.</div>
              <div>
                If you are having difficulty, please{" "}
                <a href="mailto:support@healiolytics.com">
                  contact Healiolytics
                </a>{" "}
                for assistance.
              </div>
            </div>
          )}
          <Hr />
          {intersperse(
            Array.from(fileUploadSet).reduce(
              (elements: JSX.Element[], d: File) => {
                let fileName = d.name
                let error: null | string = null
                let list: null | List = null
                let individualUploadComplete = false
                if (fileTransactionMap.has(d)) {
                  let transaction = fileTransactionMap.get(
                    d
                  ) as UploadTransaction
                  let ulu: UserListUpload
                  for (ulu of transaction.user_list_uploads) {
                    error = ulu.error
                    fileName = ulu.source_file.filename
                    list = ulu.target_list
                    elements.push(
                      renderRow({
                        fileName,
                        error,
                        list,
                        individualUploadComplete: transaction.is_finished,
                        uploadComplete,
                      })
                    )
                  }
                } else if (fileErrorMap.has(d)) {
                  error = fileErrorMap.get(d) as string
                  elements.push(
                    renderRow({
                      fileName,
                      error,
                      list,
                      individualUploadComplete: true,
                      uploadComplete,
                    })
                  )
                } else {
                  elements.push(
                    renderRow({
                      fileName,
                      error,
                      list,
                      individualUploadComplete,
                      uploadComplete,
                    })
                  )
                }

                return elements
              },
              []
            ),
            <Hr />
          )}
        </div>
      </div>
      {uploadComplete ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={onUploadMoreLists}
            style={{
              padding: px(1),
              boxSizing: "content-box",
              display: "flex",
              alignItems: "center",
              color: blueBase,
              fontSize: px(2),
            }}
            type="link"
          >
            <FontAwesomeIcon icon={faRedo} />
            <div style={{ display: "inline-block", marginLeft: px(1) }}>
              UPLOAD MORE LISTS
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  )
}

const Hr = () => (
  <div
    style={{
      margin: `${px(3)} 0px`,
      marginBottom: px(3),
      width: "100%",
      height: "1px",
      backgroundColor: silver3,
    }}
  />
)

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  let { fileUploadSet } = props
  if (!fileUploadSet.size) {
    return <DragAndDropView {...props} />
  }

  return <UploadProgressView {...props} />
}

function createObserverChannel<T>(obs: Observable<FetchResult<T>>) {
  return eventChannel((emitter) => {
    let subscription = obs.subscribe({
      next(message: FetchResult<T>) {
        emitter({ type: "next", message })
      },
      error(message: FetchResult<T>) {
        emitter({ type: "error", message })
      },
      complete() {
        emitter({ type: "complete" })
      },
    })

    return () => subscription.unsubscribe()
  })
}

type FileUploadSuccessResp = AxiosResponse<{ transaction_id: number }>
type FileUploadErrorResp = AxiosResponse<{ error: string }>
function* fileProcessSaga(payload: { data: File }) {
  let file = payload.data

  let resp: AxiosResponse<unknown>
  try {
    resp = yield call(uploadFile, { file })
  } catch (error) {
    // Note: error in this case is only if the request could not be made, we
    // mask the error as it is unlikely to be helpful
    yield put({
      type: Action.FILE_UPLOAD_ERROR,
      data: {
        file,
        error: formatError(error),
      },
    })
    return
  }

  if (resp.status >= 400) {
    let error = `An unexpected error occurred during file upload of ${file.name}`
    if ((resp as FileUploadErrorResp).data?.error) {
      error = formatError(resp)
    }

    yield put({
      type: Action.FILE_UPLOAD_ERROR,
      data: {
        file,
        error,
      },
    })
    return
  }

  let { transaction_id } = (resp as FileUploadSuccessResp).data

  let uploadTransactionObserver = client.subscribe({
    query: UPLOAD_TRANSACTION_SUBSCRIPTION,
    variables: { transaction_id },
  })

  let uploadTransactionObserverChannel = createObserverChannel<
    UploadTransaction
  >(uploadTransactionObserver)

  while (true) {
    let event: {
      type: string
      message: FetchResult<{ upload_transactions_by_pk: UploadTransaction }>
    } = yield take(uploadTransactionObserverChannel)
    if (event.type === "next") {
      if (
        event.message.data?.upload_transactions_by_pk?.error ||
        event.message.data?.upload_transactions_by_pk?.is_finished
      ) {
        yield put({
          type: Action.TRANSACTION_FINISHED,
          data: {
            file,
            transaction: event.message.data?.upload_transactions_by_pk,
          },
        })
        return
      }
      continue
    } else if (event.type === "error" || event.type === "complete") {
      yield put({
        type: Action.SUBSCRIPTION_ENDED_PRIOR_TO_TRANSACTION_FINISH,
        data: {
          file,
          transactionId: transaction_id,
        },
      })
      return
    }
  }
}

type UploadTransaction = {
  is_finished: boolean
  error: string | null
  user_list_uploads: UserListUpload[]
}

let useController = () => {
  let inputRef = useRef<HTMLInputElement>(null)
  const [dragState, setDragState] = useState("drop")

  let [fileUploadSet, setFileUploadSet] = useState<Set<File>>(new Set())
  let [fileTransactionMap, setFileTransactionMap] = useState<
    Map<File, UploadTransaction>
  >(new Map())
  let [fileErrorMap, setFileErrorMap] = useState(new Map<File, string>())

  let handler = useCallback(
    (action: ActionT) => {
      switch (action.type) {
        case Action.TRANSACTION_FINISHED: {
          let clone = new Map(fileTransactionMap)
          clone.set(action.data.file, action.data.transaction)
          setFileTransactionMap(clone)
          break
        }
        case Action.SUBSCRIPTION_ENDED_PRIOR_TO_TRANSACTION_FINISH: {
          let clone = new Map(fileErrorMap)
          clone.set(
            action.data.file,
            "A failure occurred talking to the service"
          )
          setFileErrorMap(clone)
          break
        }
        case Action.FILE_UPLOAD_ERROR: {
          let clone = new Map(fileErrorMap)
          clone.set(action.data.file, action.data.error)
          setFileErrorMap(clone)
          break
        }
      }
    },
    [fileTransactionMap, fileErrorMap]
  )
  let saga = useCallback(function* () {
    yield takeEvery<any>(Action.FILE_UPLOAD, fileProcessSaga)
  }, [])
  let dispatch = useSaga<ActionT>(saga, handler)

  let handleFileUpload = useCallback(
    (newFiles: File[]) => {
      // TODO: Why is this type signature required?
      let newUploadSet: typeof fileUploadSet = new Set()

      let f: File
      for (f of newFiles) {
        newUploadSet.add(f)

        // Queue the file upload
        dispatch({ type: Action.FILE_UPLOAD, data: f })
      }

      setFileUploadSet(newUploadSet)
      // Reset all other state
      setFileTransactionMap(new Map())
      setFileErrorMap(new Map())
    },
    [dispatch, fileUploadSet]
  )

  const onFileDrop = useCallback(
    (e) => {
      // Prevent default behavior of opening the file in the current tab
      e.preventDefault()

      let newFiles = []
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === "file") {
            let file = e.dataTransfer.items[i].getAsFile()
            newFiles.push(file)
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        newFiles = Array.from(e.dataTransfer.files)
      }

      handleFileUpload(newFiles)
      setDragState(e.type)
    },
    [setDragState, handleFileUpload]
  )

  const onFileDrag = useCallback(
    (e) => {
      // Prevent default behavior of opening the file in the current tab
      e.preventDefault()
      setDragState(e.type)
    },
    [setDragState]
  )

  const uploadComplete = useMemo(
    () => fileUploadSet.size === fileTransactionMap.size + fileErrorMap.size,
    [fileUploadSet, fileTransactionMap, fileErrorMap]
  )

  const onUploadMoreLists = useCallback(() => {
    // Reset all state
    setFileUploadSet(new Set())
    setFileErrorMap(new Map())
    setFileTransactionMap(new Map())
  }, [setFileUploadSet, setFileTransactionMap, setFileErrorMap])

  return {
    fileUploadSet,
    fileErrorMap,
    fileTransactionMap,
    uploadComplete,
    inputRef,
    handleFileUpload,
    dragState,
    onFileDrop,
    onFileDrag,
    onUploadMoreLists,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
