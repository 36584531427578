import React, { FunctionComponent } from "react"

import AuthPage from "./AuthPage"
import AuthCard from "../components/AuthCard"
import ResetPasswordForm from "../components/ResetPasswordForm"

const View: FunctionComponent = () => (
  <AuthPage>
    <AuthCard title="Set Password">
      <ResetPasswordForm />
    </AuthCard>
  </AuthPage>
)

export default View
