import React, {
  useImperativeHandle,
  useState,
  MutableRefObject,
  forwardRef,
  ChangeEvent,
  useRef,
} from "react"

import { grayBase8, gray } from "../colors"
import px from "../px"

let noneditableStyle = {
  color: gray,
}
type ViewProps = {
  prefix: string
  suffix: string
  hasError: boolean
  onValueChange?: (value: string) => void
}
export type MaskedInputRef = {
  value: null | string
  setValue: (s: string) => void
}
let View = forwardRef<MaskedInputRef, ViewProps>((props, parentRef) => {
  let ref = useRef<HTMLInputElement>(null)
  let { prefix, suffix, hasError, onValueChange } = props
  let [placeholder, setPlaceholder] = useState(ref?.current?.value || "")

  // Users of this input can use a ref to directly edit the input elements
  // value efficiently (via setValue)
  useImperativeHandle(
    parentRef,
    () => {
      return {
        value: ref?.current?.value || "",
        setValue: (value: string) => {
          if (ref?.current != null) {
            ref.current.value = value
            setPlaceholder(value)
            onValueChange && onValueChange(value)
          }
        },
      }
    },
    [onValueChange, setPlaceholder, ref]
  )

  return (
    <div
      onClick={() => {
        ;(ref as MutableRefObject<HTMLInputElement>)?.current?.focus()
      }}
      className={`masked-input-container ${hasError ? "error" : ""}`}
      style={{
        borderRadius: px(0.5),
        padding: `${px(1)} ${px(2)}`,
        fontSize: px(2),
      }}
    >
      <div
        style={{
          overflowX: "hidden",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span style={noneditableStyle}>{prefix}</span>
        <div style={{ position: "relative" }}>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onValueChange && onValueChange(e.currentTarget.value)
              setPlaceholder(e.currentTarget.value)
            }}
            ref={ref}
            className="masked-input"
            style={{
              top: 0,
              bottom: 0,
              position: "absolute",
              color: grayBase8,
              borderWidth: "0px",
              outline: "0px",
              backgroundColor: "rgba(0,0,0,0)",
              padding: "0px",
              width: "100%",
              zIndex: 1,
            }}
          />
          <span
            style={{
              opacity: 0,
              display: "inline-block",
              whiteSpace: "pre" as const,
              padding:
                "0px 1px 0px 0px" /* This adds a little spacing which accounts for the fact that a focused input will have a bit larger width to show position indicator*/,
              minWidth:
                "1px" /* This 1px width ensures the blinking position indicator is visible when the input gets focus */,
            }}
          >
            {/* We achieve an input that shrinks/grows with its content, by
            using a span (something that naturally grows/shrinks based on its
            contents) to set the parent containers width and as a result the
            inputs width */}
            {placeholder}
          </span>
        </div>
        <span style={noneditableStyle}>{suffix}</span>
      </div>
    </div>
  )
})

export default View
