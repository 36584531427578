import { useMemo, useCallback } from "react";
import searcher from "../searcher";
import useDebouncedState from "./useDebouncedState";
// Accessor is a function which accepts a data element and returns a list of
// values to search
// const useAccessorSearch = <T>(accessor: (d: T) => string[], data: T[]): [T[], string, (e: ChangeEvent<HTMLInputElement>) => void] => {
var useAccessorSearch = function (accessor, data) {
    var _a = useDebouncedState("", 150), searchStr = _a[0], setSearchStr = _a[1];
    var searchFunc = useMemo(function () { return searcher(accessor, data); }, [accessor, data]);
    // onSearch is a function  we return to the callee to use in the onChange of
    // an Input which yields the searchStr we use for filtering data
    var onSearch = useCallback(function (e) {
        return setSearchStr(e.currentTarget.value || "");
    }, [setSearchStr]);
    var filteredData = useMemo(function () { return searchFunc(searchStr); }, [
        searchFunc,
        searchStr,
    ]);
    return [filteredData, searchStr, onSearch];
};
export default useAccessorSearch;
