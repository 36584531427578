import React, { ComponentProps, FunctionComponent } from "react"

import px from "../px"

import SecondaryButton from "./SecondaryButton"
import PrimaryButton from "./PrimaryButton"
import SegmentModal from "./SegmentModal"

type ViewProps = {
  onCreateAnotherSegment: () => void
  onViewPendingSegments: () => void
} & Omit<ComponentProps<typeof SegmentModal>, "title">
let View: FunctionComponent<ViewProps> = (props) => {
  let { onCreateAnotherSegment, onViewPendingSegments, ...restProps } = props
  return (
    <SegmentModal title="Success!" {...restProps}>
      <div style={{ textAlign: "center", fontSize: px(2) }}>
        Your segments have been created. We will get back to you regarding your
        campaign status by the end of the next business day.
      </div>
      <PrimaryButton onClick={onViewPendingSegments}>
        View pending segments
      </PrimaryButton>
      <SecondaryButton onClick={onCreateAnotherSegment}>
        Create another segment
      </SecondaryButton>
    </SegmentModal>
  )
}

export default View
