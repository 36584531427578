import jwtDecode from "jwt-decode";
export function subFromToken(token) {
    var decoded;
    try {
        decoded = jwtDecode(token);
    }
    catch (_a) {
        return null;
    }
    return decoded.sub;
}
export function tokenExpiredOrInvalid(token) {
    var decoded;
    try {
        /* Note:
           jwtDecode doesn't validate the token, which will happen on the backend,
           our test of validation is whether it can be parsed as a jwt token
        */
        decoded = jwtDecode(token);
    }
    catch (_a) {
        return true;
    }
    var now = Date.now() / 1000;
    return decoded == null || decoded.exp < now;
}
export function isAuthFailureStatusCode(error) {
    var _a;
    var status = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
    return status === 401 || status === 403;
}
export var logout = function () {
    logoutToUrl("/signin");
};
export var logoutToUrl = function (url) {
    localStorage.removeItem("token");
    if (!url.startsWith("/")) {
        url = "/" + url;
    }
    // Force a page reload to clearing the stores of data for a particular user
    window.location.href = url;
};
export var authExpiredEventName = "CORE_UI_AUTH_EXPIRED";
export function dispatchToAuthExpiredModal() {
    var event = new CustomEvent(authExpiredEventName);
    document.dispatchEvent(event);
}
