import React from "react"
import { Provider as ReduxProvider } from "react-redux"
import { Switch, Router, Route } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"

import history from "./history"
import { store } from "./redux/store"
import SegmentIndex from "./pages/SegmentIndex"
import SegmentDetail from "./pages/SegmentDetail"
import ListIndex from "./pages/ListIndex"
import ListDetail from "./pages/ListDetail"
import CampaignIndex from "./pages/CampaignIndex"
import CampaignDetail from "./pages/CampaignDetail"
import Home from "./pages/Home"
import Profile from "./pages/Profile"
import ComponentSandbox from "./pages/ComponentSandbox"
import SignInPage from "./pages/SignInPage"
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage"
import ResetPasswordPage from "./pages/ResetPasswordPage"
import SetPasswordPage from "./pages/SetPasswordPage"
import UploadPage from "./pages/UploadPage"
import { Layout, FullWidthLayout } from "./components/Layout"
import RequireAuth from "./components/RequireAuth"
import client from "./client"
import RootErrorBoundary from "./components/RootErrorBoundary"
import EmulationWrapper from "./components/EmulationWrapper"
import GoogleAnalytics from "./googleAnalytics"
import NotFoundPage from "./pages/NotFoundPage"
import InMaintenancePage from "./pages/InMaintenancePage"
import ScrollToTop from "./ScrollToTop"

const inMaintenance = process.env.REACT_APP_IN_MAINTENANCE === "1"

function App() {
  if (inMaintenance) {
    return (
      <ReduxProvider store={store}>
        <Router history={history}>
          <Layout inMaintenance={true}>
            <InMaintenancePage />
          </Layout>
        </Router>
      </ReduxProvider>
    )
  }
  return (
    <RootErrorBoundary>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <Router history={history}>
            <GoogleAnalytics />
            <ScrollToTop />
            <EmulationWrapper>
              <Switch>
                <Route exact path="/sandbox">
                  <ComponentSandbox />
                </Route>
                <Route exact path="/signin">
                  <FullWidthLayout>
                    <SignInPage />
                  </FullWidthLayout>
                </Route>
                <Route exact path="/request_password_reset">
                  <FullWidthLayout>
                    <RequestPasswordResetPage />
                  </FullWidthLayout>
                </Route>
                <Route exact path={["/reset_password/:reset_code","/reset_password/:reset_code/:appended_junk"]}>
                  <FullWidthLayout>
                    <ResetPasswordPage />
                  </FullWidthLayout>
                </Route>
                <Route exact path={["/set_password/:reset_code","/set_password/:reset_code/:appended_junk"]}>
                  <FullWidthLayout>
                    <SetPasswordPage />
                  </FullWidthLayout>
                </Route>
                <Route exact path="/">
                    <FullWidthLayout>
                      <Home />
                    </FullWidthLayout>
                </Route>
                <Route
                  path="/profile"
                  render={(props: { match: { url: string } }) => {
                    // See the note above for lists regarding keying by
                    // match url
                    return (
                      <RequireAuth>
                        <FullWidthLayout key={props.match.url}>
                          <Profile />
                        </FullWidthLayout>
                      </RequireAuth>
                    )
                  }}
                />
                <Route exact path="/upload">
                  <RequireAuth>
                    <FullWidthLayout>
                      <UploadPage />
                    </FullWidthLayout>
                  </RequireAuth>
                </Route>
                <Route exact path="/lists_send">
                  <RequireAuth>
                    <Layout>
                      <ListIndex />
                    </Layout>
                  </RequireAuth>
                </Route>
                <Route exact path="/lists_match">
                  <RequireAuth>
                    <Layout>
                      <ListIndex />
                    </Layout>
                  </RequireAuth>
                </Route>
                <Route
                  path="/lists/:id"
                  render={(props: { match: { url: string } }) => {
                    // We key by url here so that mount/unmount occurs when
                    // switching between lists, however changing between
                    // tabs at /match_lists and /create_segments will not
                    // trigger a re-render, see the long note below
                    return (
                      <RequireAuth>
                        <FullWidthLayout key={props.match.url}>
                          <ListDetail />
                        </FullWidthLayout>
                      </RequireAuth>
                    )
                  }}
                />
                <Route exact path="/segments">
                  <RequireAuth>
                    <Layout>
                      <SegmentIndex />
                    </Layout>
                  </RequireAuth>
                </Route>
                <Route
                  exact
                  path="/segments/:id"
                  render={(props: { location: { pathname: string } }) => {
                    /* Pour yourself a strong drink, and see the note at
                     * very bottom about paramatrized routes */
                    return (
                      <RequireAuth>
                        <FullWidthLayout key={props.location.pathname}>
                          <SegmentDetail />
                        </FullWidthLayout>
                      </RequireAuth>
                    )
                  }}
                />
                <Route exact path="/campaigns">
                  <RequireAuth>
                    <Layout>
                      <CampaignIndex />
                    </Layout>
                  </RequireAuth>
                </Route>
                <Route
                  path="/campaigns/:id"
                  render={(props: { match: { url: string } }) => {
                    // See the note above for lists regarding keying by
                    // match url
                    return (
                      <RequireAuth>
                        <FullWidthLayout key={props.match.url}>
                          <CampaignDetail />
                        </FullWidthLayout>
                      </RequireAuth>
                    )
                  }}
                />
                <Route path="*">
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </Route>
              </Switch>
            </EmulationWrapper>
          </Router>
        </ReduxProvider>
      </ApolloProvider>
    </RootErrorBoundary>
  )
}

/*
There is something a little strange happening in the paramatrized routes
below. We are setting they key attribute on components to the value of the url
(path). This key tells React about the identify of a component.

When react is comparing the previous tree and the new tree, if it sees that
the new component is different than the old component in the hierarchy. React
will unmount the old and mount the new. A newly mounted component will have
re-initialized state (useState will get state from its only argument). Now if
the same Component is being rendered and only the props are different, React
will reuse the old and provide updated props. A reused component remains mounted.

In paramatrized routes (/lists/:id), by default, the component will be
reusued, and remain mounted when the user switches between them. However, we
specify the key property on these components, which inform it that like
components with different keys are indeed different components.

Without setting the key, it became clear that we now had to reinitialize all
our setState calls when the id prop changed, and libraries like apollographql
have serious bugs, like returning the data for a previous query while its
fetching the new query. In general, having to reset state is error prone and
libraries goof it up too
(https://github.com/apollographql/react-apollo/issues/2202).  By setting the
key, we're actually doing what we intend which is to model routes like so:

<Switch>
  <Route path="/lists/1" component={Analysis}/>
  <Route path="/lists/2" component={Analysis}/>
  <Route path="/lists/3" component={Analysis}/>
  ...
</Switch>

This is something that react-router arguably should auto adopt, because the
current behavior is unexpected.
*/

export default App
