import React, { FunctionComponent } from "react"

import { View as NavView } from "./Nav"
import px from "../px"

export const dummyLocation = { pathname: "/", state: {}, search: "", hash: "" }

let View: FunctionComponent = () => {
  return (
    <div>
      <div style={{ marginBottom: px(5) }}>
        <p>Menu and login in nav</p>
        <NavView
          location={dummyLocation}
          showMenu={true}
          showLogin={true}
          user={{
            loading: false,
            data: {
              first_name: "Sam",
              last_name: "Doe",
              email: "samdoe@mail.com",
            },
          }}
        />
      </div>
      <div style={{ marginBottom: px(5) }}>
        <p>No menu, no login in nav</p>
        <NavView
          location={dummyLocation}
          showMenu={false}
          showLogin={false}
          user={{ loading: false, data: null }}
        />
      </div>
      <div style={{ marginBottom: px(5) }}>
        <p>No menu with login in nav</p>
        <NavView
          location={dummyLocation}
          showMenu={false}
          showLogin={true}
          user={{
            loading: false,
            data: {
              first_name: "Sam",
              last_name: "Doe",
              email: "samdoe@mail.com",
            },
          }}
        />
      </div>
    </div>
  )
}

export default View
