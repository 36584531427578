import React, { ComponentProps, FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

import sharedStyles from "../sharedStyles"
import px from "../px"
import { Modal } from "./Modal"

type ViewProps = {
  title: string
  onCancel?: () => void
} & ComponentProps<typeof Modal>
let View: FunctionComponent<ViewProps> = (props) => {
  let { title, visible, setVisible, onCancel } = props
  return (
    <Modal visible={visible} setVisible={setVisible}>
      <div
        style={{
          position: "relative",
          padding: `${px(1.5)} ${px(2)} ${px(1)} ${px(2)}`,
          fontSize: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
        {/* Capture a larger area than the icon for a click region */}
        <div
          onClick={() => {
            setVisible(false)
            if (onCancel != null) onCancel()
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: px(6),
          }}
        />
      </div>
      <div
        style={{
          margin: `0 auto ${px(3)} auto`,
          width: "250px",
          display: "grid",
          rowGap: px(1.5),
          /* This will allow us to have N rows where the row's height is
           * determined by its content */
          gridTemplateRows: "repeat(auto-fill, minmax(min-content, 0))",
        }}
      >
        <div style={sharedStyles.modalHeader}>{title}</div>
        {props.children}
      </div>
    </Modal>
  )
}

export default View
