import { useRef, useEffect, useCallback, useMemo } from "react";
import { runSaga, stdChannel } from "redux-saga";
function useSaga(saga, outputDispatch) {
    // redux-saga uses this pipe to take from and put to
    var channel = useMemo(function () { return stdChannel(); }, []);
    var inputDispatch = useCallback(function (action) {
        outputDispatch(action);
        channel.put(action);
        return action;
    }, [outputDispatch, channel]);
    // Create a mutable ref, so that our saga is not recreated when the handler changes
    var outputDispatchRef = useRef(null);
    outputDispatchRef.current = outputDispatch;
    var existingSagaTask = useRef(null);
    useEffect(function () {
        var subscribed = true;
        if (existingSagaTask.current != null) {
            existingSagaTask.current.cancel();
            existingSagaTask.current = null;
        }
        existingSagaTask.current = runSaga({
            channel: channel,
            dispatch: function (action) {
                if (!subscribed) {
                    return;
                }
                if (outputDispatchRef.current) {
                    outputDispatchRef.current(action);
                }
            },
            // this will be used to resolve select Effects
            getState: function () {
                throw new Error("select cannot be used with a saga run by useSaga");
            },
        }, saga);
        return function () {
            subscribed = false;
            channel.close();
        };
    }, [saga, outputDispatchRef, channel]);
    return inputDispatch;
}
export default useSaga;
