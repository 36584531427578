import React, { FunctionComponent } from "react"

import { Avatar, Row, Col } from "antd"

import { silverBase5, blackBase, whiteBase } from "../colors"
import { leftColumnOffset } from "../constants"

type ViewProps = {
  disabled: boolean
  number: number
}
const View: FunctionComponent<ViewProps> = (props) => {
  let { number, disabled } = props
  return (
    <Row style={{ position: "relative", opacity: disabled ? 0.5 : 1 }}>
      {/* This absolute positioning expects that there is a title which has a
      height of 32px. Our circle avatar is 42 px, in order to center we shift
      the avatar up half the difference (-5px). We shift (42px avatar width +
                                                          12px gap between
      avatar and title) 54px to the left */}
      <Col
        style={{
          position: "absolute",
          top: "-5px",
        }}
      >
        <Avatar
          size={42}
          style={{
            font: "Roboto Condensed",
            fontWeight: 700,
            border: `solid 1px ${silverBase5}`,
            color: blackBase,
            backgroundColor: whiteBase,
          }}
        >
          {number}
        </Avatar>
      </Col>
      <Col
        flex="auto"
        style={{
          marginLeft: `${leftColumnOffset}px`,
        }}
      >
        {props.children}
      </Col>
      {disabled ? (
        <div
          style={{
            position: "absolute",
            left: "-54px",
            right: 0,
            top: "-5px",
            bottom: 0,
          }}
        />
      ) : null}
    </Row>
  )
}

export default View
