import React, { ComponentProps, FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

import PrimaryButton from "./PrimaryButton"

import sharedStyles from "../sharedStyles"
import px from "../px"
import { Modal } from "./Modal"
import { wideModalWidth } from "../constants"

type ViewProps = {
  onCancel?: () => void
} & ComponentProps<typeof Modal>
let View: FunctionComponent<ViewProps> = (props) => {
  let { visible, setVisible, onCancel } = props
  return (
    <Modal
      modalWidth={wideModalWidth}
      visible={visible}
      setVisible={setVisible}
    >
      <div
        style={{
          position: "relative",
          padding: `${px(1.5)} ${px(2)} ${px(1)} ${px(2)}`,
          fontSize: "24px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
        {/* Capture a larger area than the icon for a click region */}
        <div
          onClick={() => {
            setVisible(false)
            if (onCancel != null) onCancel()
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: px(6),
          }}
        />
      </div>
      <div
        style={{
          margin: `0 ${px(3)} ${px(3)}`,
        }}
      >
        <div style={{ ...sharedStyles.modalHeader, marginBottom: px(2) }}>
          Glossary
        </div>
        <ul
          style={{
            marginBottom: px(5),
            fontSize: px(2),
            padding: `0 ${px(2)}`,
          }}
        >
          <li>
            <b>Total Count:</b> The number of NPIs on the target list
          </li>
          <li>
            <b>Healio:</b> The number of users on the target list that are also
            in Healio's database
          </li>
          <li>
            <b>Healio Match Rate:</b> Healio Matches/Total Count
          </li>
          <li>
            <b>Active Programmatic:</b> The number of unique users on the target
            list that Healio can match to at least one active DSP cookie, which
            can be targeted by a programmatic ad. It represents the maximize
            potential programmatic reach.
          </li>
          <li>
            <b>Active Programmatic Match Rate:</b> Active Programmatic/Total
            Count
          </li>
          <li>
            <b>Estimated Programmatic Impressions:</b> Represents an estimate of
            the number of impressions that could be delivered in a month
            targeting a given segment. Actual delivery will vary based on
            factors like frequency caps and site lists.
          </li>
        </ul>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton onClick={() => setVisible(false)}>
            GOT IT
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default View
