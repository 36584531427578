import React, { FunctionComponent } from "react"
import { Row, Col } from "antd"

import { Link } from "react-router-dom"
import { greenBase, whiteBase, grayBase8 } from "../colors"
import px from "../px"
import { WidthScrollbarContainer } from "../components/WidthContainer"
import useUser from "../hooks/useUser"
import CampaignsIcon from "../components/PldIcon"
import ListsIcon from "../components/ListsIcon"
import SegmentsIcon from "../components/SegmentsIcon"
import UploadIcon from "../components/UploadIcon"
import CheckIcon from "../components/CheckIcon"

let tileStyle = {
  alignItems: "center",
  flex: 1,
  padding: `${px(2)} 0px ${px(2.25)}`,
  display: "flex",
  flexDirection: "column" as const,
}

let tileTextStyle = {
  fontSize: px(2.5),
  lineHeight: 1,
}

const largeIconWrapperStyle = {
  width: px(10),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: px(1),
} as const

const View: FunctionComponent = () => {
  let user = useUser()
  return (
    <div
      className="home-page"
      style={{ height: "100%", backgroundColor: grayBase8 }}
    >
      <WidthScrollbarContainer>
        <Row justify="center">
          <Col span={12}>
            <div
              style={{
                marginTop: px(4),
                display: "flex",
                justifyContent: "center",
                fontSize: px(2),
                fontWeight: 700,
                marginBottom: px(4),
              }}
            >
              <div>
                <span style={{ color: greenBase }}>Hello{user.data?.first_name ? ", " : ""}</span>
                <span style={{ color: whiteBase }}>
                  {user.data?.first_name} {user.data?.last_name}
                </span>
              </div>
            </div>
            <div>
              <Link
                to="/upload"
                className="tile blue-tile top-tile"
                style={{
                  ...tileStyle,
                  marginBottom: px(1),
                  borderTopLeftRadius: px(1),
                  borderTopRightRadius: px(1),
                }}
              >
                <div style={largeIconWrapperStyle}>
                  <UploadIcon />
                </div>
                <div style={tileTextStyle}>Upload Target Lists</div>
              </Link>
              <div
                style={{
                  display: "flex",
                  marginBottom: px(1),
                }}
              >
                <Link
                  to="/lists_match"
                  className="tile green-tile upper-left-tile"
                  style={{ ...tileStyle, marginRight: px(1) }}
                >
                  <div style={largeIconWrapperStyle}>
                    <ListsIcon />
                  </div>
                  <div style={tileTextStyle}>Match Target List</div>
                </Link>
                <Link
                  to="/lists_send"
                  className="tile green-tile upper-right-tile"
                  style={tileStyle}
                >
                  <div style={largeIconWrapperStyle}>
                    <SegmentsIcon />
                  </div>
                  <div style={tileTextStyle}>Send Segments</div>
                </Link>
              </div>
              <div style={{ display: "flex" }}>
                <Link
                  to="/campaigns"
                  className="tile green-tile bottom-left-tile"
                  style={{
                    ...tileStyle,
                    marginRight: px(1),
                    borderBottomLeftRadius: px(1),
                  }}
                >
                  <div style={largeIconWrapperStyle}>
                    <CampaignsIcon />
                  </div>
                  <div style={tileTextStyle}>View PLD</div>
                </Link>
                <Link
                  to="/segments"
                  className="tile green-tile bottom-right-tile"
                  style={{ ...tileStyle, borderBottomRightRadius: px(1) }}
                >
                  <div style={largeIconWrapperStyle}>
                    <CheckIcon />
                  </div>
                  <div style={tileTextStyle}>Segment Status</div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </WidthScrollbarContainer>
    </div>
  )
}

export default View
