import moment from "moment";
// If > 1 day, show YYYY-MM-DD else a relative timestamp
export var formatDateWithFormats = function (dateish, dateFormat, useFromNow) {
    if (useFromNow === void 0) { useFromNow = true; }
    var m = moment(dateish);
    if (useFromNow && moment().subtract(1, "days") < m)
        return m.fromNow();
    return m.format(dateFormat !== null && dateFormat !== void 0 ? dateFormat : "YYYY-MM-DD");
};
export default (function (dateish) {
    return formatDateWithFormats(dateish);
});
