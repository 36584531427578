import React, { FunctionComponent } from "react"

let View: FunctionComponent = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-15.5 -15.5 82 82"
      enableBackground="new -15.5 -15.5 82 82"
    >
      <path
        d="M25.5-11.246c20.262,0,36.745,16.483,36.745,36.746c0,20.262-16.483,36.745-36.745,36.745
        c-20.262,0-36.746-16.483-36.746-36.745C-11.246,5.238,5.238-11.246,25.5-11.246 M25.5-14.5c-22.091,0-40,17.909-40,40
        c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40C65.5,3.409,47.591-14.5,25.5-14.5L25.5-14.5z M11.568,29.05l9.667-10.083
        v24.382c0,1.776,1.429,3.203,3.206,3.203h2.136c1.776,0,3.205-1.427,3.205-3.203V18.966L39.45,29.05
        c1.241,1.294,3.311,1.32,4.578,0.051l1.456-1.468c1.255-1.254,1.255-3.285,0-4.527L27.778,5.386c-1.253-1.254-3.284-1.254-4.523,0
        L5.518,23.106c-1.256,1.255-1.256,3.286,0,4.527l1.454,1.468C8.255,30.37,10.324,30.344,11.568,29.05z"
      />
    </svg>
  )
}

export default View
