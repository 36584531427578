import React, { FunctionComponent } from "react"

import AuthPage from "./AuthPage"
import AuthCard from "../components/AuthCard"
import SignInForm from "../components/SignInForm"

const View: FunctionComponent = () => (
  <AuthPage>
    <AuthCard title="Log In">
      <SignInForm />
    </AuthCard>
  </AuthPage>
)

export default View
