import { useMemo } from "react"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"

import { store } from "../redux/store"
import { getToken } from "../selectors"
import { subFromToken } from "core_ui/lib/auth"

const USER_FRAGMENT = gql`
  fragment user on users {
    id
    email
    first_name
    last_name
    receive_pld_delivered_notifications
    receive_segment_created_notifications
    receive_target_list_from_ftp_notifications
  }
`

const USER_SUBSCRIPTION = gql`
  subscription User($id: Int!) {
    users_by_pk(id: $id) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`

const USER_QUERY = gql`
  query User($id: Int!) {
    users_by_pk(id: $id) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`

export interface User {
  id: number
  email: string
  first_name: string | null
  last_name: string | null
  receive_pld_delivered_notifications: boolean
  receive_segment_created_notifications: boolean
  receive_target_list_from_ftp_notifications: boolean
}

export default (skip = false) => {
  let token = getToken(store.getState())
  let userId = null
  if (token != null) {
    userId = subFromToken(token)
  }
  const fetchOptions = {
    skip: skip || userId == null,
    variables: { id: userId },
  }
  const userSubscription = useSubscription(USER_SUBSCRIPTION, fetchOptions)
  const userQuery = useQuery(USER_QUERY, fetchOptions)

  let user = useMemo<User | null>(() => {
    let user = userSubscription.data?.users_by_pk || userQuery.data?.users_by_pk
    return user
  }, [userQuery, userSubscription])

  return { loading: userSubscription.loading && userQuery.loading, data: user }
}
