import React, { useMemo, FunctionComponent } from "react"
import { Row, Col } from "antd"
import { ColumnsType } from "antd/es/table"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"

import DiscreetLink from "../components/DiscreetLink"
import IndexTitle from "../components/IndexTitle"
import IndexSearch from "../components/IndexSearch"
import Table from "../components/Table"
import px from "../px"
import formatDate from "core_ui/lib/formatDate"
import { useAccessorSearch } from "core_ui/lib/hooks"
import { formatMatchRate } from "./ListDetail"
import { SEGMENT_FRAGMENT, Segment, getDspEta } from "./SegmentDetail"
import { indexSearchHeight, indexTitleHeight } from "../constants"
import sharedStyles from "../sharedStyles"
import { greenBase } from "../colors"

export const SEGMENT_QUERY = gql`
  query Segments {
    segments {
      ...segment
    }
  }
  ${SEGMENT_FRAGMENT}
`

export const SEGMENT_SUBSCRIPTION = gql`
  subscription Segments {
    segments {
      ...segment
    }
  }
  ${SEGMENT_FRAGMENT}
`

const columns: ColumnsType<Segment> = [
  {
    title: "Segment Name",
    dataIndex: "filename",
    sorter: (a: Segment, b: Segment) => a.filename.localeCompare(b.filename),
    render: (text, record, _index) => {
      return (
        <div
          style={{
            ...sharedStyles.tableElement,
            display: "block",
            lineHeight: "unset",
            ...(record && record.campaign == null
              ? {
                  padding: `${px(1.5)} ${px(2)} ${px(1.5)} ${px(1)}`,
                  borderLeft: `solid ${px(1)} ${greenBase}`,
                }
              : {}),
          }}
        >
          <DiscreetLink to={`/segments/${record.id}`}>{text}</DiscreetLink>
        </div>
      )
    },
  },
  {
    title: "Campaign Name",
    sorter: (a: Segment, b: Segment) => {
      let aCampaign = a.campaign?.name || ""
      let bCampaign = b.campaign?.name || ""
      return aCampaign.localeCompare(bCampaign)
    },
    render: (_text, record, _index) => {
      if (record && record.campaign == null) {
        return (
          <div
            style={{
              ...sharedStyles.tableElement,
              fontWeight: 700,
              color: greenBase,
            }}
          >
            PENDING
          </div>
        )
      } else if (record.campaign?.id != null) {
        return (
          <div style={sharedStyles.tableElement}>
            <DiscreetLink to={`/campaigns/${record.campaign.id as number}`}>
              {record.campaign?.name}
            </DiscreetLink>
          </div>
        )
      }
      return null
    },
  },
  {
    title: "Creation Date",
    dataIndex: "created_at",
    render: (_text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>{formatDate(_text)}</div>
      )
    },
    sorter: (a: Segment, b: Segment) => {
      if (a.campaign == null && b.campaign != null) {
        return 1
      } else if (b.campaign == null && a.campaign != null) {
        return -1
      }

      return +new Date(a.created_at) - +new Date(b.created_at)
    },
    defaultSortOrder: "descend",
  },
  {
    title: "Match Rate",
    render: (_text, record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {formatMatchRate(record)}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) => {
      let aRate = a.matched_npi_count / a.total_npi_count || 0
      let bRate = b.matched_npi_count / b.total_npi_count || 0
      return aRate - bRate
    },
  },
  {
    title: "DSP ETA",
    render: (_text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {getDspEta(_record.created_at)}
        </div>
      )
    },
    sorter: (a: Segment, b: Segment) =>
      getDspEta(a.created_at).localeCompare(getDspEta(b.created_at)),
  },
]

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  return (
    <div className="indexPage" style={sharedStyles.indexPageContainer}>
      <Row style={{ height: px(6), marginBottom: px(5) }}>
        <Col>
          <IndexTitle title="Segments" />
        </Col>
        <Col
          span={8}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginTop: `${(indexTitleHeight - indexSearchHeight) / 2}px`,
          }}
        >
          <IndexSearch
            onChange={props.onSearchChange}
            placeholder="Search Segments"
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {props.searchValue ? (
            <div
              style={{ ...sharedStyles.searchResultsFor, marginBottom: px(3) }}
            >
              {props.data.length
                ? `Results for "${props.searchValue}"`
                : `No results for "${props.searchValue}"`}
            </div>
          ) : null}
          <Table<Segment>
            loading={props.segmentLoading}
            columns={columns}
            dataSource={props.data}
            rowClassName={(record: Segment) =>
              record && record.campaign == null ? "segment-in-review" : ""
            }
          />
        </Col>
      </Row>
    </div>
  )
}

let useController = () => {
  let segmentQuery = useQuery(SEGMENT_QUERY)
  let segmentSubscription = useSubscription(SEGMENT_SUBSCRIPTION)
  let data = useMemo(
    () =>
      (segmentSubscription.data?.segments || segmentQuery.data?.segments || [])
        .slice()
        .reverse(),
    [segmentQuery, segmentSubscription]
  )
  let [searchedData, searchValue, onSearchChange] = useAccessorSearch<Segment>(
    (d: Segment) => [d.filename],
    data
  )
  let segmentLoading =
    !segmentQuery.data && segmentQuery.loading && segmentSubscription.loading

  return {
    onSearchChange,
    segmentLoading,
    searchValue,
    data: searchedData,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
