import React, { useState, useCallback } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faFileAlt } from "@fortawesome/pro-light-svg-icons"
import { faPaste } from "@fortawesome/pro-duotone-svg-icons"
import px from "../px"
import { blueBase, silverBase5, whiteBase, grayBase8 } from "../colors"
import { useMounted } from "core_ui/lib/hooks"

const exportIconStyles = {
  fontSize: px(2.75),
  secondaryColor: whiteBase,
}

const iconColumnStyle = {
  cursor: "not-allowed",
  padding: `0 ${px(1.5)}`,
  display: "flex",
  alignItems: "center",
  flexDirection: "column" as const,
  color: silverBase5,
  fontSize: px(1.5),
}

const iconActiveColumnStyle = {
  padding: `0 ${px(1.5)}`,
  display: "flex",
  alignItems: "center",
  flexDirection: "column" as const,
  cursor: "pointer",
  color: blueBase,
  fontSize: px(1.5),
}

type LazyPromise = () => Promise<any>
function useCallbackWithLoadState(callback: LazyPromise | null | undefined) {
  let isMounted = useMounted()
  let [loading, setLoading] = useState(false)
  let wrappedCallback = useCallback(() => {
    if (callback == null) {
      return
    }
    setLoading(true)
    ;(callback as LazyPromise)().finally(() => {
      if (isMounted) {
        setLoading(false)
      }
    })
  }, [callback, isMounted])
  return [loading, wrappedCallback] as const
}

type ViewProps = {
  onPDFClick?: LazyPromise
  onClipboardClick?: LazyPromise
  onCSVClick?: LazyPromise
}

const View = (props: ViewProps): JSX.Element => {
  let [pdfLoading, onPDFClick] = useCallbackWithLoadState(props.onPDFClick)
  let [clipboardLoading, onClipboardClick] = useCallbackWithLoadState(
    props.onClipboardClick
  )
  let [csvLoading, onCSVClick] = useCallbackWithLoadState(props.onCSVClick)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto Condensed",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: grayBase8,
          fontSize: px(1.75),
          lineHeight: px(1.75),
          marginBottom: px(1.5),
        }}
      >
        EXPORT THIS DATA
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          onClick={onPDFClick}
          style={
            props.onPDFClick && !pdfLoading
              ? iconActiveColumnStyle
              : iconColumnStyle
          }
        >
          <div>
            <FontAwesomeIcon style={exportIconStyles} icon={faFilePdf} />
          </div>
          <div>PDF</div>
        </div>
        <div
          onClick={onClipboardClick}
          style={
            props.onClipboardClick && !clipboardLoading
              ? iconActiveColumnStyle
              : iconColumnStyle
          }
        >
          <div>
            <FontAwesomeIcon style={exportIconStyles} icon={faPaste} />
          </div>
          <div>CLIPBOARD</div>
        </div>
        <div
          onClick={onCSVClick}
          style={
            props.onCSVClick && !csvLoading
              ? iconActiveColumnStyle
              : iconColumnStyle
          }
        >
          <div>
            <FontAwesomeIcon style={exportIconStyles} icon={faFileAlt} />
          </div>
          <div>CSV</div>
        </div>
      </div>
    </div>
  )
}

export default View
