export const desktopWidth = 1200
export const mobileWidth = 375
export const modalWidth = 350
export const wideModalWidth = 696
// This unit is a percentage of the viewport height
export const verticalOffset = 10
export const indexSearchHeight = 50
export const indexTitleHeight = 40
export const chartHeight = 370
export const leftColumnOffset = 54
