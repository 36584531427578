import React, { FunctionComponent } from "react"
import DetailAdditionalMetadataTable from "./DetailAdditionalMetadataTable"
import { silver3 } from "../colors"

const View: FunctionComponent = () => {
  return (
    <DetailAdditionalMetadataTable>
      <div style={{ height: "40px", backgroundColor: silver3 }} />
      <div style={{ height: "40px", backgroundColor: silver3 }} />
      <div style={{ height: "40px", backgroundColor: silver3 }} />
    </DetailAdditionalMetadataTable>
  )
}
export default View
