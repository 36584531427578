import { applyMiddleware, combineReducers, createStore } from "redux"
import createSagaMiddleware from "redux-saga"

import {
  reducer as rootReducer,
  initialState as rootInitialState,
  State as RootState,
} from "./root"
import saga from "./saga"

export type State = {
  root: RootState
}

const sagaMiddleWare = createSagaMiddleware()

const store = createStore(
  combineReducers({
    root: rootReducer,
  }),
  { root: rootInitialState },
  applyMiddleware(sagaMiddleWare)
)

// Inititate the saga
sagaMiddleWare.run(saga)

export { store }
