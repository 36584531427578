/*
   These colors (and their names) are from the healio style guide
  https://app.uxpin.com/design-system/8a5da26226b3df4d8349/colors
*/
export const whiteBase = "#FFFFFF"
export const blackBase = "#000000"
export const grayBase8 = "#373D3F"
export const silver1 = "#F8F8F8"
export const silver2 = "#EAEAEA"
export const silver3 = "#DCDCDC"
export const silverBase5 = "#C1C1C1"
export const redBase = "#C82A10"
export const greenBase = "#6CA00D"
export const blueBase = "#006FC6"
export const grayBase2 = "#a7afb2"
export const gray = "#6f7c80"
