import React, { ComponentProps, FunctionComponent } from "react"
import { Tabs } from "antd"

const View: FunctionComponent<ComponentProps<typeof Tabs>> = (props) => {
  return (
    <div className="detail-tabs">
      <Tabs {...props} />
    </div>
  )
}

export default View
