import { useState, useMemo } from "react";
import debounce from "lodash.debounce";
// This is identical to the useState hook, but it yields a special setState
// function which is debounced. This is useful when you want to skip a rapid
// series of setState calls. For example, a user is typing in a search box.
// You want to minimize the work done on each character press, and only really
// care about the value after they are done typing.
//
// In this call:
// [state, setState] = useDebouncedState({}, 200)
//
// A rapid burst of setState calls followed by 200ms of silence will trigger a single state update
export default (function (initial, wait) {
    var _a = useState(initial), state = _a[0], setState = _a[1];
    var debouncedSetState = useMemo(function () { return debounce(setState, wait); }, [
        wait,
        setState,
    ]);
    return [state, debouncedSetState];
});
