import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export default () => {
  let history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      // @ts-ignore
      window.ga && window.ga("send", "pageview")
    })
    return () => {
      unlisten()
    }
  }, [history])

  return null
}
