import React, { FunctionComponent } from "react"
import { Row, Col } from "antd"

import UploadCard from "../components/UploadCard"

import px from "../px"
import { silver1 } from "../colors"

import { WidthScrollbarContainer } from "../components/WidthContainer"

let Page: FunctionComponent = () => {
  return (
    <div style={{ flex: 1, backgroundColor: silver1 }}>
      <WidthScrollbarContainer>
        <Row justify="center">
          <Col span={12}>
            <div style={{ margin: `${px(10)} 0px` }}>
              <UploadCard />
            </div>
          </Col>
        </Row>
      </WidthScrollbarContainer>
    </div>
  )
}

export default Page
