import React from "react"
import { Button } from "antd"
import { ButtonProps } from "antd/es/button"

import sharedStyles from "../sharedStyles"
import { blueBase, whiteBase } from "../colors"

const View = (props: ButtonProps): JSX.Element => {
  let { style, ...restProps } = props
  return (
    <Button
      {...restProps}
      style={{
        ...sharedStyles.button,
        color: blueBase,
        backgroundColor: whiteBase,
        ...style,
      }}
    />
  )
}

export default View
