import React, { useMemo, FunctionComponent } from "react"
import { Row, Col, Space } from "antd"
import { ColumnsType } from "antd/es/table"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"
import { useLocation, Link } from "react-router-dom"

import DiscreetLink from "../components/DiscreetLink"
import IndexTitle from "../components/IndexTitle"
import IndexSearch from "../components/IndexSearch"
import Table from "../components/Table"
import px from "../px"
import formatDate from "core_ui/lib/formatDate"
import { useAccessorSearch } from "core_ui/lib/hooks"
import { LIST_FRAGMENT, List, formatMatchRate } from "./ListDetail"
import { indexSearchHeight, indexTitleHeight } from "../constants"
import sharedStyles from "../sharedStyles"
import { greenBase } from "../colors"

export const LIST_QUERY = gql`
  query TargetLists {
    target_lists {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`

export const LIST_SUBSCRIPTION = gql`
  subscription TargetLists {
    target_lists {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  return (
    <div className="indexPage" style={sharedStyles.indexPageContainer}>
      <Row style={{ height: px(6), marginBottom: px(5) }}>
        <Col>
          <IndexTitle
            title={
              props.isListsMatch
                ? "Select Target List to Match"
                : "Select Target List to Send"
            }
          />
        </Col>
        <Col
          span={8}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginTop: `${(indexTitleHeight - indexSearchHeight) / 2}px`,
          }}
        >
          <IndexSearch
            onChange={props.onSearchChange}
            placeholder="Search Lists"
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {props.searchValue ? (
            <div
              style={{ ...sharedStyles.searchResultsFor, marginBottom: px(3) }}
            >
              {props.data.length
                ? `Results for "${props.searchValue}"`
                : `No results for "${props.searchValue}"`}
            </div>
          ) : null}
          <Table<List>
            loading={props.listLoading}
            columns={props.columns}
            dataSource={props.data}
          />
        </Col>
      </Row>
    </div>
  )
}

let useController = () => {
  let listQuery = useQuery(LIST_QUERY)
  let listSubscription = useSubscription(LIST_SUBSCRIPTION)
  let data = useMemo(
    () =>
      (
        listSubscription.data?.target_lists ||
        listQuery.data?.target_lists ||
        []
      )
        .slice()
        .reverse(),
    [listQuery, listSubscription]
  )
  let [searchedData, searchValue, onSearchChange] = useAccessorSearch<List>(
    (d: List) => [d.filename],
    data
  )
  let listLoading =
    !listQuery.data && listQuery.loading && listSubscription.loading
  let location = useLocation()
  let isListsMatch = location.pathname === "/lists_match"

  const columns: ColumnsType<List> = useMemo(
    () => [
      {
        title: "File Name",
        dataIndex: "filename",
        sorter: (a: List, b: List) => {
          return a.healio_target_list 
          ? -Number.MAX_SAFE_INTEGER
          : a.filename.localeCompare(b.filename)
        },
        render: (text, record, _index) => {
          return (
            <div style={{
              ...sharedStyles.tableElement,
              display:"block",
              lineHeight: "unset",
              ...(record && record.healio_target_list 
              ? {
                marginTop: "-12px",
                marginLeft: "-16px",
                marginBottom: "-12px",
                paddingTop: "12px",
                paddingLeft: "8px",
                paddingBottom:"12px",
                height: "75px",
                borderLeft: `solid ${px(1)} ${greenBase}`
              }
              :{})
            }}>
                <DiscreetLink
                  to={`/lists/${record.id}/${
                    isListsMatch ? "match_rates" : "create_segments"
                  }`}
                >
                  {text}
                </DiscreetLink>
            </div>
          )
        },
      },
      {
        title: "Uploaded",
        dataIndex: "created_at",
        render: (text, _record, _index) => {
          return (
            <div style={sharedStyles.tableElementNoWrap}>
              {formatDate(text)}
            </div>
          )
        },
        sorter: (a: List, b: List) =>
          a.healio_target_list 
          ? +new Date(8640000000000000)
          : +new Date(a.created_at) - +new Date(b.created_at),
          defaultSortOrder: "descend", 
      },
      {
        title: "Overall Match Rate",
        render: (_text, record, _index) => {
          return (
            <div style={sharedStyles.tableElementNoWrap}>
              {formatMatchRate(record)}
            </div>
          )
        },
        sorter: (a: List, b: List) => {
          let aRate = a.matched_npi_count / a.total_npi_count || 0
          let bRate = b.matched_npi_count / b.total_npi_count || 0
          return a.healio_target_list 
          ? -Number.MAX_SAFE_INTEGER
          : aRate - bRate
        },
      },
      {
        title: "Total NPIs",
        dataIndex: "total_npi_count",
      },
      {
        title: "NPIs Matched",
        dataIndex: "matched_npi_count",
      },
      {
        title: "Action",
        render: (_text, record, _index) => {
          let linkStyle = {
            textTransform: "uppercase" as const,
            fontSize: "14px",
            lineHeight: "16px",
            whiteSpace: "nowrap" as const,
          }
          return (
            <div style={sharedStyles.tableElementNoWrap}>
              {
                <Space direction="vertical">
                  <Link
                    style={linkStyle}
                    to={`/lists/${record.id}/match_rates`}
                  >
                    View match rates
                  </Link>
                  <Link
                    style={linkStyle}
                    to={`/lists/${record.id}/create_segments`}
                  >
                    Create segments
                  </Link>
                </Space>
              }
            </div>
          )
        },
      },
    ],
    [isListsMatch]
  )

  if (searchValue !== ""){
    searchedData = searchedData.filter((d: { healio_target_list: boolean }) => d.healio_target_list === false)
  }
  return {
    isListsMatch,
    onSearchChange,
    listLoading,
    searchValue,
    data: searchedData,
    columns,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
