import React from "react"
import styles from "./Ellipsis.module.css"

const Ellipsis = ({ className = "", style = {} }) => (
  <span className={`${styles.ellipsis} ${className}`} style={style}>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </span>
)

export default Ellipsis
