import React, { FunctionComponent } from "react"
import { Spin } from "antd"

const PageLoader: FunctionComponent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <Spin size="large" />
  </div>
)

export default PageLoader
