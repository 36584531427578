import React, { createRef, useEffect, FunctionComponent } from "react"

import MaskedInput, { MaskedInputRef } from "./MaskedInput"
import px from "../px"

let View: FunctionComponent = () => {
  let input1Ref = createRef<MaskedInputRef>()
  let input2Ref = createRef<MaskedInputRef>()
  let input3Ref = createRef<MaskedInputRef>()
  useEffect(() => {
    if (input1Ref.current != null) {
      input1Ref.current.setValue("Entire_List")
    }
    if (input2Ref.current != null) {
      input2Ref.current.setValue(
        "Entire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_ListEntire_List"
      )
    }
    if (input3Ref.current != null) {
      input3Ref.current.setValue("Entire_List")
    }
  }, [input1Ref, input2Ref, input3Ref])

  return (
    <div style={{ width: "300px" }}>
      <div style={{ marginBottom: px(5) }}>
        <p>Default state</p>
        <MaskedInput
          ref={input1Ref}
          prefix="Advertiser__Brand_"
          suffix="_Date.csv"
          hasError={false}
        />
      </div>
      <div style={{ marginBottom: px(5) }}>
        <p>Very long input</p>
        <MaskedInput
          ref={input2Ref}
          prefix="Advertiser__Brand_"
          suffix="_Date.csv"
          hasError={false}
        />
      </div>
      <div style={{ marginBottom: px(5) }}>
        <p>Error state</p>
        <MaskedInput
          ref={input3Ref}
          prefix="Advertiser__Brand_"
          suffix="_Date.csv"
          hasError={true}
        />
      </div>
    </div>
  )
}

export default View
