import React, { FunctionComponent } from "react"

import IndexTitle from "./IndexTitle"

let View: FunctionComponent = () => {
  return (
    <div>
      <IndexTitle title="Segments" />
    </div>
  )
}

export default View
