import React, {
  FunctionComponent,
  FormEvent,
  useState,
  useCallback,
} from "react"
import { message, Input } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import * as Yup from "yup"

import PrimaryButton from "./PrimaryButton"
import { useField } from "core_ui/lib/hooks"
import { updatePassword, catchCriticalError } from "core_ui/lib/api"
import formatError from "core_ui/lib/formatError"

import { redBase } from "../colors"
import sharedStyles from "../sharedStyles"
import px from "../px"
import { User } from "../hooks/useUser"
import { Modal } from "./Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const minPasswordLength = 6
let passwordSchema = Yup.string()
  .trim()
  .min(
    minPasswordLength,
    `The password must be at least ${minPasswordLength} characters length`
  )
  .required("Enter your password")

type ViewProps = ReturnType<typeof useController>
export const View: FunctionComponent<ViewProps> = (props) => {
  let {
    loading,
    passwordChanged,
    onSubmit,
    currentPassword,
    newPassword,
    confirmPassword,
    setPasswordChanged,
  } = props

  const submitButtonProps = {
    loading,
    disabled: loading,
    htmlType: "submit" as const,
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div style={{ marginBottom: px(3) }}>
          <label htmlFor="password-current">
            Current Password<span style={{ color: redBase }}>*</span>
          </label>
          <Input.Password
            id="password-current"
            value={currentPassword.value}
            onBlur={currentPassword.onBlur}
            onChange={currentPassword.onChange}
            placeholder=""
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <div style={sharedStyles.fieldError}>
            {currentPassword.touched && currentPassword.error}
          </div>
        </div>

        <div style={{ marginBottom: px(3) }}>
          <label htmlFor="password-new">
            New Password<span style={{ color: redBase }}>*</span>
          </label>
          <Input.Password
            id="password-new"
            value={newPassword.value}
            onBlur={newPassword.onBlur}
            onChange={newPassword.onChange}
            placeholder=""
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <div style={sharedStyles.fieldError}>
            {newPassword.touched && newPassword.error}
          </div>
        </div>

        <div style={{ marginBottom: px(6) }}>
          <label htmlFor="password-confirm">
            Confirm Password<span style={{ color: redBase }}>*</span>
          </label>
          <Input.Password
            id="password-confirm"
            value={confirmPassword.value}
            onBlur={confirmPassword.onBlur}
            onChange={confirmPassword.onChange}
            placeholder=""
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <div style={sharedStyles.fieldError}>
            {confirmPassword.touched && confirmPassword.error}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "left",
          }}
        >
          <PrimaryButton {...submitButtonProps}>Change Password</PrimaryButton>
        </div>
      </form>
      <Modal visible={passwordChanged} setVisible={setPasswordChanged}>
        <div
          style={{
            position: "relative",
            padding: `${px(1.5)} ${px(2)} ${px(1)} ${px(2)}`,
            fontSize: "24px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
          {/* Capture a larger area than the icon for a click region */}
          <div
            onClick={() => {
              setPasswordChanged(false)
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              width: px(6),
            }}
          />
        </div>
        <div
          style={{
            margin: `0 auto ${px(3)} auto`,
            width: "250px",
            display: "grid",
            rowGap: px(1.5),
            /* This will allow us to have N rows where the row's height is
             * determined by its content */
            gridTemplateRows: "repeat(auto-fill, minmax(min-content, 0))",
          }}
        >
          <div style={sharedStyles.modalHeader}>Success!</div>
          <div style={{ textAlign: "center", fontSize: px(2) }}>
            Your password was successfully changed.
          </div>
          <PrimaryButton
            type="primary"
            onClick={() => {
              setPasswordChanged(false)
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </Modal>
    </>
  )
}

const Component: FunctionComponent<ComponentPropsT> = (props) => {
  let viewProps = useController(props)
  return <View {...viewProps} />
}

type ComponentPropsT = {
  user: User
}

const useController = (props: ComponentPropsT) => {
  const { user } = props
  const [loading, setLoading] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)

  let currentPassword = useField({ initial: "", schema: passwordSchema })
  let newPassword = useField({ initial: "", schema: passwordSchema })
  let confirmPassword = useField({
    initial: "",
    schema: passwordSchema.oneOf(
      [newPassword.value],
      "The passwords do not match"
    ),
  })

  let userEmail = user.email
  const onSubmit = useCallback(
    (e: FormEvent) => {
      // Prevent default action of a form submit
      e.preventDefault()

      // Mark all fields as touched
      currentPassword.setTouched(true)
      newPassword.setTouched(true)
      confirmPassword.setTouched(true)

      if (currentPassword.error || newPassword.error || confirmPassword.error) {
        return
      }

      setLoading(true)
      updatePassword({
        email: userEmail,
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      })
        .then(() => {
          setPasswordChanged(true)

          // Reset fields
          currentPassword.setValue(undefined)
          newPassword.setValue(undefined)
          confirmPassword.setValue(undefined)
          currentPassword.setTouched(false)
          newPassword.setTouched(false)
          confirmPassword.setTouched(false)
        })
        .catch(catchCriticalError)
        .catch((error) => {
          const formattedError = formatError(error)
          if (formattedError.toLowerCase() === "password is incorrect") {
            currentPassword.setError(formattedError)
          }
          message.error(formattedError)
        })
        .finally(() => setLoading(false))
    },
    [userEmail, currentPassword, newPassword, confirmPassword]
  )

  return {
    loading,
    passwordChanged,
    onSubmit,
    currentPassword,
    newPassword,
    confirmPassword,
    setPasswordChanged,
  }
}

export default Component
