var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
export var FLASK_URL = process.env.REACT_APP_API_ENDPOINT;
export var signIn = function (_a) {
    var email = _a.email, password = _a.password;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post(FLASK_URL + "/signin/", { email: email, password: password })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp];
            }
        });
    });
};
export var requestPasswordReset = function (_a) {
    var email = _a.email;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post(FLASK_URL + "/request_password_reset/", {
                        email: email,
                    })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp];
            }
        });
    });
};
export var resetPassword = function (_a) {
    var password = _a.password, reset_code = _a.reset_code;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post(FLASK_URL + "/reset_password/", {
                        password: password,
                        reset_code: reset_code,
                    })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp];
            }
        });
    });
};
export var updatePassword = function (_a) {
    var email = _a.email, currentPassword = _a.currentPassword, newPassword = _a.newPassword;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post(FLASK_URL + "/update_password/", {
                        email: email,
                        currentPassword: currentPassword,
                        newPassword: newPassword,
                    })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp];
            }
        });
    });
};
export var requestUserAccountCreation = function (_a) {
    var email = _a.email, first_name = _a.first_name, last_name = _a.last_name;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, axios.post(FLASK_URL + "/create_user_account", { email: email, first_name: first_name, last_name: last_name }, { headers: { Authorization: "Bearer " + token } })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp];
            }
        });
    });
};
// This is promise error handler which should be used with every request, it
// handles critical errors, or rethrows less critical errors for further
// catches to handle
export var catchCriticalError = function (error) {
    var _a;
    var status = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
    // If axios has no response (connection error or like) or the response is
    // a server error, send the error to our error boundary to display a
    // modal/forward to sentry
    if (status == null || status >= 500) {
        dispatchToRootErrorBoundary(error);
        return;
    }
    // Rethrow error to be caught by other error handlers
    throw error;
};
export var matchTargetList = function (getToken) { return function (column, targetListID) { return __awaiter(void 0, void 0, void 0, function () {
    var token, resp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = getToken();
                if (token == null) {
                    throw new Error("Auth token is required");
                }
                return [4 /*yield*/, axios.post(FLASK_URL + "/match_target_list/" + targetListID, {
                        column: column,
                    }, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    })];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp];
        }
    });
}); }; };
export var errorBoundaryEventName = "CORE_UI_ERROR";
export function dispatchToRootErrorBoundary(error) {
    var errorEvent = new CustomEvent(errorBoundaryEventName, { detail: error });
    document.dispatchEvent(errorEvent);
}
