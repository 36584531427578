import React, { FunctionComponent } from "react"
import { greenBase } from "../colors"

let View: FunctionComponent<{
  label: string
  text: string
}> = (props) => {
  return (
    <div>
      <h1
        style={{
          fontSize: "15px",
          color: greenBase,
          fontFamily: "Roboto Condensed",
          fontWeight: "bold",
        }}
      >
        {props.label}
      </h1>
      <p style={{ fontSize: "22px", fontFamily: "Roboto Condensed" }}>
        {props.text}
      </p>
    </div>
  )
}

export default View
