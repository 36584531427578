import axios, { AxiosError } from "axios"

import { dispatchToAuthExpiredModal } from "core_ui/lib/auth"

import * as Core from "core_ui/lib/api"
import * as Selectors from "./selectors"
import {
  isAuthFailureStatusCode,
  tokenExpiredOrInvalid,
} from "core_ui/lib/auth"
import { store } from "./redux/store"
export {
  signIn,
  requestUserAccountCreation,
  catchCriticalError,
} from "core_ui/lib/api"

export const catchTokenExpired = (error: AxiosError) => {
  let token = getToken()

  if (
    !token ||
    isAuthFailureStatusCode(error) ||
    tokenExpiredOrInvalid(token)
  ) {
    dispatchToAuthExpiredModal()
  } else {
    throw error
  }
}

const getToken = () => Selectors.getToken(store.getState())

export const matchTargetList = Core.matchTargetList(getToken)

export const createSegments = async (
  segments_request: any,
  targetListID: number
) => {
  let token = getToken()
  if (token == null) {
    throw new Error("Auth token is required")
  }
  let resp = await axios.post(
    `${Core.FLASK_URL}/create_segments/${targetListID}`,
    segments_request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return resp
}

type UploadFilePayload = { file: File }
export const uploadFile = async ({ file }: UploadFilePayload) => {
  let token = getToken()
  if (token == null) {
    throw new Error("Auth token is required")
  }
  let encodedFileName = encodeURIComponent(file.name)
  let resp = await axios.post(
    `${Core.FLASK_URL}/upload_file/${encodedFileName}`,
    file,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return resp
}

type EmulatePayload = { email: string }
export const emulate = async ({ email }: EmulatePayload) => {
  // This is the only api call where we want to make the call as the emulator
  // not the emulatee, thus we check first for an existing emulatorToken
  let emulatorToken = localStorage.getItem("emulatorToken")
  let token = emulatorToken || getToken()
  if (token == null) {
    throw new Error("Auth token is required")
  }
  let resp = await axios.post(
    `${Core.FLASK_URL}/emulate/${encodeURIComponent(email)}`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return resp
}
