import React, { FunctionComponent } from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom"
import { Row, Col, Tabs } from "antd"

import IndexTitle from "../components/IndexTitle"
import px from "../px"
import DetailTabs from "../components/DetailTabs"
import sharedStyles from "../sharedStyles"
import { blueBase, grayBase8 } from "../colors"
import ChangePasswordForm from "../components/ChangePasswordForm"
import EmailNotificationsForm from "../components/EmailNotificationsForm"
import { WidthScrollbarContainer } from "../components/WidthContainer"
import useUser from "../hooks/useUser"

const { TabPane } = Tabs

const tabHeaderStyle = {
  fontSize: px(3.25),
  lineHeight: px(3.25),
  fontWeight: 700,
  marginBottom: px(4),
}

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  let { user, path, url, onTabChange, activeKey } = props

  if (user.data == null) {
    return null
  }

  return (
    <div
      style={{
        marginTop: px(6),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <WidthScrollbarContainer>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Row style={{ height: px(6), marginBottom: px(4) }}>
            <Col>
              <IndexTitle title="Account" />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: px(4) }}>
            <Col>
              <h1
                style={{
                  fontWeight: "bold",
                  fontFamily: "Roboto Condensed",
                  margin: 0,
                  fontSize: px(3.25),
                }}
              >
                {user.data?.first_name} {user.data?.last_name} (
                {user.data?.email})
              </h1>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <DetailTabs onChange={onTabChange} activeKey={activeKey}>
                <TabPane tab="Support" key={`/profile/support`} />
                <TabPane
                  tab="Email Notifications"
                  key={`/profile/email_notifications`}
                />
                <TabPane
                  tab="Change Password"
                  key={`/profile/change_password`}
                />
              </DetailTabs>
            </Col>
          </Row>
        </div>
      </WidthScrollbarContainer>
      <div style={sharedStyles.detailTabContainer}>
        <WidthScrollbarContainer>
          <Switch>
            <Route exact path={`${path}/support`}>
              <div style={{ fontFamily: "Roboto Condensed" }}>
                <Row style={tabHeaderStyle}>Support</Row>
                <Row
                  style={{
                    color: grayBase8,
                    fontSize: px(2),
                  }}
                >
                  Have questions? Send us an email and a Healiolytics
                  representative will reply as soon as possible.
                </Row>
                <Row
                  style={{
                    color: grayBase8,
                    fontSize: px(2),
                    marginTop: px(3.25),
                  }}
                >
                  Contact Healiolytics:{" "}
                  <a
                    href="mailto:support@healiolytics.com"
                    style={{ marginLeft: px(1.5), color: blueBase }}
                  >
                    support@Healiolytics.com
                  </a>
                </Row>
              </div>
            </Route>
            <Route exact path={`${path}/email_notifications`}>
              <Row style={tabHeaderStyle}>Email Notifications</Row>
              <Row
                style={{
                  width: "456px",
                }}
              >
                <EmailNotificationsForm user={user.data} />
              </Row>
            </Route>
            <Route exact path={`${path}/change_password`}>
              <Row style={tabHeaderStyle}>Change Password</Row>
              <Row>
                <Col span={8}>
                  <ChangePasswordForm user={user.data} />
                </Col>
              </Row>
            </Route>
            <Route>
              <Redirect to={`${url}/support`} />
            </Route>
          </Switch>
        </WidthScrollbarContainer>
      </div>
    </div>
  )
}

let useController = () => {
  let { path, url } = useRouteMatch()
  let user = useUser()
  let location = useLocation()
  let history = useHistory()

  return {
    onTabChange: history.push,
    activeKey: location.pathname,
    url,
    path,
    user,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
