import React from "react"

import { authExpiredEventName, logoutToUrl } from "core_ui/lib/auth"
import px from "../px"
import PrimaryButton from "./PrimaryButton"
import { Modal } from "./Modal"

// It handles auth expiration errors, prompting a user to signin again. It is
// triggered by a dispatch function
type ComponentProps = {}
export default class extends React.Component<
  ComponentProps,
  { visible: boolean }
> {
  constructor(props: ComponentProps) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  componentDidMount() {
    document.addEventListener(authExpiredEventName, this.onErrorEventListener)
  }
  componentWillUnmount() {
    document.removeEventListener(
      authExpiredEventName,
      this.onErrorEventListener
    )
  }
  onErrorEventListener = () => {
    this.setState({ visible: true })
  }
  onLogout = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    var redirectUrl =
      window.location.pathname + window.location.search + window.location.hash

    redirectUrl = "/signin?redirectTo=" + encodeURIComponent(redirectUrl)
    logoutToUrl(redirectUrl)
  }

  setVisible = (visible: boolean) => {
    this.setState({ visible: visible })
  }

  render() {
    let { visible } = this.state
    return (
      <Modal visible={visible} setVisible={this.setVisible}>
        <div
          style={{
            margin: `${px(3)} auto`,
            width: "265px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: px(3.25),
              lineHeight: px(4.125),
              fontWeight: 700,
              fontFamily: "Roboto Condensed",
              textAlign: "center",
              marginBottom: px(1),
            }}
          >
            Session Expired
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: px(2),
              lineHeight: px(2.5),
              marginBottom: px(2),
            }}
          >
            As a security measure, your credentials expire after a limited time.
            <br />
            <br />
            Please log in again to continue.
          </div>
          <PrimaryButton type="primary" onClick={this.onLogout}>
            Log in
          </PrimaryButton>
        </div>
      </Modal>
    )
  }
}
