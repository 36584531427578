import React, { FormEvent, FunctionComponent } from "react"
import { useFormStateController, View as SignInFormView } from "./SignInForm"

import px from "../px"

function noop() {}
let defaultField: any = {
  setValue: noop,
  error: null,
  setError: noop,
  touched: false,
  setTouched: noop,
  onChange: noop,
  onBlur: noop,
}

const SignInFormDefaultExample = () => {
  let formProps = useFormStateController()
  return (
    <SignInFormView
      {...formProps}
      loading={false}
      onSubmit={(e: FormEvent) => e.preventDefault()}
    />
  )
}

let View: FunctionComponent = () => {
  return (
    <div
      style={{
        display: "grid",
        columnGap: px(4),
        gridTemplateColumns: "repeat(auto-fill, 500px)",
      }}
    >
      <div style={{ display: "flex" }}>
        <p
          style={{ minWidth: "200px", marginBottom: px(1), marginRight: px(4) }}
        >
          Interactive example
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SignInFormDefaultExample />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <p
          style={{ minWidth: "200px", marginBottom: px(1), marginRight: px(4) }}
        >
          Error state (non-interactive)
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SignInFormView
            loading={false}
            email={{
              ...defaultField,
              touched: true,
              error: "Example error",
              value: "",
            }}
            password={{
              ...defaultField,
              touched: true,
              error: "Example error",
              value: "",
            }}
            rememberMe={true}
            onRememberMeChange={noop}
            onSubmit={noop}
          />
        </div>
      </div>
    </div>
  )
}

export default View
