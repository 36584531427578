import React, { useState, useMemo, FunctionComponent } from "react"
import { Space, Row, Col } from "antd"
import { ColumnsType } from "antd/es/table"
import gql from "graphql-tag"
import { useSubscription, useQuery } from "@apollo/react-hooks"
import { Link } from "react-router-dom"

import DiscreetLink from "../components/DiscreetLink"
import IndexTitle from "../components/IndexTitle"
import IndexSearch from "../components/IndexSearch"
import FieldSelect from "../components/FieldSelect"
import Table from "../components/Table"
import px from "../px"
import formatDate from "core_ui/lib/formatDate"
import { useAccessorSearch } from "core_ui/lib/hooks"
import { blackBase } from "../colors"
import { indexSearchHeight, indexTitleHeight } from "../constants"
import sharedStyles from "../sharedStyles"
import { CAMPAIGN_FRAGMENT, Campaign } from "./CampaignDetail"

export const CAMPAIGN_QUERY = gql`
  query Campaigns {
    campaigns {
      ...campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

export const CAMPAIGN_SUBSCRIPTION = gql`
  subscription Campaigns {
    campaigns {
      ...campaign
    }
  }
  ${CAMPAIGN_FRAGMENT}
`

const columns: ColumnsType<Campaign> = [
  {
    title: "Campaign Name",
    dataIndex: "name",
    render: (text, record, _index) => {
      return (
        <div style={sharedStyles.tableElement}>
          {<DiscreetLink to={`/campaigns/${record.id}`}>{text}</DiscreetLink>}
        </div>
      )
    },
    sorter: (a: Campaign, b: Campaign) => {
      return a.name.localeCompare(b.name)
    },
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    render: (text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>{formatDate(text)}</div>
      )
    },
    sorter: (a: Campaign, b: Campaign) => {
      return +new Date(a.updated_at) - +new Date(b.updated_at)
    },
    defaultSortOrder: "descend",
  },
  {
    title: "Uploaded",
    dataIndex: "created_at",
    render: (text, _record, _index) => {
      return (
        <div style={sharedStyles.tableElementNoWrap}>{formatDate(text)}</div>
      )
    },
    sorter: (a: Campaign, b: Campaign) =>
      +new Date(a.created_at) - +new Date(b.created_at),
  },
  {
    title: "Brand",
    render: (_text, record, _index) => (
      <div style={sharedStyles.tableElementNoWrap}>{record.brand?.name}</div>
    ),
    sorter: (a: Campaign, b: Campaign) => {
      let aBrand = a.brand?.name || ""
      let bBrand = b.brand?.name || ""
      return aBrand.localeCompare(bBrand)
    },
  },
  {
    title: "Advertiser",
    render: (_text, record, _index) => (
      <div style={sharedStyles.tableElementNoWrap}>
        {record.advertiser?.name}
      </div>
    ),
    sorter: (a: Campaign, b: Campaign) => {
      let aCampaign = a.advertiser?.name || ""
      let bCampaign = b.advertiser?.name || ""
      return aCampaign.localeCompare(bCampaign)
    },
  },
  {
    title: "Action",
    render: (_text, record, _index) => {
      let linkStyle = {
        textTransform: "uppercase" as const,
        fontSize: "14px",
        lineHeight: "16px",
        whiteSpace: "nowrap" as const,
      }
      return (
        <div style={sharedStyles.tableElementNoWrap}>
          {
            <Space direction="vertical">
              <Link
                style={linkStyle}
                to={`/campaigns/${record.id}/pld_delivery`}
              >
                PLD delivery
              </Link>
              <Link
                style={linkStyle}
                to={`/campaigns/${record.id}/aggregate_data`}
              >
                Aggregate data
              </Link>
            </Space>
          }
        </div>
      )
    },
  },
]

type ViewProps = ReturnType<typeof useController>
let View: FunctionComponent<ViewProps> = (props) => {
  return (
    <div className="indexPage" style={sharedStyles.indexPageContainer}>
      <Row style={{ height: px(6), marginBottom: px(2) }}>
        <Col>
          <IndexTitle title="Physician Level Reporting" />
        </Col>
        <Col
          span={8}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: `${(indexTitleHeight - indexSearchHeight) / 2}px`,
            marginLeft: "auto",
          }}
        >
          <IndexSearch
            onChange={props.onSearchChange}
            placeholder="Search Campaigns"
          />
        </Col>
      </Row>
      <Row align="middle" style={{ marginBottom: px(4) }}>
        <Col
          span={4}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginRight: px(3),
          }}
        >
          <div
            style={{ color: blackBase, fontSize: "16px", marginBottom: px(1) }}
          >
            Filter by advertiser
          </div>
          <FieldSelect<{ id: number; name: string }>
            placeholder="Option"
            onChange={props.onAdvertiserFilterChange}
            collection={props.advertisers}
            field="name"
          />
        </Col>
        <Col
          span={4}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginRight: px(3),
          }}
        >
          <div
            style={{ color: blackBase, fontSize: "16px", marginBottom: px(1) }}
          >
            Filter by brand
          </div>
          <FieldSelect<{ id: number; name: string }>
            placeholder="Option"
            onChange={props.onBrandFilterChange}
            collection={props.brands}
            field="name"
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {props.searchValue ? (
            <div
              style={{ ...sharedStyles.searchResultsFor, marginBottom: px(3) }}
            >
              {props.data.length
                ? `Results for "${props.searchValue}"`
                : `No results for "${props.searchValue}"`}
            </div>
          ) : null}
          <Table<Campaign>
            loading={props.campaignLoading}
            columns={columns}
            dataSource={props.data}
          />
        </Col>
      </Row>
    </div>
  )
}

let useController = () => {
  let campaignQuery = useQuery(CAMPAIGN_QUERY)
  let campaignSubscription = useSubscription(CAMPAIGN_SUBSCRIPTION)
  let data = useMemo(
    () =>
      (
        campaignSubscription.data?.campaigns ||
        campaignQuery.data?.campaigns ||
        []
      )
        .slice()
        .reverse(),
    [campaignQuery, campaignSubscription]
  )
  let [filterAdvertiserId, setFilterAdvertiserId] = useState<number | null>(
    null
  )
  let [filterBrandId, setFilterBrandId] = useState<number | null>(null)

  let [searchedData, searchValue, onSearchChange] = useAccessorSearch<Campaign>(
    (d: Campaign) => [d.name],
    data
  )
  let campaignLoading =
    !campaignQuery.data && campaignQuery.loading && campaignSubscription.loading
  let advertisers = useMemo(() => {
    // Produce the unique set of advertisers
    let advertiserSet = new Set<number>()
    let advertisers: { id: number; name: string }[] = []
    for (let d of searchedData) {
      if (d.advertiser != null) {
        let advertiserId = d.advertiser_id as number
        if (advertiserSet.has(advertiserId)) continue
        advertiserSet.add(advertiserId)
        advertisers.push(d.advertiser)
      }
    }
    advertisers.sort((a: { name: string }, b: { name: string }) =>
      a.name.localeCompare(b.name)
    )
    return advertisers
  }, [searchedData])
  let brands = useMemo(() => {
    let brandSet = new Set<number>()
    let brands: { id: number; name: string }[] = []
    for (let d of searchedData) {
      // Skip this brand if it's not associated with our filterAdvertiser
      if (
        filterAdvertiserId != null &&
        d.advertiser_id !== filterAdvertiserId
      ) {
        continue
      }
      if (d.brand != null) {
        let brandId = d.brand.id as number
        if (brandSet.has(brandId)) continue
        brandSet.add(brandId)
        brands.push(d.brand)
      }
    }
    brands.sort((a: { name: string }, b: { name: string }) =>
      a.name.localeCompare(b.name)
    )
    return brands
  }, [filterAdvertiserId, searchedData])

  let filteredAndSearchedData = useMemo(
    () =>
      searchedData.filter((d: Campaign) => {
        // Assert that brand matches filter if filter exists
        if (filterBrandId != null && filterBrandId !== d.brand_id) {
          return false
        }
        if (
          filterAdvertiserId != null &&
          filterAdvertiserId !== d.advertiser_id
        ) {
          return false
        }
        return true
      }),
    [searchedData, filterAdvertiserId, filterBrandId]
  )

  return {
    onSearchChange,
    onAdvertiserFilterChange: (id: unknown) =>
      setFilterAdvertiserId(id as number),
    onBrandFilterChange: (id: unknown) => setFilterBrandId(id as number),
    campaignLoading,
    data: filteredAndSearchedData,
    advertisers,
    searchValue,
    brands,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
