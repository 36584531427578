import React, { ComponentProps } from "react"
import { Pagination } from "antd"
import { TablePaginationConfig } from "antd/es/table"
import { PaginationProps } from "antd/es/pagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons"

type PaginationItemRenderer = ComponentProps<typeof Pagination>["itemRender"]
const paginationItemRenderer: PaginationItemRenderer = (
  current,
  type,
  originalElement
) => {
  if (type === "prev") {
    return <FontAwesomeIcon icon={faChevronLeft} />
  }
  if (type === "next") {
    return <FontAwesomeIcon icon={faChevronRight} />
  }
  return originalElement
}

export const defaultConfig: TablePaginationConfig = {
  itemRender: paginationItemRenderer,
  showSizeChanger: false,
  position: ["bottomCenter"],
}

function View(props: PaginationProps): JSX.Element {
  let paginationProps = {
    ...defaultConfig,
    ...props,
  }
  return <Pagination {...paginationProps} />
}

export default View
