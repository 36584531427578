import React, {
  FormEvent,
  FunctionComponent,
  useState,
  useCallback,
  ComponentProps,
} from "react"
import { message, Row, Col, Checkbox } from "antd"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

import PrimaryButton from "./PrimaryButton"
import px from "../px"
import { User } from "../hooks/useUser"

import { useDependentState } from "core_ui/lib/hooks"
import { catchCriticalError } from "core_ui/lib/api"
import formatError from "core_ui/lib/formatError"
import { Modal } from "./Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import sharedStyles from "../sharedStyles"

const UPDATE_USER_NOTIFICATIONS_QUERY = gql`
  mutation(
    $user_id: Int!
    $receive_target_list_from_ftp_notifications: Boolean!
    $receive_segment_created_notifications: Boolean!
    $receive_pld_delivered_notifications: Boolean!
  ) {
    update_users(
      where: { id: { _eq: $user_id } }
      _set: {
        receive_target_list_from_ftp_notifications: $receive_target_list_from_ftp_notifications
        receive_segment_created_notifications: $receive_segment_created_notifications
        receive_pld_delivered_notifications: $receive_pld_delivered_notifications
      }
    ) {
      returning {
        id
      }
    }
  }
`

type ViewProps = ReturnType<typeof useController>
export const View: FunctionComponent<ViewProps> = (props) => {
  let {
    loading,
    onSubmit,
    updatedNotificationPreferences,
    setUpdatedNotificationPreferences,
    receiveTargetListFromFtpNotifications,
    receiveTargetListFromFtpNotificationsOnChange,
    receiveSegmentCreatedNotifications,
    receiveSegmentCreatedNotificationsOnChange,
    receivePldDeliveredNotifications,
    receivePldDeliveredNotificationsOnChange,
  } = props

  const submitButtonProps = {
    loading,
    disabled: loading,
    htmlType: "submit" as const,
    label: "SAVE PREFERENCES",
  }

  const checkBoxItemProps = {
    padding: "8px",
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Checkbox
          checked={receiveTargetListFromFtpNotifications}
          onChange={receiveTargetListFromFtpNotificationsOnChange}
          disabled={loading}
          style={checkBoxItemProps}
        >
          Receive email alerts about new lists
        </Checkbox>

        <Checkbox
          checked={receiveSegmentCreatedNotifications}
          onChange={receiveSegmentCreatedNotificationsOnChange}
          disabled={loading}
          style={checkBoxItemProps}
        >
          Receive email alerts when new segments are created
        </Checkbox>

        <Checkbox
          checked={receivePldDeliveredNotifications}
          onChange={receivePldDeliveredNotificationsOnChange}
          disabled={loading}
          style={checkBoxItemProps}
        >
          Receive email alerts about new PLD
        </Checkbox>

        <Row justify="space-between">
          <Col>
            <PrimaryButton {...submitButtonProps} style={{ marginTop: px(5) }}>
              {submitButtonProps.label}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
      <Modal
        visible={updatedNotificationPreferences}
        setVisible={setUpdatedNotificationPreferences}
      >
        <div
          style={{
            position: "relative",
            padding: `${px(1.5)} ${px(2)} ${px(1)} ${px(2)}`,
            fontSize: "24px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
          {/* Capture a larger area than the icon for a click region */}
          <div
            onClick={() => {
              setUpdatedNotificationPreferences(false)
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              width: px(6),
            }}
          />
        </div>
        <div
          style={{
            margin: `0 auto ${px(3)} auto`,
            width: "250px",
            display: "grid",
            rowGap: px(1.5),
            /* This will allow us to have N rows where the row's height is
             * determined by its content */
            gridTemplateRows: "repeat(auto-fill, minmax(min-content, 0))",
          }}
        >
          <div style={sharedStyles.modalHeader}>Success!</div>
          <div style={{ textAlign: "center", fontSize: px(2) }}>
            Your email notification preferences have been saved.
          </div>
          <PrimaryButton
            type="primary"
            onClick={() => {
              setUpdatedNotificationPreferences(false)
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </Modal>
    </>
  )
}

type ComponentPropsT = {
  user: User
}
const Component: FunctionComponent<ComponentPropsT> = (props) => {
  let viewProps = useController(props)
  return <View {...viewProps} />
}

type CheckboxOnChange = NonNullable<ComponentProps<typeof Checkbox>["onChange"]>

const useController = (props: ComponentPropsT) => {
  let { user } = props
  const [loading, setLoading] = useState(false)
  const [
    updatedNotificationPreferences,
    setUpdatedNotificationPreferences,
  ] = useState(false)

  const [updateUserNotifications] = useMutation(UPDATE_USER_NOTIFICATIONS_QUERY)

  let [
    receiveTargetListFromFtpNotifications,
    setReceiveTargetListFromFtpNotifications,
  ] = useDependentState(user.receive_target_list_from_ftp_notifications)
  let [
    receiveSegmentCreatedNotifications,
    setReceiveSegmentCreatedNotifications,
  ] = useDependentState(user.receive_segment_created_notifications)
  let [
    receivePldDeliveredNotifications,
    setReceivePldDeliveredNotifications,
  ] = useDependentState(user.receive_pld_delivered_notifications)

  let userId = user.id
  const onSubmit = useCallback(
    (e: FormEvent) => {
      // Prevent default action of a form submit
      e.preventDefault()

      setLoading(true)

      updateUserNotifications({
        variables: {
          user_id: userId,
          receive_target_list_from_ftp_notifications: receiveTargetListFromFtpNotifications,
          receive_segment_created_notifications: receiveSegmentCreatedNotifications,
          receive_pld_delivered_notifications: receivePldDeliveredNotifications,
        },
      })
        .then(() => {
          setUpdatedNotificationPreferences(true)
        })
        .catch(catchCriticalError)
        .catch((error) => {
          message.error(formatError(error))
        })
        .finally(() => setLoading(false))
    },
    [
      updateUserNotifications,
      userId,
      receiveTargetListFromFtpNotifications,
      receiveSegmentCreatedNotifications,
      receivePldDeliveredNotifications,
    ]
  )

  const receiveTargetListFromFtpNotificationsOnChange = useCallback<
    CheckboxOnChange
  >(
    (e) => {
      setReceiveTargetListFromFtpNotifications(e.target.checked)
    },
    [setReceiveTargetListFromFtpNotifications]
  )
  const receiveSegmentCreatedNotificationsOnChange = useCallback<
    CheckboxOnChange
  >(
    (e) => {
      setReceiveSegmentCreatedNotifications(e.target.checked)
    },
    [setReceiveSegmentCreatedNotifications]
  )
  const receivePldDeliveredNotificationsOnChange = useCallback<
    CheckboxOnChange
  >(
    (e) => {
      setReceivePldDeliveredNotifications(e.target.checked)
    },
    [setReceivePldDeliveredNotifications]
  )

  return {
    loading,
    onSubmit,
    updatedNotificationPreferences,
    setUpdatedNotificationPreferences,
    receiveTargetListFromFtpNotifications,
    receiveTargetListFromFtpNotificationsOnChange,
    receiveSegmentCreatedNotifications,
    receiveSegmentCreatedNotificationsOnChange,
    receivePldDeliveredNotifications,
    receivePldDeliveredNotificationsOnChange,
  }
}

export default Component
