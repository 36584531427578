import React, { useMemo, FunctionComponent } from "react"
import { Avatar, Button, Menu } from "antd"

import { Link, useLocation } from "react-router-dom"

import { grayBase8 } from "../colors"
import Logo from "../assets/healiolytics-logo.svg"

import { logout } from "core_ui/lib/auth"
import NavBox from "../assets/nav-box.svg"
import px from "../px"

import useUser from "../hooks/useUser"

type User = {
  email: string
  first_name: string | null
  last_name: string | null
}

let loginTextStyle = {
  fontSize: "14px",
  padding: `0px ${px(1)}`,
  lineHeight: "24px",
  alignItems: "center",
  display: "inline-flex",
  height: px(6),
}
const navBoxBoundingWidth = 246
const navBoxBoundingHeight = 72

export const View: FunctionComponent<ReturnType<typeof useController>> = (
  props
) => {
  let { user, showMenu, showLogin, location } = props

  const navMenuChildren = useMemo(
    () => [
      <Menu.Item key="/upload">
        <Link to="/upload">Upload</Link>
      </Menu.Item>,
      <Menu.Item key="/lists_match">
        <Link to="/lists_match">Match</Link>
      </Menu.Item>,
      <Menu.Item key="/lists_send">
        <Link to="/lists_send">Send</Link>
      </Menu.Item>,
      <Menu.Item key="/campaigns">
        <Link to="/campaigns">PLD</Link>
      </Menu.Item>,
      <Menu.Item key="/segments">
        <Link to="/segments">Segments</Link>
      </Menu.Item>,
    ],
    []
  )

  let selectedKey = useMemo(() => {
    let isMatchTab = /^\/lists\/\d+\/match_rates/.test(location.pathname)

    if (isMatchTab) {
      return "/lists_match"
    }

    let isSendTab = /^\/lists\/\d+\/create_segments/.test(location.pathname)
    if (isSendTab) {
      return "/lists_send"
    }

    // Strip anything after the first path, so that sub routes like
    // /campaigns/392/details result in the /campaigns menu item being selected
    //
    // /campaigns/392/details -> /campaigns
    let secondSlashIndex = location.pathname.indexOf("/", 1)
    if (secondSlashIndex === -1) {
      return location.pathname
    }
    return location.pathname.substring(0, secondSlashIndex)
  }, [location.pathname])

  let navMenu = showMenu ? (
    <Menu selectedKeys={[selectedKey]} mode="horizontal">
      {navMenuChildren}
    </Menu>
  ) : null

  let navLogin = null
  if (showLogin) {
    let firstName = user.data?.first_name ?? ""
    let lastName = user.data?.last_name ?? ""
    let initials = firstName.substring(0, 1) + lastName.substring(0, 1)
    navLogin = (
      <div
        style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
      >
        { user.loading || user.data ?
        <>
        <Link to={"/profile"} style={loginTextStyle}>
          <Avatar style={{ backgroundColor: grayBase8 }}>{initials}</Avatar>
        </Link>
        <Button type="link" onClick={logout} style={loginTextStyle}>
          Log out
        </Button>
        </>
         : 
         <Link type="link" to={"/signin"} style={loginTextStyle}>
          Log In
        </Link>
         }
      </div>
    )
  }

  return (
    <div className="global-nav" style={{ position: "relative" }}>
      <Link
        style={{
          backgroundImage: `url(${NavBox})`,
          display: "inline-block",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-1px 0px",
          position: "absolute",
          width: `${navBoxBoundingWidth}px`,
          height: `${navBoxBoundingHeight}px`,
        }}
        to="/"
      >
        <img
          style={{ textAlign: "center", padding: "6px 0 0 11px" }}
          src={Logo}
          alt="HealioLytics Logo"
        />
      </Link>
      <div
        style={{
          marginLeft: "28px",
          paddingRight: px(1),
          paddingLeft: `${navBoxBoundingWidth}px`,
          backgroundColor: grayBase8,
          alignItems: "center",
          flex: 1,
          height: px(6),
          display: "flex",
        }}
      >
        {navMenu}
        {navLogin}
      </div>
    </div>
  )
}

let useController = () => {
  let location = useLocation()
  const routesWithoutMenu = [
    /^\/$/,
    /^\/signin/,
    /^\/request_password_reset/,
    /^\/reset_password/,
    /^\/set_password/,
  ]
  const routesWithoutLogin = [
    /^\/signin/,
    /^\/request_password_reset/,
    /^\/reset_password/,
    /^\/set_password/,
  ]

  let skipUserFetch = routesWithoutLogin.some((regex) =>
    regex.test(location.pathname)
  )
  let user: { loading: boolean; data: User | null } = useUser(skipUserFetch)
  return {
    user,
    showMenu: !routesWithoutMenu.some((regex) => regex.test(location.pathname)),
    showLogin: !skipUserFetch,
    location,
  }
}

let Component = () => {
  let viewProps = useController()
  return View(viewProps)
}

export default Component
