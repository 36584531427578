import React, {
  FormEvent,
  ComponentProps,
  FunctionComponent,
  useState,
  useCallback,
} from "react"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import * as Yup from "yup"
import { message, Checkbox, Input } from "antd"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

import { ActionType } from "../redux/root"
import PrimaryButton from "./PrimaryButton"
import { signIn, catchCriticalError } from "core_ui/lib/api"
import { useField, useLocalStorage } from "core_ui/lib/hooks"
import formatError from "core_ui/lib/formatError"
import { redBase } from "../colors"
import sharedStyles from "../sharedStyles"
import px from "../px"

let emailSchema = Yup.string()
  .email("Please enter a valid Email Address")
  .trim()
  .required("Enter your Email Address")

let passwordSchema = Yup.string().trim().required("Enter your password")

type ViewProps = ReturnType<typeof useController>
export const View: FunctionComponent<ViewProps> = (props) => {
  let {
    loading,
    onSubmit,
    email,
    password,
    rememberMe,
    onRememberMeChange,
  } = props

  const buttonProps = {
    label: "Log In",
    loading,
    disabled: loading,
    htmlType: "submit" as const,
  }

  return (
    <form className="sign-in-form" onSubmit={onSubmit}>
      <div style={{ marginBottom: px(4) }}>
        <label
          htmlFor="email"
          style={{
            display: "block",
            fontSize: px(2),
            marginBottom: px(0.25),
          }}
        >
          Email<span style={{ color: redBase }}>*</span>
        </label>
        <Input
          className={email.touched && email.error ? "error" : ""}
          id="email"
          value={email.value}
          onBlur={email.onBlur}
          onChange={email.onChange}
          placeholder="Email"
          style={{
            borderRadius: px(0.5),
          }}
        />
        <div style={sharedStyles.fieldError}>
          {email.touched && email.error}
        </div>
      </div>

      <div style={{ marginBottom: px(3) }}>
        <label
          htmlFor="password"
          style={{
            display: "block",
            fontSize: px(2),
            marginBottom: px(0.25),
          }}
        >
          Password<span style={{ color: redBase }}>*</span>
        </label>
        <Input.Password
          id="password"
          value={password.value}
          onBlur={password.onBlur}
          onChange={password.onChange}
          placeholder="Password"
          iconRender={(visible: boolean) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          style={{
            borderRadius: px(0.5),
          }}
        />
        <div style={sharedStyles.fieldError}>
          {password.touched && password.error}
        </div>
      </div>

      <div style={{ marginBottom: px(2.5) }}>
        <Checkbox checked={rememberMe} onChange={onRememberMeChange}>
          Remember me
        </Checkbox>
      </div>

      <div
        style={{
          marginBottom: px(4),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PrimaryButton {...buttonProps}>{buttonProps.label}</PrimaryButton>
      </div>

      <div
        style={{
          fontSize: px(2),
          marginBottom: px(1.75),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link
          style={{
            textDecoration: "underline",
            textUnderlinePosition: "under",
          }}
          to="/request_password_reset"
        >
          Reset password
        </Link>
      </div>
      <div
        style={{
          fontSize: px(2),
          marginBottom: px(4),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          Do you need help?{" "}
          <a
            href="mailto:support@healiolytics.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              textUnderlinePosition: "under",
            }}
          >
            Email Healiolytics
          </a>
        </div>
      </div>
    </form>
  )
}

type FirstParam<T> = T extends (first: infer F, ...args: any[]) => any ? F : any

type CheckboxOnChange = NonNullable<ComponentProps<typeof Checkbox>["onChange"]>

export const useFormStateController = () => {
  // This logic was taken out of useController, so that the view would be
  // easier to include in the sandbox

  const [rememberMe, setRememberMe] = useLocalStorage("rememberMe", true)
  const location = useLocation<{ email?: string }>()

  let email = useField({
    initial: location?.state?.email || "",
    schema: emailSchema,
  })
  let password = useField({ initial: "", schema: passwordSchema })
  const onRememberMeChange = useCallback<CheckboxOnChange>(
    (e) => {
      setRememberMe(e.target.checked)
    },
    [setRememberMe]
  )

  return {
    email,
    password,
    rememberMe,
    onRememberMeChange,
  }
}

const useController = () => {
  let {
    email,
    password,
    rememberMe,
    onRememberMeChange,
  } = useFormStateController()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    (e: FormEvent) => {
      // Prevent default action of a form submit
      e.preventDefault()

      // Mark all fields as touched
      email.setTouched(true)
      password.setTouched(true)

      if (email.error || password.error) {
        return
      }

      setLoading(true)
      signIn({ email: email.value.trim(), password: password.value })
        .then((resp) => {
          let token = resp.data.token
          dispatch({ type: ActionType.USER_AUTHENTICATED, data: token })
          if (rememberMe) {
            localStorage.setItem("token", token)
          }
          let urlParams = new URLSearchParams(window.location.search)
          let redirectLocation = urlParams.get("redirectTo")
          if (redirectLocation) {
            redirectLocation = decodeURIComponent(redirectLocation)
            if (!redirectLocation.startsWith("/")) {
              redirectLocation = "/" + redirectLocation
            }
            history.push(redirectLocation)
          } else {
            history.push("/")
          }
        })
        .catch(catchCriticalError)
        .catch((error) => {
          if (error?.response?.data?.error === "User account does not exist") {
            email.setError(error.response.data.error)
          } else if (error?.response?.data?.error === "Password is incorrect") {
            password.setError(error.response.data.error)
          } else if (error?.response?.data?.email) {
            email.setError(error.response.data.email)
          } else if (error?.response?.data?.password) {
            password.setError(error.response.data.password)
          } else {
            message.error(formatError(error))
          }
        })
        .finally(() => setLoading(false))
    },
    [history, dispatch, email, password, rememberMe]
  )

  return {
    loading,
    onSubmit,
    email,
    password,
    rememberMe,
    onRememberMeChange,
  }
}

const Component: FunctionComponent = () => {
  let viewProps = useController()
  return <View {...viewProps} />
}

export default Component
