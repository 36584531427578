import React from "react"
import { ColumnsType } from "antd/es/table"
import Table from "./Table"

interface User {
  key: number
  name: string
  age: number
  hair: string
}

const columns: ColumnsType<User> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "age",
    title: "Age",
    dataIndex: "age",
    sorter: (a, b) => a.age - b.age,
  },
  {
    key: "hair",
    title: "Hair",
    dataIndex: "hair",
  },
]

const data: User[] = [
  {
    key: 0,
    name: "Jack",
    age: 5,
    hair: "Brunette",
  },
  {
    key: 1,
    name: "Jane",
    age: 7,
    hair: "Blond",
  },
]

export default () => <Table<User> columns={columns} dataSource={data} />
