import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import * as Sentry from "@sentry/react"

import px from "../px"
import PrimaryButton from "./PrimaryButton"
import { Modal } from "./Modal"
import { errorBoundaryEventName } from "core_ui/lib/api"
import formatError from "core_ui/lib/formatError"

// NOTE: This class is a singleton, it should not be instantiated more than
// once.
//
// It provides a fallback for errors that happen in a subtree. It also listens
// for errors which are sent to its observable. This is primarily so that
// event handlers and others can send it an error.
export default class extends React.Component<
  {},
  { eventError: any; childrenError: any }
> {
  constructor(props: {}) {
    super(props)
    this.state = {
      eventError: null,
      childrenError: null,
    }
  }

  clearError = () => this.setState({ eventError: null, childrenError: null })

  onErrorEventListener = (e: unknown) => {
    let error = (e as { detail: any }).detail

    Sentry.captureException(error)

    this.setState({ eventError: error })
  }

  componentDidMount() {
    document.addEventListener(errorBoundaryEventName, this.onErrorEventListener)
  }

  componentWillUnmount() {
    document.removeEventListener(
      errorBoundaryEventName,
      this.onErrorEventListener
    )
  }

  static getDerivedStateFromError(childrenError: any) {
    // Currently only one modal can be open, so in order to show our error
    // modal, we must close others
    // Modal.destroyAll()
    Sentry.captureException(childrenError)

    return { childrenError }
  }

  render() {
    let { eventError, childrenError } = this.state
    let error = eventError || childrenError
    let hasError = error != null

    let errorModal = (
      <Modal
        visible={error}
        setVisible={() => {
          error = null
        }}
      >
        <div
          style={{
            position: "relative",
            padding: `${px(1.5)} ${px(2)} ${px(1)} ${px(2)}`,
            fontSize: px(3),
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={this.clearError}
        >
          <FontAwesomeIcon icon={faTimes} />
          {/* Capture a larger area than the icon for a click region */}
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              width: px(6),
            }}
          />
        </div>
        <div
          style={{
            margin: `${px(0)} auto ${px(3)}`,
            width: "265px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: px(3.25),
              lineHeight: px(4.125),
              fontWeight: 700,
              fontFamily: "Roboto Condensed",
              textAlign: "center",
              marginBottom: px(1),
            }}
          >
            An Error Occurred
          </div>
          <div
            style={{
              fontSize: px(2),
              lineHeight: px(2.5),
              marginBottom: px(2.5),
              textAlign: "center",
            }}
          >
            We're sorry, there was an error processing your request.
          </div>
          <div
            style={{
              fontSize: px(2),
              lineHeight: px(2.5),
              marginBottom: px(2.5),
              textAlign: "center",
            }}
          >
            Please refresh the page and try again.
          </div>
          <div
            style={{
              fontSize: px(2),
              lineHeight: px(2.5),
              marginBottom: px(2.5),
              textAlign: "center",
            }}
          >
            {error && formatError(error)}
          </div>
          <div
            style={{
              fontSize: px(2),
              lineHeight: px(2.5),
              marginBottom: px(2),
              textAlign: "center",
            }}
          >
            If the problem continues,{" "}
            <a
              href="mailto:support@healiolytics.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
              }}
            >
              contact Healiolytics{" "}
            </a>
            for assistance.
          </div>
          <PrimaryButton type="primary" onClick={this.clearError}>
            Got it
          </PrimaryButton>
        </div>
      </Modal>
    )

    // If we render props.children, when props.children has thrown an error
    // (childrenError), the application will crash indefinitely
    if (hasError) {
      return (
        <>
          {errorModal}
          {childrenError == null ? this.props.children : null}
        </>
      )
    }

    return this.props.children
  }
}
