import React, { FunctionComponent } from "react"

import { whiteBase, silver1 } from "../colors"
import { dummyLocation } from "./SandboxNav"

import Nav, { View as NavView } from "./Nav"
import { WidthContainer, WidthScrollbarContainer } from "./WidthContainer"

export const FullWidthLayout: FunctionComponent = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: whiteBase,
      }}
    >
      <WidthContainer
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        <Nav />
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {props.children}
        </div>
      </WidthContainer>
    </div>
  )
}

type LayoutProps = { inMaintenance?: boolean }
export const Layout: FunctionComponent<LayoutProps> = (props) => {
  let { inMaintenance } = props
  return (
    <div
      id="scroll-container"
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        backgroundColor: silver1,
      }}
    >
      <WidthContainer>
        {inMaintenance ? (
          <NavView
            location={dummyLocation}
            user={{ loading: false, data: null }}
            showMenu={false}
            showLogin={false}
          />
        ) : (
          <Nav />
        )}
      </WidthContainer>
      <WidthScrollbarContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          {props.children}
        </div>
      </WidthScrollbarContainer>
    </div>
  )
}
