import { eventChannel } from "redux-saga"
import { call, put, take } from "redux-saga/effects"
import throttle from "lodash.throttle"
import { ActionType } from "./root"

function createWindowWidthChannel() {
  return eventChannel((emitter) => {
    // Throttle resize events, only call once in any 250ms window
    let handler = throttle(() => emitter({}), 250)
    window.addEventListener("resize", handler)
    return () => window.removeEventListener("resize", handler)
  })
}

function* saga() {
  let channel = yield call(createWindowWidthChannel)
  while (true) {
    yield take(channel)
    yield put({
      type: ActionType.RESIZE,
      data: window.innerWidth,
    })
  }
}

export default saga
