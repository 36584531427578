import React, { useState, FunctionComponent } from "react"
import { AxiosError } from "axios"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { message } from "antd"

import { catchCriticalError } from "core_ui/lib/api"
import { ActionType } from "../redux/root"
import { getToken } from "../selectors"
import { whiteBase, blueBase } from "../colors"
import { useLocalStorage } from "core_ui/lib/hooks"
import Ellipsis from "./Ellipsis"
import px from "../px"

import useUser from "../hooks/useUser"
import { tokenExpiredOrInvalid, logoutToUrl } from "core_ui/lib/auth"
import { emulate } from "../api"
import formatError from "core_ui/lib/formatError"

type User = {
  email: string
  first_name: string | null
  last_name: string | null
}

function cancelEmulation() {
  console.log(
    localStorage.getItem("token"),
    localStorage.getItem("emulatorToken")
  )
  let emulatorToken = localStorage.getItem("emulatorToken")
  localStorage.removeItem("emulatorToken")
  if (emulatorToken) {
    localStorage.setItem("token", emulatorToken)
    window.location.reload()
  } else {
    logoutToUrl("/signin")
  }
}

export const Component: FunctionComponent = (props) => {
  let user: { loading: boolean; data: User | null } = useUser()
  let [emulationLoading, setEmulationLoading] = useState(false)
  let history = useHistory()

  // This component will inspect the token and conditionally redirect.
  // Otherwise it will load the app.
  const dispatch = useDispatch()
  const [emulatorToken, setEmulatorToken] = useLocalStorage("emulatorToken", "")

  let token = useSelector(getToken)

  let urlParams = new URLSearchParams(window.location.search)
  let emulateParam = "emulate"
  let emulateEmail = urlParams.get(emulateParam)
  if (emulateEmail) {
    urlParams.delete(emulateParam)
    let noEmulateURL =
      window.location.pathname +
      (urlParams.toString() ? "?" + urlParams.toString() : "") +
      window.location.hash
    history.push(noEmulateURL)
    setEmulationLoading(true)
    emulate({ email: emulateEmail })
      .then((resp) => {
        if (!emulatorToken) {
          setEmulatorToken(token)
        }
        dispatch({
          type: ActionType.USER_AUTHENTICATED,
          data: resp.data.token,
        })
        // Normally we'd only set this if the user uses the "rememberMe"
        // setting during signin but emulation is a special exception
        localStorage.setItem("token", resp.data.token)
        window.location.reload()
      })
      .catch(catchCriticalError)
      .catch((error: AxiosError) => {
        if (emulatorToken && tokenExpiredOrInvalid(emulatorToken)) {
          message.error(
            `Your emulation token has expired please re-login as an admin.`
          )
        } else if (token && tokenExpiredOrInvalid(token)) {
          message.error(`Your token has expired please re-login as an admin.`)
        } else {
          message.error(`Emulation failed: ${formatError(error)}`)
        }
      })
      .finally(() => {
        setEmulationLoading(false)
      })
  }

  let hasEmulatorToken = !!localStorage.getItem("emulatorToken")
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {emulateEmail || emulationLoading || hasEmulatorToken ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: px(4),
            backgroundColor: blueBase,
          }}
        >
          <div
            style={{
              padding: `0px ${px(1)}`,
              fontWeight: 700,
              color: whiteBase,
            }}
          >
            Currently emulating{" "}
            {emulationLoading || user.loading ? (
              <Ellipsis />
            ) : (
              user.data?.email ?? "no one"
            )}
          </div>
          <button onClick={cancelEmulation}>Cancel emulation</button>
        </div>
      ) : null}
      {emulateEmail || emulationLoading ? null : props.children}
    </div>
  )
}

export default Component
