import React, { FunctionComponent } from "react"
import PieChart from "./PieChart"
import { greenBase, blueBase } from "../colors"
import ExportButtons from "./ExportButtons"
import jsPDF from "jspdf"
import canvg from "canvg"

let View: FunctionComponent = () => {
  return (
    <div>
      <div>
        <p>Small Pie Chart</p>
        <div style={{ width: "100px" }}>
          <PieChart
            size={50}
            colors={[greenBase, blueBase]}
            data={[
              { value: 20, name: undefined },
              { value: 80, name: undefined },
            ]}
            labels={false}
          />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", right: 0, marginTop: 35 }}>
          <ExportButtons
            onPDFClick={() => {
              return exportPieChart("piechart-npi")
            }}
          />
        </div>
        <p>NPIS Reached</p>
        <div style={{ width: "600px" }}>
          <PieChart
            size={500}
            colors={[greenBase, blueBase]}
            data={[
              { name: "NPIS REACHED", value: 100 },
              { name: "NPIS TARGETED", value: 800 },
            ]}
            startAngle={90}
            endAngle={-270}
            labels={true}
          />
        </div>
      </div>
      <div style={{ marginBottom: "50px" }}>
        <p>Values Only</p>
        <div style={{ width: "300px" }}>
          <PieChart
            size={250}
            colors={[greenBase, blueBase]}
            data={[
              { name: "", value: 1000 },
              { name: "", value: 8000 },
            ]}
            startAngle={90}
            endAngle={-270}
            labels={true}
          />
        </div>
      </div>
    </div>
  )
}

let exportPieChart = async (chartClass: string) => {
  //for some reason recharts wasn't setting the ID of the charts properly, so i decided to just use a class instead
  let chart = document.getElementsByClassName(chartClass)[0]

  const doc = new jsPDF()
  // const doc = new jsPDF("p", "px");
  /* var canvas = doc.canvas */
  let canvas = document.createElement("canvas")
  canvas.width = 350
  canvas.height = 350

  var options = {
    // offsetX : 35,
    // offsetY : 50,
    ignoreAnimation: true,
    // ignoreDimensions: true
  }

  let ctx = canvas.getContext("2d")
  var svg = new XMLSerializer().serializeToString(chart.children[0])

  // @ts-ignore
  let i = canvg.fromString(ctx, svg, options)
  i.start()

  // @ts-ignore
  doc.addImage(canvas, "PNG", 50, 0, 100, 100)

  doc.save("data.pdf")
}

export default View
