import escapeStringRegexp from "escape-string-regexp";
import bs from "binary-search";
// interface SearchFunc<T> {
//   (needle?: string, maxResults: number): T[]
// }
// search = searcher(d => [d.name, d.age], [{name:"a", age:4}, ...])
// search("4") -> {name:"a", age:4}
var searcher = function (accessor, array) {
    var offsets = [];
    var haystack = "";
    for (var i = 0; i < array.length; i++) {
        var keys = accessor(array[i]);
        for (var k = 0; k < keys.length; k++) {
            var key = keys[k];
            key = key || ""; // Coerce null/undefined to ""
            key = key + ""; // Coerce to a string type
            // EsLint warns against putting \x00 in a regex, because users are
            // unlikely to supply it as input. While its unlikely, it would break
            // this function, if our delimiter (\x00) were provided by the user
            //
            // eslint-disable-next-line
            key = key.replace(/\x00/g, "");
            haystack += "\x00" + key;
        }
        offsets.push(haystack.length);
    }
    return function (needle, maxResults) {
        if (maxResults === void 0) { maxResults = array.length; }
        // See note above
        //
        // eslint-disable-next-line
        needle = (needle || "").replace(/\x00/g, "");
        needle = escapeStringRegexp(needle);
        if (!needle) {
            return array.slice(0, maxResults);
        }
        var results = [];
        var rx = new RegExp(needle, "gi");
        var uniqueIndexes = {};
        var result;
        while (results.length < maxResults && (result = rx.exec(haystack))) {
            var matchIndex = result.index;
            var index = bs(offsets, matchIndex, function (a, b) { return a - b; });
            if (index < 0) {
                index = (index + 1) * -1;
            }
            if (index in uniqueIndexes) {
                // searching for 6 in 666, would match that term 3 times, only want to
                // record the first time it resolves to the index for 666
                continue;
            }
            uniqueIndexes[index] = true;
            results.push(array[index]);
        }
        return results;
    };
};
export default searcher;
