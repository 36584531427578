import React, { useEffect, FunctionComponent } from "react"
import { useSelector } from "react-redux"
import * as Sentry from "@sentry/react"

import { getToken } from "../selectors"
import PageLoader from "./PageLoader"

import AuthExpiredModal from "./AuthExpiredModal"
import {
  logoutToUrl,
  subFromToken,
  tokenExpiredOrInvalid,
} from "core_ui/lib/auth"

const View: FunctionComponent = (props) => {
  // This component will inspect the token and conditionally redirect.
  // Otherwise it will load the app.
  let token = useSelector(getToken)
  let userId: string | null = null
  if (token && !tokenExpiredOrInvalid(token)) {
    userId = subFromToken(token)
  }

  useEffect(() => {
    if (userId == null) {
      let redirectUrl =
        window.location.pathname + window.location.search + window.location.hash

      redirectUrl = "/signin?redirectTo=" + encodeURIComponent(redirectUrl)
      logoutToUrl(redirectUrl)
      return
    } else {
      Sentry.setUser({ id: userId as string })
    }
  }, [userId])

  if (userId == null) {
    return <PageLoader />
  }

  return (
    <>
      {props.children}
      <AuthExpiredModal />
    </>
  )
}
export default View
