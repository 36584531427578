import { jsPDF } from "jspdf"
import domToImage from "dom-to-image"
import hlLogo from "../assets/healiolytics-logo.png"
import { whiteBase } from "../colors"

export default async (componentSelectors: string[], documentName: string) => {
  // Units are mm, an A4 pdf is 210 mm
  let pageWidth = 210

  let image = document.createElement("img")
  const doc = new jsPDF()
  let pixelsInMM = 3.779
  let hlLogoWidth = 196
  let hlLogoHeight = 49
  image.width = hlLogoWidth
  image.height = hlLogoHeight
  image.src = hlLogo
  let contentBottom = 0
  doc.addImage(
    image,
    "PNG",
    16 / pixelsInMM,
    12 / pixelsInMM,
    pageWidth / 5,
    (pageWidth / 5) * (hlLogoHeight / hlLogoWidth)
  )

  contentBottom += (hlLogoHeight + 24) / pixelsInMM

  for (let x = 0; x < componentSelectors.length; x++) {
    let selector = componentSelectors[x]

    let chart = document.querySelector(selector)
    if (chart == null) {
      throw new Error(`Expected element ${selector} not found`)
    }
    let { width, height } = chart.getBoundingClientRect()
    let dataURI = await domToImage.toPng(chart, { bgcolor: whiteBase })
    let chartWidth = 0.75 * pageWidth
    let chartHeight = chartWidth * (height / width)
    doc.addImage(
      dataURI,
      "PNG",
      (pageWidth - chartWidth) / 2,
      contentBottom,
      chartWidth,
      chartHeight
    )

    contentBottom += 24 / pixelsInMM + chartHeight
  }

  await doc.save(documentName)
}
