import React, { FunctionComponent, ComponentProps } from "react"
import { Select } from "antd"
import { CaretDownFilled } from "@ant-design/icons"

import { blackBase } from "../colors"

export const Option = Select.Option

type ViewProps = {} & ComponentProps<typeof Select>
const View: FunctionComponent<ViewProps> = (props) => {
  return (
    <Select
      suffixIcon={
        <CaretDownFilled
          style={{ pointerEvents: "none", cursor: "auto", color: blackBase }}
        />
      }
      style={{ width: "100%" }}
      {...props}
    />
  )
}

export default View
