import {
  grayBase8,
  blackBase,
  greenBase,
  redBase,
  silver3,
  silver1,
} from "./colors"
import px from "./px"

const baseTableElement = {
  // In order to have lineHeight respected in a <td>, you have to wrap the
  // content in a div, it's not clear to me why this is the case
  lineHeight: px(2.5),
  display: "inline",
}

export default {
  headerSmall: {},
  headerLarge: {
    fontSize: "26px",
    lineHeight: "32px",
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
  },
  headerExtraSmall: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
  },
  detailTabContainer: {
    borderTop: `solid 1px ${silver3}`,
    display: "flex",
    flexDirection: "column" as const,
    flex: 1,
    backgroundColor: silver1,
    padding: `${px(7)} 0px ${px(21)}`,
  },
  fieldError: {
    position: "absolute" as const,
    marginTop: "2px",
    color: redBase,
  },
  button: {
    borderRadius: "4px",
    fontFamily: "Roboto",
    textTransform: "uppercase" as const,
    height: px(6),
    minWidth: "245px",
    boxShadow: "0px 1px 3px rgba(0,0,0,0.3)",
    border: "0px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
    textAlign: "center" as const,
  },
  indexPageContainer: {
    marginTop: px(6),
    marginBottom: px(7),
  },
  detailTableColumnHeader: {
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
    marginBottom: px(1),
    color: greenBase,
    fontSize: px(1.88),
    lineHeight: px(1.88),
    textTransform: "uppercase" as const,
  },
  detailTableColumnContent: {
    fontFamily: "Roboto Condensed",
    color: blackBase,
    fontSize: px(2.75),
    lineHeight: px(3.5),
  },
  matchColumnSmallHeader: {
    fontSize: px(1.5),
    lineHeight: px(1.5),
    marginBottom: "1px",
    color: blackBase,
  },
  commonInputLabel: {
    color: grayBase8,
    fontSize: px(2),
    lineHeight: px(2),
    marginBottom: px(0.75),
    marginLeft: px(0.5),
  },
  tableElementNoWrap: {
    ...baseTableElement,
    whiteSpace: "nowrap" as const,
  },
  tableElement: {
    ...baseTableElement,
  },
  searchResultsFor: {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "26px",
    color: blackBase,
  },
}
