import React, { FunctionComponent } from "react"

import { View as FormView } from "./RequestPasswordResetForm"

import px from "../px"

function noop() {}
let defaultField: any = {
  setValue: noop,
  error: null,
  setError: noop,
  touched: false,
  setTouched: noop,
  onChange: noop,
  onBlur: noop,
}

let View: FunctionComponent = () => {
  return (
    <div
      style={{
        display: "grid",
        columnGap: px(4),
        gridTemplateColumns: "repeat(auto-fill, 500px)",
      }}
    >
      <div style={{ display: "flex" }}>
        <p
          style={{ minWidth: "200px", marginBottom: px(1), marginRight: px(4) }}
        >
          Default (non-interactive)
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormView
            loading={false}
            email={{ ...defaultField, touched: true, value: "" }}
            emailSent={false}
            onSubmit={noop}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <p
          style={{ minWidth: "200px", marginBottom: px(1), marginRight: px(4) }}
        >
          Email sent (non-interactive)
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormView
            loading={false}
            email={{ ...defaultField, touched: true, value: "" }}
            emailSent={true}
            onSubmit={noop}
          />
        </div>
      </div>
    </div>
  )
}

export default View
