import React, { FunctionComponent } from "react"

import { blackBase } from "../colors"

import Select, { Option } from "./Select"

import { CaretDownFilled } from "@ant-design/icons"

function onChange() {}

function onBlur() {}

function onFocus() {}

function onSearch() {}

let View: FunctionComponent = () => {
  return (
    <div style={{ width: "200px" }}>
      <Select
        suffixIcon={<CaretDownFilled style={{ color: blackBase }} />}
        showSearch
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="jack">Jack</Option>
        <Option value="lucy">Lucy</Option>
        <Option value="tom">Tom</Option>
      </Select>
    </div>
  )
}

export default View
