import React, { FunctionComponent, MouseEvent } from "react"

import OpacityTransition from "./OpacityTransition"
import { whiteBase } from "../colors"
import * as Constants from "../constants"

export type ModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  modalWidth?: number
}

const styles = {
  backdrop: {
    position: "fixed" as "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: `${Constants.verticalOffset}vh 0`,
  },
  container: {
    backgroundColor: whiteBase,
    borderRadius: "4px",
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.2)",
    overflow: "hidden",
  },
}

export const Modal: FunctionComponent<ModalProps> = (props) => {
  let { visible, setVisible, modalWidth = Constants.modalWidth } = props
  return (
    <OpacityTransition visible={visible} duration={200}>
      <div onClick={() => setVisible(false)} style={styles.backdrop}>
        <div
          onClick={(e: MouseEvent) => e.stopPropagation()}
          style={{ ...styles.container, width: modalWidth }}
        >
          {props.children}
        </div>
      </div>
    </OpacityTransition>
  )
}
