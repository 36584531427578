import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export default () => {
  let history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      let elem = document.querySelector("#scroll-container")
      if (elem != null) {
        elem.scrollTop = 0
      } else {
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])

  return null
}
