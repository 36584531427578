import React, { FunctionComponent } from "react"

import { whiteBase, blackBase } from "../colors"
import px from "../px"

type ViewProps = {
  title: string
}
const View: FunctionComponent<ViewProps> = (props) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      maxWidth: "696px",
      width: "100%",
      backgroundColor: whiteBase,
      borderRadius: px(1),
    }}
  >
    <div
      style={{
        maxWidth: "455px",
        width: "100%",
        margin: `${px(5)} ${px(3)} 0`,
      }}
    >
      <div
        style={{
          fontWeight: 700,
          color: blackBase,
          fontSize: "31px",
          lineHeight: px(5),
          marginBottom: px(1.5),
        }}
      >
        {props.title}
      </div>
      {props.children}
    </div>
  </div>
)

export default View
