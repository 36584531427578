import React, { FunctionComponent } from "react"

import Ellipsis from "../components/Ellipsis"
import SandboxPrimaryButton from "../components/SandboxPrimaryButton"
import SandboxIndexTitle from "../components/SandboxIndexTitle"
import SandboxIndexSearch from "../components/SandboxIndexSearch"
import SandboxMaskedInput from "../components/SandboxMaskedInput"
import SandboxSelect from "../components/SandboxSelect"
import SandboxPagination from "../components/SandboxPagination"
import SandboxTable from "../components/SandboxTable"
import SandboxSigninForm from "../components/SandboxSigninForm"
import SandboxRequestPasswordResetForm from "../components/SandboxRequestPasswordResetForm"
import SandboxPasswordResetForm from "../components/SandboxPasswordResetForm"
import SandboxDetailTable from "../components/SandboxDetailTable"
import SandboxDetailAdditionalMetadataTable from "../components/SandboxDetailAdditionalMetadataTable"
import SandboxDetailTabs from "../components/SandboxDetailTabs"
import SandboxNav from "../components/SandboxNav"
import SandboxNumberedPageSection from "../components/SandboxNumberedPageSection"
import SandboxModal from "../components/SandboxModal"
import SandboxPieChart from "../components/SandboxPieChart"
import SandboxLabeledText from "../components/SandboxLabeledText"
import px from "../px"

const styles = {
  grid: {
    display: "grid",
    rowGap: px(4),
  },
  sandboxContainer: {
    margin: `${px(1.5)} ${px(2)}`,
  },
}

let Page: FunctionComponent = () => {
  return (
    <div style={styles.sandboxContainer}>
      <h1>Sandbox</h1>
      <div style={styles.grid}>
        <ComponentExample
          title="Masked Input"
          description="An input where only part of the input is editable"
        >
          <SandboxMaskedInput />
        </ComponentExample>
        <ComponentExample
          title="Detail table"
          description="A table to render resource details"
        >
          <SandboxDetailTable />
        </ComponentExample>
        <ComponentExample title="Modal">
          <SandboxModal />
        </ComponentExample>
        <ComponentExample title="Numbered page section">
          <SandboxNumberedPageSection />
        </ComponentExample>
        <ComponentExample title="Global navigation">
          <SandboxNav />
        </ComponentExample>
        <ComponentExample
          title="Detail additional metadata table"
          description="A table to render additional metadata"
        >
          <SandboxDetailAdditionalMetadataTable />
        </ComponentExample>
        <ComponentExample
          title="Detail tabs"
          description="The tabs components for the detail views"
        >
          <SandboxDetailTabs />
        </ComponentExample>
        <ComponentExample
          title="Ellipsis"
          description="An ellipsis is useful as a fallback for text content that is loading"
        >
          <Ellipsis />
        </ComponentExample>
        <ComponentExample title="Button primary">
          <SandboxPrimaryButton />
        </ComponentExample>
        <ComponentExample title="Select">
          <SandboxSelect />
        </ComponentExample>
        <ComponentExample
          title="Index title"
          description="The topmost header for the index pages"
        >
          <SandboxIndexTitle />
        </ComponentExample>
        <ComponentExample
          title="Index search"
          description="The search input for the index pages"
        >
          <SandboxIndexSearch />
        </ComponentExample>
        <ComponentExample title="Pagination">
          <SandboxPagination />
        </ComponentExample>
        <ComponentExample title="Table">
          <SandboxTable />
        </ComponentExample>
        <ComponentExample title="Pie Chart">
          <SandboxPieChart />
        </ComponentExample>
        <ComponentExample title="Labeled Text">
          <SandboxLabeledText />
        </ComponentExample>
        <ComponentExample title="Sign-in form">
          <SandboxSigninForm />
        </ComponentExample>
        <ComponentExample title="Request password reset form">
          <SandboxRequestPasswordResetForm />
        </ComponentExample>
        <ComponentExample title="Reset password form">
          <SandboxPasswordResetForm />
        </ComponentExample>
      </div>
    </div>
  )
}

type ComponentExampleProps = { title: string; description?: string }
let ComponentExample: FunctionComponent<ComponentExampleProps> = (props) => {
  let { title, description } = props
  return (
    <div>
      <h2>{title}</h2>
      {description ? <p>{description}</p> : null}
      {props.children}
    </div>
  )
}

export default Page
