import React, { FunctionComponent } from "react"
import LabeledText from "./LabeledText"

let View: FunctionComponent = () => {
  return (
    <div>
      <table width="300px">
        <tr>
          <td>
            <LabeledText label="UPLOAD DATE" text="24 min ago" />
          </td>
          <td>
            <LabeledText label="ADVERTISER" text="MERK" />
          </td>
        </tr>
      </table>
    </div>
  )
}

export default View
