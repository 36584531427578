import React, { FunctionComponent } from "react"

import px from "../px"
import PrimaryButton from "./PrimaryButton"

let View: FunctionComponent = () => {
  return (
    <div>
      <div style={{ marginBottom: px(5) }}>
        <PrimaryButton>Primary Button</PrimaryButton>
      </div>
      <div style={{ marginBottom: px(5) }}>
        <p>A loading button</p>
        <PrimaryButton loading={true}>Primary Button</PrimaryButton>
      </div>
    </div>
  )
}

export default View
