import React, { ComponentProps, FunctionComponent } from "react"

import px from "../px"
import PrimaryButton from "./PrimaryButton"

import SegmentModal from "./SegmentModal"

type ViewProps = Omit<ComponentProps<typeof SegmentModal>, "title">
let View: FunctionComponent<ViewProps> = (props) => {
  return (
    <SegmentModal title="Server error" {...props}>
      <div style={{ textAlign: "center", fontSize: px(2) }}>
        Your segments could not be created at this time due to an issue with the
        server.
      </div>
      <div style={{ textAlign: "center", fontSize: px(2) }}>
        Please try again later or{" "}
        <a href="mailto:support@healiolytics.com">contact Healiolytics</a> for
        assistance.
      </div>
      <PrimaryButton
        onClick={() => {
          props.setVisible(false)
          if (props.onCancel != null) props.onCancel()
        }}
      >
        Got it
      </PrimaryButton>
    </SegmentModal>
  )
}

export default View
