import React, { FunctionComponent } from "react"

let View: FunctionComponent = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="369.274 265.576 82 82"
      enableBackground="new 369.274 265.576 82 82"
    >
      <path d="M409.705,346.576c-22.057,0-40-17.944-40-40s17.943-40,40-40c5.397,0,10.637,1.057,15.569,3.144l-1.22,2.88 c-4.544-1.921-9.372-2.897-14.35-2.897c-20.331,0-36.873,16.541-36.873,36.873s16.542,36.873,36.873,36.873 s36.873-16.541,36.873-36.873c0-2.873-0.332-5.735-0.986-8.507l3.042-0.719c0.71,3.007,1.071,6.11,1.071,9.226 C449.705,328.632,431.762,346.576,409.705,346.576z M430.966,266.906l-20.439,39.67l40.317-13.793L430.966,266.906z" />
    </svg>
  )
}

export default View
