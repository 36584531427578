import React, { FunctionComponent } from "react"

import { indexTitleHeight } from "../constants"
import px from "../px"

const View: FunctionComponent<{ title: string }> = (props) => {
  return (
    <h1
      style={{
        fontWeight: "bold",
        fontFamily: "Roboto Condensed",
        margin: 0,
        fontSize: px(4),
        lineHeight: `${indexTitleHeight}px`,
      }}
    >
      {props.title}
    </h1>
  )
}

export default View
