import React, { FunctionComponent } from "react"

let View: FunctionComponent = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-15.5 -15.5 82 82"
      enableBackground="new -15.5 -15.5 82 82"
    >
      <path d="M25.5-11.246c20.262,0,36.745,16.483,36.745,36.746c0,20.262-16.483,36.745-36.745,36.745 c-20.262,0-36.746-16.483-36.746-36.745C-11.246,5.238,5.238-11.246,25.5-11.246 M25.5-14.5c-22.091,0-40,17.909-40,40 c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40C65.5,3.409,47.591-14.5,25.5-14.5L25.5-14.5z M47.14,40.828 c0,1.489-1.217,2.706-2.704,2.706H6.563c-1.487,0-2.704-1.217-2.704-2.706v-1.802c0-1.487,1.217-2.705,2.704-2.705h37.873 c1.487,0,2.704,1.218,2.704,2.705V40.828z M47.14,26.402c0,1.487-1.217,2.703-2.704,2.703H6.563c-1.487,0-2.704-1.216-2.704-2.703 v-1.804c0-1.486,1.217-2.702,2.704-2.702h37.873c1.487,0,2.704,1.216,2.704,2.702V26.402z M47.14,10.172v1.801 c0,1.487-1.217,2.706-2.704,2.706H6.563c-1.487,0-2.704-1.219-2.704-2.706v-1.801c0-1.489,1.217-2.706,2.704-2.706h37.873 C45.923,7.466,47.14,8.683,47.14,10.172z" />
    </svg>
  )
}

export default View
