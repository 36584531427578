import React, {
  useMemo,
  useCallback,
  ReactNode,
  ReactElement,
  CSSProperties,
} from "react"
import { Transition } from "react-transition-group"

type OpacityTransitionCSS = Pick<CSSProperties, "display" | "animation">
type ChildrenType =
  | ReactNode
  | ((style: OpacityTransitionCSS) => ReactElement | null)

type OpacityTransitionProps = {
  visible?: boolean
  duration?: number // ms

  // Either children is an element, or its a function which takes a style
  // object and returns an element
  children: ChildrenType
}
const OpacityTransition = (props: OpacityTransitionProps) => {
  let { children, visible = true, duration = 100 } = props

  const transitionStyles: {
    [transitionStatus: string]: Partial<OpacityTransitionCSS>
  } = useMemo(
    () => ({
      entering: { animation: `fade ${duration}ms` },

      entered: { animation: `fade ${duration}ms` },
      exiting: {
        animation: `fadeOut ${duration + 5}ms`,
      },
      exited: {
        animation: "",
        display: "none",
      },
    }),
    [duration]
  )

  let renderChildren = useCallback(
    (state: string) => {
      let style = transitionStyles[state]
      if (typeof children === "function") {
        return children(style)
      }
      return <div style={style}>{children}</div>
    },
    [children, transitionStyles]
  )

  return (
    <Transition
      in={visible}
      timeout={{ appear: 0, enter: duration, exit: duration }}
    >
      {renderChildren}
    </Transition>
  )
}

export default OpacityTransition
