import React, { useState, FunctionComponent } from "react"

import SegmentCreationModal from "./SegmentCreationModal"
import SegmentCreationErrorModal from "./SegmentCreationErrorModal"
import SegmentCreationConfirmLeaveModal from "./SegmentCreationConfirmLeaveModal"
import PrimaryButton from "./PrimaryButton"
import px from "../px"

let SegmentCreationModalExample = () => {
  let [visible, setVisible] = useState(false)
  return (
    <>
      <PrimaryButton onClick={() => setVisible(true)}>Show</PrimaryButton>
      <SegmentCreationModal
        visible={visible}
        onCreateAnotherSegment={() => setVisible(false)}
        onViewCampaign={() => setVisible(false)}
        setVisible={setVisible}
      />
    </>
  )
}

let SegmentCreationErrorModalExample = () => {
  let [visible, setVisible] = useState(false)
  return (
    <>
      <PrimaryButton onClick={() => setVisible(true)}>Show</PrimaryButton>
      <SegmentCreationErrorModal visible={visible} setVisible={setVisible} />
    </>
  )
}

let SegmentCreationConfirmLeaveModalExample = () => {
  let [visible, setVisible] = useState(false)
  return (
    <>
      <PrimaryButton onClick={() => setVisible(true)}>Show</PrimaryButton>
      <SegmentCreationConfirmLeaveModal
        onLeave={() => setVisible(false)}
        onContinue={() => setVisible(false)}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

let View: FunctionComponent = () => {
  return (
    <div
      style={{
        display: "grid",
        rowGap: px(2),
        gridTemplateRows: "repeat(auto-fill, minmax(min-content, 0))",
      }}
    >
      <div>
        <p>Show Segment Creation modal</p>
        <SegmentCreationModalExample />
      </div>
      <div>
        <p>Show Segment Creation Error modal</p>
        <SegmentCreationErrorModalExample />
      </div>
      <div>
        <p>Show "Are you sure you want to leave segment creation" modal</p>
        <SegmentCreationConfirmLeaveModalExample />
      </div>
    </div>
  )
}

export default View
