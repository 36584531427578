var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useEffect, useState } from "react";
// Identital to set state, but allows you to pass a list of dependencies in the
// spirit of useMemo, useCallback, etc, which will trigger the state to be reset
// to initial
export default function useDependentState(initial, dependencies) {
    dependencies = dependencies || [];
    var _a = useState(initial), state = _a[0], setState = _a[1];
    useEffect(function () {
        setState(initial);
        // React hook will warn because it cannot tell statically if the
        // dependencies in this useEffect are satisfied, its pretty easy for us to
        // verify that its true here, we want this functionality though its a tad
        // anti-patternish
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArrays([initial, setState], dependencies));
    return [state, setState];
}
