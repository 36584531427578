import { useState, useCallback, useMemo } from "react";
function storageAvailable() {
    try {
        var storage = window.localStorage;
        var x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
    }
    catch (e) {
        return false;
    }
    return true;
}
// Equivalen to useState but persists to and loads from localStorage if possible
export default (function (key, initialValue) {
    var localStorageAvailable = useMemo(storageAvailable, []);
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    var _a = useState(function () {
        if (!localStorageAvailable) {
            return initialValue;
        }
        var item = window.localStorage.getItem(key);
        if (item == null) {
            return initialValue;
        }
        return item;
    }), storedValue = _a[0], setStoredValue = _a[1];
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    var setValue = useCallback(function (value) {
        // Allow value to be a function so we have same API as useState
        var valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        if (!localStorageAvailable) {
            return;
        }
        // Remove from localstorage if value being set is undefined
        if (valueToStore === undefined) {
            window.localStorage.removeItem(key);
            return;
        }
        // Save to local storage
        window.localStorage.setItem(key, valueToStore);
    }, [key, storedValue, localStorageAvailable]);
    return [storedValue, setValue];
});
