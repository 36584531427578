import { useRef, useEffect } from "react";
export default (function () {
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, [isMounted]);
    return isMounted.current;
});
