import React, { FunctionComponent } from "react"
import { Row, Col } from "antd"

import IndexTitle from "../components/IndexTitle"
import px from "../px"
import sharedStyles from "../sharedStyles"

let Page: FunctionComponent = () => {
  return (
    <div style={sharedStyles.indexPageContainer}>
      <Row style={{ height: px(5), marginBottom: px(4) }}>
        <Col>
          <IndexTitle title="We're sorry, you don't have access to this page." />
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ fontFamily: "Merriweather", fontSize: px(2) }}>
            <a
              style={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
              }}
              href="mailto:support@healiolytics.com"
            >
              Contact Healiolytics
            </a>{" "}
            for assistance with your permissions.
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Page
