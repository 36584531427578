import React, { FunctionComponent } from "react"
import { Row, Col } from "antd"
import intersperse from "intersperse"

import { silver3 } from "../colors"
import px from "../px"

type ViewProps = {
  title: string
  subTitle?: JSX.Element
  gridTemplateColumns?: string
}
const View: FunctionComponent<ViewProps> = (props) => {
  let { title, subTitle, gridTemplateColumns } = props

  let numOfChildren = React.Children.toArray(props.children).length
  let numOfDividers = Math.max(0, numOfChildren - 1)

  gridTemplateColumns =
    gridTemplateColumns ||
    Array.from({
      length: numOfChildren + numOfDividers,
    })
      .map((_: any, i: number) => (i % 2 === 0 ? "auto" : px(4)))
      .join(" ")
  return (
    <div
      style={{
        border: `1px solid ${silver3}`,
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col style={{ padding: `${px(1.5)} ${px(2)}` }}>
          <h3
            style={{
              fontSize: "22px",
              lineHeight: "24px",
              fontWeight: 700,
              fontFamily: "Roboto Condensed",
              marginBottom: subTitle == null ? "0px" : px(1),
            }}
          >
            {title}
          </h3>
          {subTitle ? (
            <div
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                fontFamily: "Roboto Condensed",
                margin: 0,
              }}
            >
              {subTitle}
            </div>
          ) : null}
        </Col>
      </Row>
      <div style={{ height: "1px", width: "100%", backgroundColor: silver3 }} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns,
          padding: `${px(4)} ${px(4)} ${px(3)}`,
        }}
      >
        {intersperse(
          React.Children.toArray(props.children),
          <Col>
            <div
              style={{
                width: "1px",
                margin: `0 ${px(2)}`,
                height: px(8),
                backgroundColor: silver3,
              }}
            />
          </Col>
        ).map((child, index) => {
          // Something a little complex is happening here. Every child of a
          // Row must be a Col. Every even index is a user provided element,
          // every odd index is a divider. If it's a user provided element
          // (even) then we render the Col with flex: 1. We are keying by
          // index on the basis that these children's position is not going to
          // change, i.e. that it is a sufficient test for their identity
          return (
            <Col key={index} {...(index % 2 === 0 ? { flex: "1" } : {})}>
              {child}
            </Col>
          )
        })}
      </div>
    </div>
  )
}

export default View
