import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { WebSocketLink } from "@apollo/client/link/ws"
import { dispatchToRootErrorBoundary } from "core_ui/lib/api"
import { dispatchToAuthExpiredModal } from "core_ui/lib/auth"
import { tokenExpiredOrInvalid } from "core_ui/lib/auth"
import { store } from "./redux/store"
import { getToken } from "./selectors"

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `${
    process.env.REACT_APP_HASURA_GRAPHQL_WS_ENDPOINT as string
  }/v1/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      let token = getToken(store.getState())
      if (token == null) {
        throw new Error("Token does not exist")
      }
      return {
        headers: { Authorization: `Bearer ${token}` },
      }
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let token = getToken(store.getState())
  if (!token || tokenExpiredOrInvalid(token)) {
    dispatchToAuthExpiredModal()
  } else {
    dispatchToRootErrorBoundary(graphQLErrors || networkError)
  }
})

const client = new ApolloClient({
  link: from([errorLink, wsLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

export default client
