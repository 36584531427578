import React, {
  FunctionComponent,
  FormEvent,
  useState,
  useCallback,
} from "react"
import { message, Input, Button } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { useParams, useHistory } from "react-router-dom"
import * as Yup from "yup"

import { subFromToken } from "core_ui/lib/auth"
import {
  requestPasswordReset,
  resetPassword,
  catchCriticalError,
} from "core_ui/lib/api"
import formatError from "core_ui/lib/formatError"
import PrimaryButton from "./PrimaryButton"
import { useField } from "core_ui/lib/hooks"
import { blueBase, redBase } from "../colors"
import sharedStyles from "../sharedStyles"
import px from "../px"

const minPasswordLength = 6
let passwordSchema = Yup.string()
  .trim()
  .min(
    minPasswordLength,
    `The password must be at least ${minPasswordLength} characters length`
  )
  .required("Enter your password")

type ViewProps = ReturnType<typeof useController>
export const View: FunctionComponent<ViewProps> = (props) => {
  let {
    loading,
    onLinkExpired,
    passwordReset,
    goToSignin,
    onSubmit,
    password,
    confirmPassword,
  } = props

  const submitButtonProps = {
    loading,
    disabled: loading,
    htmlType: "submit" as const,
  }

  return (
    <>
      {passwordReset ? (
        <div>
          <div style={{ fontSize: px(2), marginBottom: px(4) }}>
            Password was reset.
          </div>
          <div style={{ marginBottom: px(3.5) }}>
            <PrimaryButton onClick={goToSignin}>Go to Log in</PrimaryButton>
          </div>
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div style={{ marginBottom: px(4) }}>
            <label
              style={{
                fontSize: px(2),
                marginBottom: px(0.25),
                display: "block",
              }}
              htmlFor="password"
            >
              Password<span style={{ color: redBase }}>*</span>
            </label>
            <Input.Password
              id="password"
              value={password.value}
              onBlur={password.onBlur}
              onChange={password.onChange}
              placeholder="Password"
              iconRender={(visible: boolean) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <div style={sharedStyles.fieldError}>
              {password.touched && password.error}
            </div>
          </div>

          <div style={{ marginBottom: px(4) }}>
            <label
              style={{
                display: "block",
                fontSize: px(2),
                marginBottom: px(0.25),
              }}
              htmlFor="password-confirm"
            >
              Confirm Password<span style={{ color: redBase }}>*</span>
            </label>
            <Input.Password
              id="password-confirm"
              value={confirmPassword.value}
              onBlur={confirmPassword.onBlur}
              onChange={confirmPassword.onChange}
              placeholder="Password"
              iconRender={(visible: boolean) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <div style={sharedStyles.fieldError}>
              {confirmPassword.touched && confirmPassword.error}
            </div>
          </div>

          <div
            style={{
              marginBottom: px(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PrimaryButton {...submitButtonProps}>Submit</PrimaryButton>
          </div>

          <div
            style={{
              marginBottom: px(3),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: px(2) }}>Link expired?</span>&nbsp;
            <Button
              style={{
                padding: 0,
                fontSize: px(2),
                color: blueBase,
                border: "0px",
                height: "22px",
              }}
              type="link"
              onClick={onLinkExpired}
            >
              Resend email
            </Button>
          </div>
        </form>
      )}
    </>
  )
}

const Component: FunctionComponent = () => {
  let viewProps = useController()
  return <View {...viewProps} />
}

const useController = () => {
  let history = useHistory()
  let { reset_code } = useParams()
  const [loading, setLoading] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)
  let password = useField({ initial: "", schema: passwordSchema })
  let confirmPassword = useField({
    initial: "",
    schema: passwordSchema.oneOf(
      [password.value],
      "The passwords do not match"
    ),
  })

  const onSubmit = useCallback(
    (e: FormEvent) => {
      // Prevent default action of a form submit
      e.preventDefault()

      // Mark all fields as touched
      password.setTouched(true)
      confirmPassword.setTouched(true)

      if (password.error || confirmPassword.error) {
        return
      }

      setLoading(true)
      resetPassword({ password: password.value, reset_code })
        .then(() => {
          setPasswordReset(true)
        })
        .catch(catchCriticalError)
        .catch((error) => {
          message.error(formatError(error))
        })
        .finally(() => setLoading(false))
    },
    [confirmPassword, password, reset_code]
  )

  const goToSignin = useCallback(() => {
    let email = subFromToken(reset_code)
    if (email) {
      history.push({ pathname: "/signin", state: { email } })
    } else {
      history.push("/signin")
    }
  }, [history, reset_code])

  const onLinkExpired = useCallback(() => {
    let email = subFromToken(reset_code)
    if (email) {
      requestPasswordReset({ email })
        .then(() => message.success("Email was sent"))
        .catch(catchCriticalError)
        .catch((error) => {
          if (error?.response?.data?.password) {
            confirmPassword.setError(error.response.data.password)
          } else {
            message.error(formatError(error))
          }
        })
    } else {
      history.push("/request_password_reset")
    }
  }, [confirmPassword, history, reset_code])

  return {
    goToSignin,
    loading,
    onLinkExpired,
    passwordReset,
    onSubmit,
    password,
    confirmPassword,
  }
}

export default Component
